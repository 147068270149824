import { SharedService } from 'src/app/_services/shared.service';
import { Pipe, PipeTransform } from '@angular/core';
import { HDate } from '@hebcal/core';

@Pipe({
  name: 'hebrewYear'
})
export class HebrewYearPipe implements PipeTransform {

  constructor(private service: SharedService) {
  }

  transform(value: number): any {
    try {
      if (this.service.getLang() != "he")
        return value;
      if (value === undefined || value === null) {
        return '';
      }
      const hd2 = new HDate(new Date(value, 0, 1));
      const result =  hd2.renderGematriya();
      return result.split(' ')[2];
    } catch (error) {
      console.log(error);
      console.log(value);
      return '';
    }
  }

}
