import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ServerService {

  private loadComplete = new BehaviorSubject(true);
  private swipeUpEvent = new BehaviorSubject(true);
  private swipeDownEvent = new BehaviorSubject(true);



  getLoadComplete(){
    return this.loadComplete.asObservable();
  }

  setLoadComplete() {
    this.loadComplete.next(true);
  }

  setSwipeUp(){
    this.swipeUpEvent.next(true);
  }
  getSwipeUp() {
    return this.swipeUpEvent.asObservable();
  }

  setSwipeDown(){
    this.swipeDownEvent.next(true);
  }
  getSwipeDown() {
    return this.swipeDownEvent.asObservable();
  }
}
