import { SharedService } from 'src/app/_services/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { radioBtnOption } from './radioBtnOption';

@Component({
  selector: 'app-radio-buttons-group',
  templateUrl: './radio-buttons-group.component.html',
  styleUrls: ['./radio-buttons-group.component.scss']
})
export class RadioButtonsGroupComponent implements OnInit {

  @Input() public options: radioBtnOption[];
  listOptions: any[] = [];
  @Input() public selected: number | string;
  @Input() public vertical = false;
  @Input() public disabled = false;
  @Input() public verticalCss = 'displayVertical';
  @Input() public extraTextClass = '';
  @Input() public cssClass = '';
  @Input() public elementcss = 'aa';
  @Input() public splitByGroupText = '';
  @Input() public isFullBank = false;
  @Input() public compTabIndex = 0;

  @Output() selectedValue = new EventEmitter();
  @Output() selectedValueWithText = new EventEmitter<any>();
  lastSelectedOption: radioBtnOption = new radioBtnOption();
  textboxControl: UntypedFormControl = new UntypedFormControl();
  @Input() public control: UntypedFormControl = new UntypedFormControl();
  headers: any[];
  listIsEmpty = false;
  constructor(
    public shared: SharedService
  ) { }

  onSelectChange(selectedValue: any) {
    if (selectedValue.value === undefined) { return; }
    this.selectedValue.emit(selectedValue?.value?.value);

    this.selectedValueWithText.emit({ 'value': selectedValue?.value?.value, 'text': selectedValue?.value?.isOpenText ? this.textboxControl.value : '' });
    this.lastSelectedOption = selectedValue.value;
  }
  inputChanged($event) {
    this.selectedValueWithText.emit({ 'value': this.lastSelectedOption.value, 'text': this.lastSelectedOption?.isOpenText ? this.textboxControl.value : '' });
  }

  ngOnInit() {
    if (this.selected) {
      this.lastSelectedOption = this.options.filter(x => x.value === this.selected)[0];
    }
    this.headers = this.shared.distinctArray(this.options, [this.splitByGroupText])

    this.getItemsByGroupText();

  }
  setValue($event) {
    if (this.control) {
      this.control.setValue($event.value);
    }
  }
  getItemsByGroupText(options = null) {
    if (options != null) {
      this.options = options;
    }
    this.listOptions = [...this.listOptions.filter(x => x == 999999)]
    if (this.splitByGroupText == '') {
      this.listOptions.push({ items: [...this.options] });
      this.listIsEmpty = this.listOptions.length == 0;
    } else {
      this.listIsEmpty = true;
      this.headers.forEach(x => {
        this.listOptions.push({
          items: [...this.options.filter(o => o[this.splitByGroupText] == x[this.splitByGroupText])],
          header: x[this.splitByGroupText]
        });
        if (this.listOptions.filter(lo => lo.header == x[this.splitByGroupText])[0].items.length > 0) {
          this.listIsEmpty = false;
        }

      });
      this.listOptions = [...this.listOptions]
      return this.listOptions;
    }

  }

}
