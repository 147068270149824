export class AssesmentBank {
  from_mark: number;
  table_no: number;
  femaleAssesment: string;
  maleAssesment: string;
  literal_evaulations_midrag?: number;
  homeroom_teacher_notes_midrag?: number;
  evaluation_event_midrag?: number;
  category?: string;
  showFromMark?:boolean = true;
}

export class AssesmentBankLinkage {
  assessment_code: number;
  homeroom_note_id?: number;
  literal_evaluation_id?: number;
  evaluation_event_type_id?: number;
}
