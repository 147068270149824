import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../_services/shared.service';

@Pipe({
  name: 'numToDay'
})
export class NumberToDayNamePipe implements PipeTransform {
  private days: string[] = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' , 'Sunday'];
  private heDays: string[] = ['', 'ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
  private arDays: string[] = ['', 'الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
  private ruDays: string[] = ['', 'Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  private tiDays: string[] = ['', 'ሰንበት', 'ሰኑይ', 'ሰሉስ', 'ረቡዕ', 'ሓሙስ', 'ዓርቢ', 'ቀዳም'];


  constructor(
    private translate: TranslateService,
    private shared: SharedService
  ) { }
  transform(number: number | any, isShort: boolean= false , showText: boolean = false, showDay: boolean = false): string {
    if (!this.shared.isNumber(number) || (this.shared.isNumber(number) && number > 8)) {
      const d = new Date(number);
      if (showText) {
        if (d.setHours(24, 0, 0, 0) === (new Date()).setHours(24, 0, 0, 0)) {
          return this.translate.instant('Today');
        }
        if (d.setHours(24, 0, 0, 0) === (new Date()).setHours(24, 0, 0, 0) - 1 ) {
          return this.translate.instant('Yesterday');
        }
      }
      number = d.getDay() + 1;
    }
    if (number === -1) {
      number = (new Date()).getDay() + 1;
    }

    if (isShort) {
      return this.translate.instant(this.days[number] + 'Short');
    } else {
      const lang = localStorage.getItem('language');
      let result =  this.getDayByLang(lang, number);

      if (result === this.days[number]) {
        if (lang !== undefined && lang !== null && lang === 'en') {

          result = this.days[number];
        } else {
          result = this.heDays[number];
        }

      }
      return `${showDay ? this.translate.instant('Day') : ''} ${result}`  ;
    }
  }

  getDayByLang(lang:string, num: number) {
    switch (lang) {
      case 'he': return this.heDays[num];
      case 'ar': return this.arDays[num];
      case 'ru': return this.ruDays[num];
      case 'ti': return this.tiDays[num];
      case 'en': return this.days[num];
      default: return this.heDays[num];
    }
  }
}
