import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value, args?: any) {

    if (args === 'sanitized') {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }

    if (args === 'url') {
      return this.sanitized.bypassSecurityTrustResourceUrl(value);
    }


    if (!value) {
      return '';
    }
    const txt = document.createElement('textarea');
    txt.innerHTML = value;
    return txt.value;
  }
}
