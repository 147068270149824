import { AccessibilitySettingsComponent } from './../accessibility-settings/accessibility-settings.component';
import { SliderComponent } from './../../common/UIControls/slider/slider.component';
import { SharedService } from 'src/app/_services/shared.service';
import { ClassList } from './../../_models/Contacts';
import { HebrewDatePipe } from './../../_pipes/hebrewDate.pipe';
import { formattedDate } from './../../_pipes/formattedDate';
import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { radioBtnOption } from 'src/app/common/UIControls/radio-buttons-group/radioBtnOption';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AccessibilityStatementComponent } from 'src/app/account/accessibility-statement/accessibility-statement.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';

@Component({
  selector: 'app-view-settings',
  templateUrl: './view-settings.component.html',
  styleUrls: ['./view-settings.component.css']
})
export class ViewSettingsComponent implements OnInit {

  @Input() viewName: string;

  fontSize: number = 100;
  defaultFontSize: number;
  fontExample: string = '100%';
  selectedLanguage: number = 0;
  selectedDatePickerType: number = 1;
  selectedViewMode: number = 1;
  languageOptions: radioBtnOption[] = [];
  datePickerOptions: radioBtnOption[] = [];
  viewModeOptions: radioBtnOption[] = [];


  @ViewChild(AccessibilitySettingsComponent) accessibilitySettings: AccessibilitySettingsComponent;



  constructor(
    private translate: TranslateService,
    private formattedDate: formattedDate,
    private hebDate: HebrewDatePipe,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    public shared: SharedService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.languageOptions.push({ 'value': 0, 'text': this.translate.instant('Hebrew'), 'icon': 'flag_hebrew' });
    this.languageOptions.push({ 'value': 1, 'text': this.translate.instant('Arabic'), 'icon': 'flag_arabic' });
    this.languageOptions.push({ 'value': 2, 'text': this.translate.instant('English'), 'icon': 'flag_us' });
    this.languageOptions.push({ 'value': 3, 'text': this.translate.instant('Russian'), 'icon': 'flag_russia' });
    this.languageOptions.push({ 'value': 4, 'text': this.translate.instant('tigress'), 'icon': 'flag_ti' });
    this.datePickerOptions.push({ 'value': 1, 'text': this.translate.instant('ForeignType'), 'extraText': this.formattedDate.transform(new Date(), false) });
    this.datePickerOptions.push({ 'value': 2, 'text': this.translate.instant('HebrewType'), 'extraText': this.hebDate.transform(new Date()) });
    this.viewModeOptions.push({ 'value': 1, 'text': this.translate.instant('light'), 'icon': 'LightMode' });
    //this.viewModeOptions.push({ 'value': 2, 'text': this.translate.instant('dark'), 'icon': 'DarkMode' });
    this.viewModeOptions.push({ 'value': 3, 'text': this.translate.instant('dark'), 'icon': 'DarkMode' });
  }


  changeExamplePreview(val: number) {
    this.fontSize = val;
    this.fontExample = val + '%';
  }

  viewModeChange(value: number) {
    this.selectedViewMode = value;
    if (value == 1) {
      document.getElementsByTagName('body').item(0).classList.remove('dark-theme');
      document.getElementsByTagName('body').item(0).classList.remove('dark-theme-2');
      document.getElementsByTagName('body').item(0).classList.add('light-theme');
      localStorage.removeItem('darkTheme');
    }
    else {
      document.getElementsByTagName('body').item(0).classList.remove('light-theme');
      if (value == 3) {
        document.getElementsByTagName('body').item(0).classList.add('dark-theme-2');
        document.getElementsByTagName('body').item(0).classList.remove('dark-theme');
      }
      else {
        document.getElementsByTagName('body').item(0).classList.add('dark-theme');
        document.getElementsByTagName('body').item(0).classList.remove('dark-theme-2');
      }
      localStorage.setItem('darkTheme', (value - 1).toString());
    }
  }


  selectLanguageChange(value: number) {
    this.selectedLanguage = value;
  }
  selectDatePickerChange(value: number) {
    this.selectedDatePickerType = value;
  }

  changeLanguage(): void {
    const selectedLang = this.translate.langs[this.selectedLanguage];
    // this.document.documentElement.lang = selectedLang;
    localStorage.setItem('language', selectedLang);
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      data: { message: this.translate.instant('settingsSavedSuccessfuly'), type: 'succcess' }
    });
    this.translate.use(selectedLang);
    window.location.reload();
  }





  changeColorContrasts(): void {
    /* var checked = this.colorContractBtn.find(x=>x.isChecked);
    var access = localStorage.getItem("accessibility");
    if (access != null)
      document.getElementsByTagName('body').item(0).classList.remove(access);
    if (checked != null){
      localStorage.setItem("accessibility", checked.name);
      document.getElementsByTagName('body').item(0).classList.add(checked.name);
    }
    else localStorage.removeItem("accessibility"); */
  }

  changeDatePicker(): void {
    localStorage.setItem('datepicker', this.selectedDatePickerType == 1 ? 'regular' : 'hebrew');
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: 3000,
      data: { message: this.translate.instant('settingsSavedSuccessfuly'), type: 'succcess' }
    });
  }

  saveSettings(): void {
    switch (this.viewName) {
      case 'ApplicationLanguage':
        this.changeLanguage();
        break;
      case 'accessibility':
        this.accessibilitySettings.changeFontSize();
        this.accessibilitySettings.changeColorContrasts();
        break;
      case 'DateCalender':
        this.changeDatePicker();
        break;
    }
  }

  ngOnInit() {

    this.selectedLanguage = this.translate.langs.indexOf(localStorage.getItem('language'));
    if (this.selectedLanguage < 0) {
      this.selectedLanguage = 0;
    }
    if (localStorage.getItem('datepicker') == null) {
      this.selectedDatePickerType = this.translate.langs[this.selectedLanguage] === 'he' ? 2 : 1;
    } else { this.selectedDatePickerType = localStorage.getItem('datepicker') === 'hebrew' ? 2 : 1; }
    this.selectedViewMode = localStorage.getItem('darkTheme') == null ? 1 : Number(localStorage.getItem('darkTheme')) + 1;
  }

  declarationAccessibility() {
    this.matDialog.open(AccessibilityStatementComponent, {
      disableClose: false,
      autoFocus: true
    });

  }

  resetSettings() {

    this.saveSettings();
  }

}
