import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-textare-wordcounter',
  templateUrl: './textare-wordcounter.component.html',
  styleUrls: ['./textare-wordcounter.component.scss']
})
export class TextareWordcounterComponent implements OnInit {

  @Input() label: string;
  @Input() control: UntypedFormControl;
  @Input() maxLength: number;


  @Output() inputChanged = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  dataChanged($event) {
    this.inputChanged.emit(this.control.value);
    }

}
