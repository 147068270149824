import { environment } from 'src/environments/environment';
import { PostService } from 'src/app/_services/postService';
import { YearRangeServiceService } from './year-range-service.service';
import { Injectable } from '@angular/core';
import { SelectItem } from '../_models/sharedModels';
import { TinyUser, User } from '../_models/user';
import { RsaService } from './Rsa.service';
import { CookiesService } from './cookies-service.service';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { SqlService } from './sqlService';


@Injectable()
export class UserService {
  private yearChanged = new Subject();
  private hideFrame = false;
  private _user: User;
  private multiUsers: SelectItem[] = [];
  public selectedUser: string;
  private lastToken = new Date();
  private notNeedToken = ['/account/login', '/account/forgotpassword'];
  private selectedStudyYear = new BehaviorSubject<number>(environment.currentYear);
  constructor(
    private _rsaService: RsaService,
    private yearRange: YearRangeServiceService,
    private cookieService: CookiesService,
    private sqlService: SqlService,
  ) {
  }

  updateSelectedStudyYear(value, postSrv: PostService) {
    this.selectedStudyYear.next(value);
    this.yearRange.getRange(this.selectedStudyYear.value, postSrv);
    this.setYearChanged();
  }

  getSelectedStudyYear() {
    return this.selectedStudyYear.asObservable();
  }

  getYearChanged() {
    return this.yearChanged.asObservable();
  }

  setYearChanged() {
    this.yearChanged.next();
  }
  updateLastToken() {
    this.lastToken = new Date();
  }

  setMustUpdatePassword(flag: boolean) {
    if (!flag)
      localStorage.removeItem('mustUpdatePassword');
    else
      localStorage.setItem('mustUpdatePassword', '1');

  }
  getMustUpdatePassword() {
    return localStorage.getItem('mustUpdatePassword') == '1';
  }
  checkLastToken() {
    return new Date().getTime() - this.lastToken.getTime() > (1000 * 60 * 30);
  }
  needToCheckToken() {
    return !this.notNeedToken.includes(location.pathname.toLowerCase());
  }

  setHideFrame(flag: boolean) {
    this.hideFrame = flag;
  }
  getHideFrame() {
    return this.hideFrame;
  }


  clear(clearAll: boolean = false, removeBioLogin = true) {
    this.multiUsers = [];
    this.selectedUser = '';
    this.clearSavedUser().subscribe();
    this._user = new User();
    localStorage.removeItem('selectedUser');
    this.cookieService.removeItem('webToptokenExpired');
    localStorage.removeItem('user');
    localStorage.removeItem('mustUpdatePassword');
    this.cookieService.removeItem('loginMoe');
    this.setHideFrame(false);
    if (removeBioLogin) {
      this.removeBioLogin().subscribe(result => { });
    } else {
      this.getBioLogin().subscribe(result => { });
    }
    localStorage.removeItem('admin');
    this.yearRange.clearRange();
    localStorage.removeItem('connected');

  }

  clearBio() {
    this.multiUsers = [];
    this.selectedUser = '';
    this._user = new User();
    localStorage.removeItem('selectedUser');
    this.cookieService.removeItem('webToptokenExpired');
    localStorage.removeItem('user');
    this.cookieService.removeItem('loginMoe');
    this.setHideFrame(false);
    localStorage.removeItem('admin');
    this.yearRange.clearRange();
  }

  setUser(value: User, postSrv: PostService) {
    this._user = value;
    localStorage.setItem('user', this._rsaService.encrypt(JSON.stringify(new TinyUser(value))));
    this.yearRange.getRange(this.selectedStudyYear.value, postSrv);
    this.setMyTab(value);
  }

  setMyTab(value: User) {
    const randomNumber = value.userId + value.id;
    sessionStorage.clear();
    sessionStorage.setItem('tabId', randomNumber);
    localStorage.setItem('lastTabId', randomNumber);

  }

  saveUserNameAndPassword(userName: string, password: string) {
    if (password == '')
      this.sqlService.setValue('SavedUser', userName);
    else
      this.sqlService.setValue('SavedUser', this._rsaService.encrypt(`${userName}***${password}`));
  }

  setRememberMe(flag: boolean) {
    if (flag) {
      //this.cookieService.setItem((this._user.id), flag.toString());
      this.sqlService.setValue((this._user.id), flag.toString());
      this.removeBioLogin().subscribe();
    } else {
      this.sqlService.deleteByKey(this._user.id).subscribe();
    }
  }

  getBioLogin(): Observable<unknown> {
    return this.sqlService.getByKey('bioLogin');
  }

  setBioLogin(value) {
    let today = new Date();
    today.setFullYear(today.getFullYear() + 1)
    this.sqlService.setValue('bioLogin', value, today);
  }

  removeBioLogin(): Observable<unknown> {
    return this.sqlService.deleteByKey('bioLogin');
  }

  addDays(futureDate: Date, days: number): Date {
    futureDate = new Date(futureDate);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }

  clearSavedUser(): Observable<unknown> {
    if (this._user != undefined && this._user != null && this._user.id != undefined) {
      this.sqlService.deleteByKey(this._user.id).subscribe(result => { });
    }
    return this.sqlService.deleteByKey('SavedUser');
  }
  getSavedUser() {
    return this.sqlService.getByKey('SavedUser');
  }
  /*   getSavedPassword() {
      const data = this.cookieService.getItem('SavedUser');
      if (data === undefined || data === null || data === '') {
        return '';
      }
      return this._rsaService.decrypt(data).split('***')[1];
    } */


  setSelectedUser(value: string) {
    localStorage.setItem('selectedUser', value);
    this.selectedUser = value;
  }

  getSelectedUser() {
    if (this.selectedUser === undefined || this.selectedUser === null || this.setSelectedUser.length === 0) {
      const tmp = localStorage.getItem('selectedUser');
      if (tmp !== undefined || tmp !== null || tmp.length > 0) {
        return tmp;
      }
    }
    return this.selectedUser;
  }

  setOption(option, value) {
    this._user[option] = value;
  }

  getUser() {
    const tmp = localStorage.getItem('user');

    try {

      if (this._user !== null && this._user !== undefined && this._user.userId !== undefined) {
        return this._user;
      }
      if (tmp !== undefined && tmp !== null && tmp !== '') {
        this._user = JSON.parse(this._rsaService.decrypt(localStorage.getItem('user'))) as User;
        if (this._user !== null && this._user !== undefined && this._user.userId !== undefined) {
          return this._user;
        }
      }
      this.clearSavedUser();
      return null;
    } catch (error) {
      console.error(error);
      console.log(tmp);
      this.clearSavedUser();
      return null;
    }
  }

  setMultiUsers(multiUsers: SelectItem[]) {
    this.multiUsers = multiUsers;
  }

  getMultiUsers() {
    return this.multiUsers;
  }




}
