import { CodeInputModule } from 'angular-code-input';
import { CodeInputControlComponent } from './code-input-control.component';
import { NgModule,Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {  MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  imports:[
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    CodeInputModule
  ],
  declarations:[
    CodeInputControlComponent
  ],
  exports:[
    CodeInputControlComponent
  ]
})
export class CodeInputControlModule { }
