import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs-compat';

import { PupilCardData } from '../_models/pupilCard/PupilCardData'

@Injectable({
  providedIn: 'root'
})
export class PupilCardSharedService {

  //private dataSource = new BehaviorSubject<PupilCardData>(new PupilCardData());
  private dataSource = new BehaviorSubject<PupilCardData>(new PupilCardData());
  private tabs = new ReplaySubject<string[]>();
  private icons = new BehaviorSubject<any[]>([]);
  private currentTab = new BehaviorSubject<string>('');
  private showDivisions = new BehaviorSubject<boolean>(true);
  private showBtn = new BehaviorSubject<boolean>(false);
  private enableBtn = new BehaviorSubject<boolean>(true);
  private clickedSaveBtn = new BehaviorSubject<boolean>(undefined);
  private notificationDate = new BehaviorSubject<any>(undefined);
  private showMatriculationGradesFromAllYears = new BehaviorSubject<boolean>(false);
  private showMatriculationMaxGradesOnly = new BehaviorSubject<boolean>(true);
  private visibleGradeTypes = new BehaviorSubject<any[]>(null);

  constructor() {
  }

  triggerClickEvent(value: boolean) {
    this.clickedSaveBtn.next(value);
  }

  updateNotificationData(data: any){
    this.notificationDate.next(data);
  }

  updateCurrentTab(data: string) {
    this.currentTab.next(data);
  }

  updatePupilCardData(data: PupilCardData) {
    this.dataSource.next(data);
  }

  updatePupilCardTabs(data: string[]) {
    this.tabs.next(data);
  }

  updatePupilCardIcons(data: any[]) {
    this.icons.next(data);
  }

  updateDivisionsAvailable(data: boolean) {
    this.showDivisions.next(data);
  }

  updateBtnVisible(data: boolean) {
    this.showBtn.next(data);
  }

  updateBtnEnable(data: boolean) {
    this.enableBtn.next(data);
  }

  getPupilCardData(){
    return this.dataSource.asObservable();
  }

  getPupilCardTabs() {
    return this.tabs.asObservable();
  }

  getPupilCardIcons() {
    return this.icons.asObservable();
  }

  getCurrentTab() {
    return this.currentTab.asObservable();
  }

  getShowDivisions() {
    return this.showDivisions.asObservable();
  }

  getBtnVisible() {
    return this.showBtn.asObservable();
  }

  getBtnEnable() {
    return this.enableBtn.asObservable();
  }

  getClickEvent() {
    return this.clickedSaveBtn.asObservable();
  }

  getNotificationData(){
    return this.notificationDate.asObservable();
  }

  getshowMatriculationFromAllYears() {
    return this.showMatriculationGradesFromAllYears.asObservable();
  }

  setshowMatriculationAllYears(value: boolean) {
    this.showMatriculationGradesFromAllYears.next(value);
  }

  getshowMatriculationMaxGradeOnly() {
    return this.showMatriculationMaxGradesOnly.asObservable();
  }

  setshowMaxGradeOnly(value: boolean) {
    this.showMatriculationMaxGradesOnly.next(value);
  }
  
  setGradesToShow(value: any[]) {
    this.visibleGradeTypes.next(value);
  }

  getVisibleGradeTypes() {
    return this.visibleGradeTypes.asObservable();
  }

}
