import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
//import Hammer from 'hammerjs';
import { MobileService } from 'src/app/_services/mobile-service.service';

@Component({
  selector: 'app-swipe-to-refresh',
  templateUrl: './swipe-to-refresh.component.html',
  styleUrls: ['./swipe-to-refresh.component.css']
})
export class SwipeToRefreshComponent implements OnInit, AfterViewInit {
  @ViewChild('content', { static: true }) private content: ElementRef;
  @ViewChild('loaderSpace', { static: true }) private loaderSpace: ElementRef;

  @Input() maxLoaderHeight: number = 80;
  @Output() refreshEvent = new EventEmitter<any>();
  refreshing = false;

  pan = false;

  ngAfterViewInit() {
    /* const hammer = new Hammer(this.content.nativeElement);
    hammer.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL });

    hammer.on("panstart", ($e) => {
      this.pan = true;
    });

    hammer.on("pandown", ($event) => {
      this.setHeight($event.deltaY);
    });

    hammer.on("panup", ($event) => {
      this.setHeight($event.deltaY);
    });

    hammer.on("panend", ($event) => {
      if ($event.deltaY >= this.maxLoaderHeight) {
        this.refreshEvent.emit();
        this.refresh();
      } else if (!this.refreshing) {
        this.setHeight(0);
        this.pan = false;
      }
    }); */


  }
  constructor( public mobileService:MobileService) { }

  ngOnInit() {
  }

  public refresh() {
    if (this.refreshing) {
      return;
    }
    this.refreshing = true;
  }

  public stopRefresh() {
    this.refreshing = false;
    this.pan = false;
    this.setHeight(0);

  }

  private setHeight(distance: number) {
    if (this.refreshing) {
      return;
    }
    if (distance > this.maxLoaderHeight) {
      distance = this.maxLoaderHeight;
    } else if (distance < 0) {
      distance = 0;
    }

    this.loaderSpace.nativeElement.style.height = `${distance}px`;
  }

}

