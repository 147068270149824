export abstract class DoughtChartColor {
  static readonly colors: string[] = [
    '#3c596b', '#ee2764', '#025599', '#293846', '#0099cc', '#2b908f', '#68bd49', '#d75452', '#7c5496', '#ffdd00', '#f7a35c', '#f48fb1', '#8085e9', '#00897b', '#cb6b6b', '#85c092', '#d7aaaa', '#d0b5b0', '#757da7', '#007ab2', '#004666', '#002333', '#32bfff', '#99dfff', '#71cfae', '#004d40'
  ];
}

export abstract class ChartColors {
  static readonly colors: string[] = ['#8067DC',
    '#6794DC',
    '#DC67CE',
    '#DC6788',
    '#BED263',
    '#FE9462',
    '#C767DC',
    '#67B7DC',
    '#EAE362',
    '#71BB58',
    '#6771DC',
    '#A367DC',
    '#DC67AB',
    '#DC6967',
    '#F5CC62'];
  static readonly hoverColors: string[] = ['#6C4DDC',
    '#4D84DC',
    '#DC4DCB',
    '#DC4D75',
    '#B9D24A',
    '#FE8B56',
    '#C24DDC',
    '#4DAFDC',
    '#EAE24B',
    '#60BB41',
    '#4D59DC',
    '#964DDC',
    '#DC4DA0',
    '#DC4F4D',
    '#F5C549']
}
