import { SharedService } from './../../../_services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { PostService } from 'src/app/_services/postService';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GetUserViaSearches } from 'src/app/_models/messageBox/Recipients';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['../inputs.css','./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {

  @Input() searchList: any[];
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() apiUrl: string;
  @Input() requestObj: any;
  @Input() placeHolder: string = "";
  @Input() floatLabel ="auto"
  @Input() showIcon: boolean = false;
  @Input() icon: string;
  @Input() setInputText: (args: any) => string;

  @Output() selectedvalue = new EventEmitter<any>();

  @ContentChild('autocompleteList') autocompleteList: TemplateRef<any>;

  control: FormControl = new FormControl();
  filteredsearchUsers: Observable<GetUserViaSearches[]>;
  searchOnServer: boolean = true;
  resultAsk = 0;
  resultAskArr = []
  enterLoop = true;
  allsearchUsers: GetUserViaSearches[] = [];

  constructor(
    private postSrv: PostService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
    this.filteredsearchUsers = this.control.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      map((searchUser: GetUserViaSearches | null) => searchUser ?  this._filter(searchUser) : this.tmp(searchUser)));
  }

  tmp(searchUser): any[] {
    return [];
  }

  resumeSearch() {
    this.enterLoop = true;
  }

  clearText() {
    this.control.setValue('');
  }

  ngOnInit() {
    if (this.disabled)
      this.control.disable();
  }

  private _filter(value: any): any[] {
    this.resultAsk = (new Date()).getTime();
    this.resultAskArr.push(this.resultAsk);
    if (!this.enterLoop) {
      return this.allsearchUsers;
    } else if (this.searchList !== undefined && this.searchList !== null && value.length > 0) {
      return this.searchList.filter(x => x.firstName.includes(value) || x.lastName.includes(value)
        || x.firstName.includes(this.sharedService.replcaeLanguage(value))
        || x.lastName.includes(this.sharedService.replcaeLanguage(value)))
    } else if (value.length > 0) {
      this.requestObj["SearchQuery"] = value;
      this.postSrv.postData<any[]>(this.apiUrl,
        true,
        this.requestObj).pipe(take(1))
        .subscribe((result: baseResult<any[]>) => {
          if(this.resultAsk != this.resultAskArr.slice(-1)[0]) { // show only the last result
            return;
          }
          if (result.data === null || result.data.length === 0) {
            this.allsearchUsers = [];
            if (this.enterLoop) {
              this.enterLoop = false;
              this.control.setValue(this.control.value);
            }
            return this.allsearchUsers;
          } else {

            this.allsearchUsers = result.data;
            if (this.enterLoop) {
              this.enterLoop = false;
              this.control.setValue(this.control.value);
            }
            return this.allsearchUsers;
          }
        });
    }

    return this.allsearchUsers;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.control.setValue(this.setInputText(event.option.value));
    this.selectedvalue.emit(event.option.value);
  }

  onKeyUp(){
    if (this.control.value.length == 0)
      this.selectedvalue.emit(null);
  }

}
