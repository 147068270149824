import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { FilesService } from 'src/app/_services/files.service';
import { FileData } from '../../../_models/FileData';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ImageDialogComponent } from '../../dialogs/image-dialog/image-dialog.component';
import { PostService } from 'src/app/_services/postService';
import { HttpResponse } from '@angular/common/http';
import { baseResult } from 'src/app/_models/baseResult';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.css']
})
export class FileUploaderComponent implements OnInit {

  @Input() multiFiles: boolean = false;
  @Input() control: UntypedFormControl;
  @Input() allowFilesExtesnions = '';
  @Input() AttachedFileText = 'AttachedFileText';
  @Input() ExsitingFile: FileData[] = [];
  @Output() removeFile = new EventEmitter<FileData>();
  @Output() addFile = new EventEmitter<File | File[]>();

  filesList: File[] = [];
  imageExtension = ['.jpeg', '.jpg', '.png', '.gif', '.tiff', '.exif', '.tif', '.jpe'];

  constructor(
    private translate: TranslateService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private fileService: FilesService,
    private postSrv: PostService,

  ) {
  }

  uploadFile(file: File): void {
    if (!file) { return; }
    const formData = new FormData();
    formData.append('file', file);

    const subscribtion = this.postSrv.postWithFile<boolean>('api/general/CheckFile',
      true,
      formData).subscribe((result: HttpResponse<baseResult<boolean>>) => {
        if (result.body !== undefined) {
          if (result.body.data) {
            this.control.setValue(file);
            this.control.markAsDirty();
            this.addFile.emit(file)
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('invalidFile'), type: 'warning' }
            });
          }
          subscribtion.unsubscribe();
        }
      });
  }

  uploadFiles(files: File[]): void {

    const formData = new FormData();
    for (var i=0;i<files.length;i++){
      formData.append('file', files[i]);
    }

    const subscribtion = this.postSrv.postWithFile<boolean>('api/general/CheckFile',
      true,
      formData).subscribe((result: HttpResponse<baseResult<boolean>>) => {
        if (result.body !== undefined) {
          if (result.body.data) {
            for (var i = 0; i < files.length; i++)
              this.filesList.push(files[i]);
            this.control.setValue(this.filesList);
            this.control.markAsDirty();
            this.addFile.emit(this.filesList)
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('invalidOneFile'), type: 'warning' }
            });
          }
          subscribtion.unsubscribe();
        }
      });



  }

  removeSinglefile(file: File, e: Event) {
    e.stopPropagation();
    this.filesList = this.filesList.filter(x => x != file);
    this.control.setValue(this.filesList);
    this.control.markAsDirty();
    this.addFile.emit(this.filesList)
  }

  removeExistsfile(file: FileData, e: Event) {
    e.stopPropagation();
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      disableClose: false,
      data: { message: this.translate.instant('deleteattachedFile'), confirmBtn: this.translate.instant('Delete'), cancelBtn: this.translate.instant('cancelBtn') },
      autoFocus: true
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeFile.emit(file);
      }
    });
  }

  removefile(e: Event): void {
    e.stopPropagation();
    this.control.setValue(null);
    this.control.markAsDirty();
    this.removeFile.emit(null);
  }

  ngOnInit(): void {
    if (this.multiFiles)
      this.AttachedFileText = 'AttachedFilesText';
  }


  getFile() {
    return (this.control.value as FileData);
  }

  public download(file: FileData) {
    if (this.isImage(file)) {
      return this.preview(file);
    }
    this.fileService.download(file.fileUrl.slice(file.fileUrl.indexOf('token=') + 6), file.fileName);
  }

  preview(file: FileData) {
    this.matDialog.open(ImageDialogComponent, {
      disableClose: false,
      data: {
        title: file.fileName,
        fileUrl: file.fileUrl
      },
      autoFocus: true
    });
  }

  isImage(file: FileData): boolean {
    return this.imageExtension.includes(file.fileExtantion.toLowerCase());
  }

}
