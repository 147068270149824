import { SwipeToRefreshComponent } from './../common/UIControls/swipe-to-refresh/swipe-to-refresh.component';
import { HelpButtonComponent } from './../common/UIControls/help-button/help-button.component';
import { AutoCompleteComponent } from './../common/UIControls/auto-complete/auto-complete.component';
import { ToggleButtonComponent } from './../common/UIControls/toggle-button/toggle-button.component';
import { ScrollToTopComponent } from './../common/UIControls/scroll-to-top/scroll-to-top.component';
import { TableComponent } from './../common/UIControls/table/table/table.component';
import { SliderComponent } from './../common/UIControls/slider/slider.component';
import { LottieComponent } from './../common/UIControls/lottie/lottie.component';
import { RadioButtonComponent } from './../common/UIControls/radio-button/radio-button.component';
import { IconsListComponent } from './../common/UIControls/icons-list/icons-list.component';
import { DragAndDropComponent } from './../common/dragAndDrop/drag-and-drop/drag-and-drop.component';
import { BtnIconComponent } from './../common/UIControls/btn-icon/btn-icon.component';
import { RadioButtonsGroupComponent } from './../common/UIControls/radio-buttons-group/radio-buttons-group.component';
import { SpanComponent } from './../common/UIControls/span/span.component';
import { PipesModule } from './pipes.module';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonFlatComponent } from '../common/UIControls/button-flat/button-flat.component';
import { ButtonComponent } from '../common/UIControls/button/button.component';
import { FabIconComponent } from '../common/UIControls/fab-icon/fab-icon.component';
import {FabButtonContainerComponent} from '../common/UIControls/fab-button-container/fab-button-container.component'
import { MatButtonModule } from '@angular/material/button';
import { TextComponent } from '../common/UIControls/text/text.component';
import { MaterialModule } from './material-module';
import { CheckBoxGroupComponent } from '../common/UIControls/check-box-group/check-box-group.component';
import { CheckboxComponent } from '../common/UIControls/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { SelectComponent } from '../common/UIControls/select/select.component';
import { TabsComponent } from '../common/UIControls/tabs/tabs.component';
import { AccessibilitySettingsComponent } from '../settings/accessibility-settings/accessibility-settings.component';
import { AvatarModule } from '../shared/avatar/avatar.module';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { InlineSearchComponent } from '../common/UIControls/inline-search/inline-search.component';
import { InlineSelectComponent } from '../common/UIControls/inline-select/inline-select.component';
import { InlineTextComponent } from '../common/UIControls/inline-text/inline-text.component';
import { InlineTextareaComponent } from '../common/UIControls/inline-textarea/inline-textarea.component';
import { LayoutModule } from '@angular/cdk/layout';
import { LayoutsModule } from './layouts.module';
import { BadgesModule } from './badges.module';
import { ClickableTooltipDirective } from '../_directives/clickableTooltip-Directive';
export function playerFactory() {
  return player;
}


@NgModule({
  declarations: [
    ToggleButtonComponent,
    ButtonFlatComponent,
    FabButtonContainerComponent,
    FabIconComponent,
    ButtonComponent,
    HelpButtonComponent,
    TextComponent,
    CheckboxComponent,
    CheckBoxGroupComponent,
    SelectComponent,
    SpanComponent,
    RadioButtonsGroupComponent,
    RadioButtonComponent,
    TabsComponent,
    BtnIconComponent,
    IconsListComponent,
    DragAndDropComponent,
    AccessibilitySettingsComponent,
    SliderComponent,
    LottieComponent,
    InlineSelectComponent,
    InlineTextareaComponent,
    InlineTextComponent,
    InlineSearchComponent,
    SwipeToRefreshComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    TranslateModule,
    MatButtonModule,
    MaterialModule,
    //FlexLayoutModule,
    BadgesModule,
    PipesModule,
    ClickableTooltipDirective,
    [LottieModule.forRoot({ player: playerFactory })]
  ],
  exports: [
    ClickableTooltipDirective,
    ToggleButtonComponent,
    HelpButtonComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    ButtonFlatComponent,
    FabButtonContainerComponent,
    FabIconComponent,
    ButtonComponent,
    MatButtonModule,
    BadgesModule,
    TextComponent,
    CheckboxComponent,
    CheckBoxGroupComponent,
    //FlexLayoutModule,
    SelectComponent,
    SpanComponent,
    RadioButtonsGroupComponent,
    RadioButtonComponent,
    TabsComponent,
    BtnIconComponent,
    IconsListComponent,
    DragAndDropComponent,
    AccessibilitySettingsComponent,
    SliderComponent,
    LottieComponent,
    InlineSelectComponent,
    InlineTextareaComponent,
    InlineTextComponent,
    InlineSearchComponent,
    SwipeToRefreshComponent
  ]
  })
  export class UIModule { }
