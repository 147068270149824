import { TranslateService } from '@ngx-translate/core';
import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CheckBoxGroup } from '../../../_models/sharedModels';

@Component({
  selector: 'app-check-box-group',
  templateUrl: './check-box-group.component.html',
  styleUrls: ['./check-box-group.component.css']
})
export class CheckBoxGroupComponent implements OnInit, AfterContentChecked {


  @Input() task: CheckBoxGroup;
  @Output() selectedValue = new EventEmitter<CheckBoxGroup>();
  @Output() checkboxTextChanged = new EventEmitter<any>();
  @Input() control?: UntypedFormControl;
  @Input() IsInner = false;
  @Input() allowSelectAll = true;
  @Input() innerCb = true;
  @Input() textBold = false;
  @Input() showSelectAll = true;
  @Input() disabled = false;
  @Input() checkBoxCss = '';

  constructor(
    private translate: TranslateService
  ) {

  }
  ngAfterContentChecked() {
    this.task.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);

  }

  ngOnInit(): void {
  }

  getText() {
    if (!this.showSelectAll) {
      return this.task.name;
    }
    return this.translate.instant(this.task.allComplete ? 'Unselect' : 'selectAll');
  }
  textChanged($event, value) {
    if (this.disabled) {
      return;
    }
    this.task.subtasks.filter(x => x.value === value)[0].completed = $event.checked;
    this.checkboxTextChanged.emit({'value': value , 'text': $event.text});
  }

  updateAllComplete(item: CheckBoxGroup, completed: boolean) {
    if (this.disabled) {
      return;
    }
    item.completed = completed;
    this.task.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    this.selectedValue.emit(item);
  }

  setAll(task: CheckBoxGroup , completed: boolean) {
    if (this.disabled) {
      return;
    }
    task.allComplete = completed;
    if (task.subtasks == null) {
      return;
    }
    task.subtasks.forEach(t => {
      t.completed = completed;
      if (t.subtasks != null) {
        this.setAll(t, completed);
      }
    });
    this.selectedValue.emit(this.task);
  }
}
