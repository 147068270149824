import { Component, Input, OnInit } from '@angular/core';
import { BehaviourGrade } from 'src/app/_models/myPupils/myPupils';

@Component({
  selector: 'app-student-behavior-grade',
  templateUrl: './student-behavior-grade.component.html',
  styleUrls: ['./student-behavior-grade.component.css']
})
export class StudentBehaviorGradeComponent implements OnInit {

  @Input() behaviorGrade: BehaviourGrade;
  @Input() removeContentPadding: boolean = false;
  @Input() isBehaviourGrade: boolean = true;
  
  constructor() { }

  ngOnInit(): void {
  }

}
