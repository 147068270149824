// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lyCropper {
  margin: 16px 0;
}


rotat_btn{
  text-align: center;
  padding: 8px;
}
::ng-deep .LyImageCropper-area-g {
 height: 320px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/cropper-dialog/cropper-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;;AAGA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;CACC,wBAAwB;AACzB","sourcesContent":[".lyCropper {\r\n  margin: 16px 0;\r\n}\r\n\r\n\r\nrotat_btn{\r\n  text-align: center;\r\n  padding: 8px;\r\n}\r\n::ng-deep .LyImageCropper-area-g {\r\n height: 320px !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
