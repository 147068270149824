export class FileSize{
  size: number;
  sizeName: string;
}

export class FileData {
  id: number;
  fileName: string;
  fileUrl: string;
  fileSize: FileSize;
  fileExtantion: string;
}
