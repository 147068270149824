export class PupilCardData {
  studyYear: number;
  studentID: number | string;
  periodID: number;
  weekIndex: number = 0
  viewType: number = 0;
  moduleID: number;
  classCode: string;
  studentName: string;
  periodName: string;
  studyYearName: string;
  allYearsData: boolean;
  maxGradeOnly: boolean;
}
