import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SharedService } from 'src/app/_services/shared.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css','../inputs.css']
})
export class TextareaComponent implements OnInit ,OnChanges {

  @Input() label: string;
  @Input() control: UntypedFormControl;
  @Input() value = '';
  @Input() rows = 3;
  @Input() showCounter = false;
  @Input() disabled = false;
  @Input() maxlength = 2000;
  @Input() public compTabIndex = 0;


  ltrClass = 'textAlignRight';
  constructor(
    public shared: SharedService

  ) { }

  ngOnInit(): void {
    this.dataChanged(null);
    if(this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control.setValue(this.value);
    if(this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  dataChanged($event) {
    if (!this.shared.isUnicode(this.control.value))  {
      this.ltrClass = 'textAlignLeft ltr';
    } else {
      this.ltrClass = 'textAlignRight';
    }
    //this.control?.setValue($event);
  }

  setValue( txt) {
    this.control.setValue(txt);
  }
  disabledCtrl() {
    this.control.disable();
  }
  enableCtrl(){
    this.control.enable();
  }
}
