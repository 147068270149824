import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @Input() fontSize: number = 100;
  @Input() minSize: number = 100;
  @Input() maxSize: number = 150;
  @Input() step : number = 10;
  @Input() showChar : boolean = true;
  @Input() cssClass : string = '';

  @Output() changeFontSize = new EventEmitter<number>();

  constructor(
    public shared: SharedService,

  ) { }

  ngOnInit(): void {
  }

  resetSize() {
    this.fontSize = 100;
  }

  changeFontSizeClick(x) {
    if(x < 0 && this.fontSize === this.minSize) {
      return;
    }
    if(x > 0 && this.fontSize === this.maxSize) {
      return;
    }
    this.fontSize += x;

    this.changeFontSize.emit(this.fontSize);
  }

  setFontSize(value) {
    this.fontSize = value;
  }

  sliderChange($event) {
    this.fontSize = $event.value;
    this.changeFontSize.emit(this.fontSize);
  }




}
