import { SchedualeExams, SchedulaeChanges, ShotefEvents } from "../shotef/ChangesAndMessages";

export class PupilCardScheduale {
  dayIndex: number;
  hoursData: SchedualeHour[];
}

export class SchedualeHour{
  hour: number;
  hourName: string;
  scheduale: Lesson[];
  changes: SchedulaeChanges[];
  exams: SchedualeExams[];
  events: ShotefEvents[];
  isAllowed: boolean;

}

export class Lesson {
  day: number;
  hour: number;
  subject: string;
  subjectLevel: string;
  teacherLastName: string;
  teacherPrivateName: string;
  room: string;
  roomID: number;
  classes: string;
  capsule: string;
  changes: SchedulaeChanges[];
  studyGroupID: any;
  isItemFromScheduale: boolean;
}
