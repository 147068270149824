import { SharedService } from './../../../../_services/shared.service';


import { TableColumn, TableColumnFooter } from './../tableTypes';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';

import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.css']
})
export class SimpleTableComponent implements OnInit {


  @Input() dataSource: any;
  ds: MatTableDataSource<any>;
  @Input() showCounter = false;
  @Input() isEditMode = false;
  @Input() showFooter = false;
  @Input() showHint = false;
  @Input() noData: string = 'noData2';
  @Input() noDataCss: string = '';
  @Input() sortActive = '';
  @Input() index = '';
  @Input() cbEditEvent = '';
  @Input() sortDirection: SortDirection = 'asc';
  @Input() tableHeight = 350;
  @Input() columnsFooter: TableColumnFooter[] = [];
  @Input() columns: TableColumn[] = [];

  @Output() loadComplete = new EventEmitter();
  @Output() externalSort = new EventEmitter<any>();

  isLoading = true;
  roundGrades = true;
  gradesArr = [];
  selectedRows:any = []
  hover = '';
  hoverIndex = -1;
  @Input() displayedColumns: string[] = [];



  private paginator: MatPaginator;
  private sort: MatSort;
  showRoundGrades: boolean;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }
  setIsLoading(flag) {
    this.isLoading = true;
  }
  updateTableData(dataSource) {
    this.isLoading = true;
    this.dataSource = dataSource;
    if (this.cbEditEvent != '') {
      this.selectedRows = [];
      this.dataSource.forEach(x => {
        if (x.events[this.cbEditEvent].value) {
          this.selectedRows.push(x[this.index]);
        }
      });
    }
    this.setDataSourceAttributes();
  }

  sortData($event) {
    if (this.externalSort.observers.length > 0) {
      this.externalSort.emit($event);
    }
  }

  setSortActive(sortActive, sortDirection = 'asc') {
    this.sortActive = sortActive;
    if (sortDirection == 'asc')
      this.sortDirection = 'asc';
    else
      this.sortDirection = 'desc';
  }

  updateTableColumn(data) {
    this.columns = data;
  }
  mouseenter(i) {
    this.hoverIndex = i;
    this.hover = 'rowHover';
  }
  mouseleave() {
    this.hoverIndex = -1;
    this.hover = '';
  }

  showCb(i) {
    if (this.hoverIndex == i || this.isEditMode) return 'showCb';
  }

  checkHover(i) {
    if (this.hoverIndex == i) return this.hover;
  }




  setDataSourceAttributes() {
    setTimeout(() => {

      this.ds = new MatTableDataSource(this.dataSource);
      this.ds.paginator = this.paginator;
      if (this.externalSort.observers.length == 0) {
        this.ds.sort = this.sort;
        if (this.ds.sort != undefined) {
          this.ds.sort.active = this.sortActive;
          this.ds.sort.direction = this.sortDirection;
        }
      }
      this.isLoading = false;
    }, 10);
  }


  readDataComplete = false;
  constructor(
    public sharedService: SharedService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private changeDetector: ChangeDetectorRef,

  ) {

  }




  ngOnInit(): void {

  }







  isSticky(id: string) {
    return this.columns.filter(x => x.id == id)[0]?.isSticky;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.readDataComplete = true;
    }, 1000);
  }


  showFooterData(index) {
    return this.showFooter && this.columnsFooter.filter(x => x.columnIndex == index).length > 0;
  }

  getFooterData(index, column) {
    let data = this.columnsFooter.filter(x => x.columnIndex == index)[0];
    switch (data.columnType) {
      case 1:
        return data.text;

      default:
        return '';
    }
  }

  getFooterInnerHtmlData(index, column) {
    let data = this.columnsFooter.filter(x => x.columnIndex == index)[0];
    return data?.innerHtml ?? '';
  }


  changeFocus(x) {
    if (x < 0) {
      x = (this.dataSource as []).length - 1;
    } else if (x >= (this.dataSource as []).length - 1) {
      x = 0
    }
    const elem = document.getElementById('row-' + x);

    elem?.focus();
  }


  splitData(data){
    return data?.split('**##**')?? []
  }




}
