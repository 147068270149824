import { MobileService } from 'src/app/_services/mobile-service.service';
import { DragAndDrop, DragAndDropHeader } from './../drag-and-drop';

import { SortItem } from './../../../_models/sortItem';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css']
})
export class DragAndDropComponent implements OnInit {

  @Input() items: SortItem[] = [];
  @Input() itemsComponent: DragAndDrop[] = [];
  @Input() headrs: DragAndDropHeader[] = [];

  @Input() emptyItemsMessage = '';
  @Input() inMainCss = '';
  @Input() cssClass = '';
  @Input() dragCss = '';
  @Output() sortChange = new EventEmitter<any[]>();
  @Output() onDataChanged = new EventEmitter<any>();
  @Output() onItemClicked = new EventEmitter<any>();
  index1 = -1;
  @Input() disabledDrag = false;
  constructor(
    public mobileService: MobileService
  ) { }

  ngOnInit(): void {
  }


  drop(event: CdkDragDrop<string[]>) {

    if (this.items != null && this.items.length > 0) {
      moveItemInArray(this.items, event.previousIndex, event.currentIndex);
      this.sortChange.emit(this.items);
    }
    else if (this.itemsComponent != null && this.itemsComponent.length > 0) {
      moveItemInArray(this.itemsComponent, event.previousIndex, event.currentIndex);
      this.sortChange.emit(this.itemsComponent);
    }
  }
  trackByFn(index: number, item: String) {
    return index;
  }

  changeFocus(x) {
    if (x < 0) {
      x = this.items.length - 1;
    } else if (x > this.items.length - 1) {
      x = 0;
    }
    document.getElementById('row' + x).focus();
  }

  newSort(i: number) {
    if (this.index1 === -1) {
      this.index1 = i;
      document.getElementById('row' + i)?.classList?.add('bg');
      return;
    }
    document.getElementById('row' + this.index1)?.classList?.remove('bg');
    if (this.items != null && this.items.length > 0) {
      moveItemInArray(this.items, this.index1, i);
      this.sortChange.emit(this.items);
    }
    else if (this.itemsComponent != null && this.itemsComponent.length > 0) {
      moveItemInArray(this.itemsComponent, this.index1, i);
      this.sortChange.emit(this.itemsComponent);
    }
    this.index1 = -1;
  }

  onRowClick(innerItem, item) {
    this.onItemClicked.emit({ innerItem: innerItem, item: item });
  }

  dataChaned(value, item, innerItem) {
    this.onDataChanged.emit({
      value: value,
      item: item,
      innerItem: innerItem,
      items: this.itemsComponent
    });
  }

  getInMainItems(item, flag) {
    return item.items.filter(x => x.inMain == flag);
  }
}
