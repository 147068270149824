import { SharedService } from 'src/app/_services/shared.service';
import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formattedDate', pure: false })
export class formattedDate implements PipeTransform {
constructor(
    private translate: TranslateService,
    private shared: SharedService
  ) {}

  private days: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  transform(date: Date | string, viewDay: boolean = true, viewHours: boolean= false, addSperator: boolean= false, showText: boolean = false): string {
    let format = this.translate.instant('dateFormat');
    if (viewHours) {
        format += ' (HH:mm)';
    }
    date = new Date(date);
    const daySring = this.shared.getLang() == "en"?'':(this.translate.instant('Day') + ' ');
    if (showText) {
        if (date.setHours(24, 0, 0, 0) === (new Date()).setHours(24, 0, 0, 0)) {
          return this.translate.instant('Today');
        }
        if (date.setHours(24, 0, 0, 0) === (new Date()).setHours(24, 0, 0, 0) - 1 ) {
          return this.translate.instant('Yesterday');
        }
    }
    if (addSperator) {
      return (viewDay ? (daySring  + this.translate.instant(this.days[date.getDay()]) + ' | ') : '') + new DatePipe('en-US').transform(date, format);
    }
    try {
      let tmp =  (viewDay ? ((daySring + this.translate.instant(this.days[date.getDay()])) + ', ') : '') + new DatePipe('en-US').transform(date, format);
      return tmp;

    } catch {
      console.log(date)
    }
  }
}
