import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'numToMonth'
})
export class NumToMonthPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) { }

  private months: string[] = ['','January','February','March','April','May','June','July','August','September','October','November','December']


  transform(value: any, isShort: boolean= false): string {
    if (isShort) {
      return this.translate.instant(this.months[value] + 'Short');
    } else { return this.translate.instant(this.months[value]); }
  }

}
