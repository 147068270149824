import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];




if (window.location.host.toLowerCase() !== 'localhost:4200' && window.location.host.toLowerCase() !== '192.168.4.42:4200') {
  //window.console.log = () => { }
  enableProdMode();
}
const arrHideError = [
  "Cannot read properties of null (reading 'insertBefore')",
  'Expression has changed after it was checked.',
  'Host already has a portal attached'
]
window.console.error = (e, exp) => {
  let flag = true;
  arrHideError.forEach(x => {
    if (exp?.message.includes(x))
      flag = false;
  });
  if (flag && exp && exp?.stack) console.log(exp?.stack);
}
//window.console.error = () => { };

platformBrowserDynamic(providers).bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.

  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;

  // Otherwise, log the boot error
}).catch(err => console.log(err));
