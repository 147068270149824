import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { SharedService } from '../_services/shared.service';

@Directive({
    selector: '[swipe]'
})
export class SwipeDirectiveDirective {
    @Output() swipeLeft = new EventEmitter();
    @Output() swipeRight = new EventEmitter();
    @Output() touchMovePosition = new EventEmitter();
    @Output() touchStartPosition = new EventEmitter();

    @Input() elem: any = document;
    @Input() minSwipeWidth: number = 60;

    constructor(private shared: SharedService, private el: ElementRef) { }

    ngAfterViewInit() {
        this.startOnTouch();
    }

    defaultTouch = { x: 0, y: 0, time: 0 };
    touchStart(touch, event) {
        this.defaultTouch.x = touch.clientX;
        this.defaultTouch.y = touch.clientY;
        this.defaultTouch.time = event.timeStamp;
        this.touchStartPosition.emit(event.touches[0].clientX)
    }

    touchEnd(touch, event) {
        const deltaX = event.changedTouches[0].clientX - this.defaultTouch.x;
        const startScreenPoint = this.shared.isRtl() ? (window.innerWidth - this.defaultTouch.x) : this.defaultTouch.x;
        const endScreenPoint = this.shared.isRtl() ? (window.innerWidth - event.changedTouches[0].clientX) : event.changedTouches[0].clientX;
        if (Math.abs(deltaX) > this.minSwipeWidth) {
            if (deltaX > 0) {
                this.swipeRight.emit({ "startPoint": startScreenPoint, "endPoint": endScreenPoint });
            } else {
                this.swipeLeft.emit({ "startPoint": startScreenPoint, "endPoint": endScreenPoint });
            }
        }
        else if (deltaX != 0){
            if (deltaX > 0) {
                this.swipeRight.emit(null);
            } else {
                this.swipeLeft.emit(null);
            }
        }
    }

    touchMove(touch, event) {
        this.touchMovePosition.emit(event.touches[0].clientX);
    }

    startOnTouch() {
        const element = this.el.nativeElement;

        const touchStart$ = fromEvent(element, 'touchstart');
        const touchMove$ = fromEvent(element, 'touchmove');
        const touchEnd$ = fromEvent(element, 'touchend');

        const subscription = merge(touchStart$, touchMove$, touchEnd$).subscribe((event: TouchEvent) => {
            let touch = event["touches"][0] || event["changedTouches"][0];
            if (event.type === 'touchstart') {
                this.touchStart(touch, event);
            }
            else if (event.type === 'touchmove') {
                this.touchMove(touch, event);
            }
            else if (event.type === 'touchend') {
                this.touchEnd(touch, event);
            }
        });
    }
}
