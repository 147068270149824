// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolTip {
  font-size: var(--font-size--default) !important;
  font-family: var(--regularFont);
  font-weight: normal;
  background-color: white;
  border-radius: var(--tooltip-radius) !important;
}
.toolTipText {
  background-color: var(--tooltip-background-color);
  color: var(--tooltip-text-color) !important;
  border-radius: var(--tooltip-radius) !important;
  padding: 4px;
  max-width: 250px;
}
.inputBtn {
  background-color: transparent;
  color: transparent;
  border: 0;
  background: none;
  -webkit-appearance: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 1px;
  height: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/help-button/help-button.component.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,+BAA+B;EAC/B,mBAAmB;EACnB,uBAAuB;EACvB,+CAA+C;AACjD;AACA;EACE,iDAAiD;EACjD,2CAA2C;EAC3C,+CAA+C;EAC/C,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,gBAAgB;EAChB,wBAAwB;EACxB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;EACV,WAAW;AACb","sourcesContent":[".toolTip {\r\n  font-size: var(--font-size--default) !important;\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  background-color: white;\r\n  border-radius: var(--tooltip-radius) !important;\r\n}\r\n.toolTipText {\r\n  background-color: var(--tooltip-background-color);\r\n  color: var(--tooltip-text-color) !important;\r\n  border-radius: var(--tooltip-radius) !important;\r\n  padding: 4px;\r\n  max-width: 250px;\r\n}\r\n.inputBtn {\r\n  background-color: transparent;\r\n  color: transparent;\r\n  border: 0;\r\n  background: none;\r\n  -webkit-appearance: none;\r\n  position: absolute;\r\n  top: 0px;\r\n  left: 0px;\r\n  width: 1px;\r\n  height: 1px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
