import { PostService } from './../../_services/postService';
import { PupilCardSharedService } from 'src/app/_services/PupilCardSharedService';
import { Router, Params } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { NotificationData } from 'src/app/_models/Notifications/Notification';
import { baseResult } from 'src/app/_models/baseResult';
import { HeaderCountersService } from 'src/app/_services/headerCounters.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-unread-notifications-preview',
  templateUrl: './unread-notifications-preview.component.html',
  styleUrls: ['./unread-notifications-preview.component.scss']
})
export class UnreadNotificationsPreviewComponent implements OnInit {

  @Input() notifications: NotificationData[]
  @ViewChildren('item') items: ElementRef;
  @Output() messageClicked = new EventEmitter();
  currentElment = 0;
  constructor(
    private route: Router,
    private sharedSrv: PupilCardSharedService,
    private postSrv: PostService,
    private countersSrv: HeaderCountersService
  ) { }

  openNotification(item: NotificationData):void{
    const notifications = [item.itemId];
    this.postSrv.postData('api/Notification/UpdateNotificationReadStatus',
    true,
    {
      'isRead': true,
      'notifiactionsList': notifications
    }
  ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
    if (result.data) {
      this.countersSrv.changeUnreadNotifications(-1);
      let notificationDate: any = {};
      if(item.icon_id === 1)
      {
        notificationDate.studentID = item.studentID;
        notificationDate.date = item.date;
      }
      var extraData = item.extra_data.split("&");
      extraData.forEach(data => {
        var currentData = data.split("=");
        notificationDate[currentData[0]] = currentData[1];
      });
      this.sharedSrv.updateNotificationData(notificationDate);
      this.route.navigate([item.moduleNavigation] );
    }
  });

  }

  ngOnInit() {}

  closeMenu() {
    this.messageClicked.emit();
  }
  menuOpened() {
    setTimeout(() => {
      (this.items as any)._results[0]?.nativeElement.focus();
    }, 0);
  }
  changeFocus(x) {
    this.currentElment = this.currentElment + x;
    if (this.currentElment < 0 ) {
      this.currentElment = this.notifications.length - 1;
    }
    if (this.currentElment > this.notifications.length - 1  ) {
      this.currentElment = 0;
    }
    (this.items as any)._results[ this.currentElment].nativeElement.focus();
  }


}
