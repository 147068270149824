// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-content.mat-content-hide-toggle{
    margin: 0 !important;
}

::ng-deep .mat-accordion.panel-spaces .mat-expansion-panel.acoordio-panel.mat-expanded{
    margin: 16px 0;
    border-radius: 4px !important;
}

::ng-deep .mat-accordion.panel-spaces app-expansion-panel:first-child .mat-expansion-panel.acoordio-panel.mat-expanded{
    margin-top: 0;
}

::ng-deep .mat-accordion.panel-spaces app-expansion-panel:last-child .mat-expansion-panel.acoordio-panel.mat-expanded{
    margin-bottom: 0;
}

::ng-deep .mat-accordion app-expansion-panel .mat-expansion-panel{
    border-radius: 0 !important;
}

::ng-deep .mat-accordion app-expansion-panel:first-of-type .mat-expansion-panel{
    border-top-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
}

::ng-deep .mat-accordion app-expansion-panel:last-of-type .mat-expansion-panel{
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/accordion/accordion.component.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,uCAAuC;IACvC,sCAAsC;AAC1C;;AAEA;IACI,0CAA0C;IAC1C,yCAAyC;AAC7C","sourcesContent":["::ng-deep .mat-content.mat-content-hide-toggle{\r\n    margin: 0 !important;\r\n}\r\n\r\n::ng-deep .mat-accordion.panel-spaces .mat-expansion-panel.acoordio-panel.mat-expanded{\r\n    margin: 16px 0;\r\n    border-radius: 4px !important;\r\n}\r\n\r\n::ng-deep .mat-accordion.panel-spaces app-expansion-panel:first-child .mat-expansion-panel.acoordio-panel.mat-expanded{\r\n    margin-top: 0;\r\n}\r\n\r\n::ng-deep .mat-accordion.panel-spaces app-expansion-panel:last-child .mat-expansion-panel.acoordio-panel.mat-expanded{\r\n    margin-bottom: 0;\r\n}\r\n\r\n::ng-deep .mat-accordion app-expansion-panel .mat-expansion-panel{\r\n    border-radius: 0 !important;\r\n}\r\n\r\n::ng-deep .mat-accordion app-expansion-panel:first-of-type .mat-expansion-panel{\r\n    border-top-right-radius: 4px !important;\r\n    border-top-left-radius: 4px !important;\r\n}\r\n\r\n::ng-deep .mat-accordion app-expansion-panel:last-of-type .mat-expansion-panel{\r\n    border-bottom-right-radius: 4px !important;\r\n    border-bottom-left-radius: 4px !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
