import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-inline-textarea',
  templateUrl: './inline-textarea.component.html',
  styleUrls: ['./inline-textarea.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineTextareaComponent implements OnInit {

  @Input() value: string;
  @Input() placeHolder: string;
  @Input() disabled: boolean;
  @Input() maxLength: number;
  @Input() wordCounter: boolean = true;
  @Input() IsEdit = true;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() doubleClick = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  setIsEdit() {
    this.IsEdit = true;
  }
  dblclick() {
    this.doubleClick.emit();
  }

}
