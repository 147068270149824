import { UserService } from 'src/app/_services/user-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BreadCrumb } from 'src/app/_models/breadcrumb';
import { filter, distinctUntilChanged, take } from 'rxjs/operators';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  @Output() breadCrumbClick = new EventEmitter<any>();
  @Input() breadCrumbs: any[] = [];
  hideFrame: boolean;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public shared: SharedService,
    private user: UserService
  ) {
    this.breadcrumbsList = this.buildBreadCrumb(this.activatedRoute.root);
  }


  today: Date = new Date();
  subscription: Subscription;

  public breadcrumbsList: BreadCrumb[];

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: BreadCrumb[] = []): BreadCrumb[] {
    // If no routeConfig is avalailable we are on the root path

    let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

    // In the routeConfig the complete path is not available,
    // so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;
    const breadcrumb: BreadCrumb = {
        label: route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '',
        url: nextUrl === '' && route.routeConfig && route.routeConfig.data && route.routeConfig.data.breadcrumb ?
            ('/' + route.routeConfig.data.breadcrumb.substr(0, route.routeConfig.data.breadcrumb.lastIndexOf('_'))) : nextUrl,
        isActive: route.routeConfig && route.routeConfig.data ? route.routeConfig.data.active && route.firstChild != null : true,
        onClickValue : null
    };
    if (breadcrumb.url === '/spaces') {
      breadcrumb.url = '/learning_space';
    }
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
    if (route.firstChild) {
        // If we are not on our current path yet,
        // there will be more children to look after, to build our breadcumb
        return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  ngOnInit() {
    this.hideFrame = this.user.getHideFrame();;

    this.subscription  = this.router.events.pipe(
        filter((event: Event) => event instanceof NavigationEnd,),
        distinctUntilChanged(),
    ).subscribe(() => {
        this.breadcrumbsList = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }

  breadcrumbClickEvent(breadcrumb: BreadCrumb) {
    this.breadCrumbClick.emit(breadcrumb.onClickValue);
  }

}
