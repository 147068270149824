import { CropperComponent } from './../common/UIControls/cropper/cropper.component';
import { CropperDialogComponent } from './../common/UIControls/cropper-dialog/cropper-dialog.component';
import { AvatarModule } from './../shared/avatar/avatar.module';
import { SharedModule } from 'src/app/shared.module';
import { UIModule } from './../modules/UI.module';
import { MaterialModule } from './../modules/material-module';
import { PipesModule } from './../modules/pipes.module';
import { AvatarComponent } from './../shared/avatar/avatar.component';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileComponent } from './profile/profile.component';
import { SettingsComponent } from './settings.component';
import { ViewSettingsComponent } from './view-settings/view-settings.component';
import { UserImageComponent } from './user-image/user-image.component';
import { CameraDialogComponent } from '../common/UIControls/camera-dialog/camera-dialog.component';
import { Color } from '@alyle/ui/color';
import { MinimaLight} from '@alyle/ui/themes/minima';
import { StyleRenderer, LyTheme2, LY_THEME_NAME, LY_THEME } from '@alyle/ui';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { WebcamModule } from 'ngx-webcam';
import { NotificationsSettingsComponent } from './notifications-settings/notifications-settings.component';
import { CodeInputControlModule } from '../common/UIControls/code-input-control/code-input.module';


export class CustomMinimaLight {
  name = 'minima-light';
  demoBg = new Color(0x8c8c8c);
}

export type AppThemeVariables = MinimaLight

@NgModule({
  declarations: [
    SettingsComponent,
    ProfileComponent,
    ViewSettingsComponent,
    UserImageComponent,
    CameraDialogComponent,
    CropperDialogComponent,
    CropperComponent,
    NotificationsSettingsComponent

  ],
  imports:[
    UIModule,
    PipesModule,
    TranslateModule,
    MaterialModule,
    SharedModule,
    AvatarModule,
    LyImageCropperModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    WebcamModule,
    CodeInputControlModule

  ],
  exports: [
    SettingsComponent,
    ProfileComponent,
    ViewSettingsComponent,
    CropperComponent
  ],
  providers:[
    StyleRenderer,
    LyTheme2,
    { provide: LY_THEME_NAME, useValue: 'minima-light' },
    { provide: LY_THEME, useClass: MinimaLight, multi: true },
  ]

  }) export class SettingsModule { }
