import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';
import { ToggleSwipeItemService } from 'src/app/_services/toggle-swipe-item.service';
import { UserService } from 'src/app/_services/user-service.service';

@Component({
  selector: 'app-swipe-item',
  templateUrl: './swipe-item.component.html',
  styleUrls: ['./swipe-item.component.css']
})
export class SwipeItemComponent {
  @Input() swipeIcon: string;
  @Input() disableSwipe: boolean;
  @Input() componentId: string;
  showIcon: boolean = false;
  isSwiping: boolean = false;
  currentTranslateX: number = 0;
  startX: number = 0;
  deleted: boolean = false;
  minSwipeForDelete = window.innerWidth * 0.6;
  @Output() clicked = new EventEmitter();

  @ViewChild('swipeWrapper') swipeWrapper: HTMLElement;
  constructor(public shared: SharedService,
    public toggleSwipe: ToggleSwipeItemService,
    public user: UserService
  ) {

  }

  onIconClick() {
    this.currentTranslateX = this.shared.isRtl() ? window.innerWidth : (window.innerWidth * -1);
    this.showIcon = true;
    this.deleted = true;
    setTimeout(() => {
      this.clicked.emit(true);
    }, 250);
  }

  ngOnInit() {
    this.toggleSwipe.activeComponent$.subscribe((activeId) => {
      this.isSwiping = this.showIcon = (activeId === this.componentId);
      this.currentTranslateX = activeId != this.componentId ? 0 : this.currentTranslateX;
    });
  }

  swipeStart($event) {
    if (this.disableSwipe) {
      this.isSwiping = false;
      return;
    }
    this.toggleSwipe.setActiveComponent(this.componentId);
    this.startX = $event;
    this.isSwiping = true;
  }

  swipe(position: number) {
    if (!this.isSwiping) return;
    const diffX = position - this.startX;
   if (this.shared.isRtl() && diffX > 0)
      this.currentTranslateX = diffX;
    else if (!this.shared.isRtl() && diffX < 0)
      this.currentTranslateX = diffX;
    else this.currentTranslateX = 0;
  }

  swipeRight(swipeData: any) {
    if (this.shared.isRtl())
      this.swipeEnd(swipeData)
  }

  swipeLeft(swipeData: any) {
    if (!this.shared.isRtl())
      this.swipeEnd(swipeData)
  }

  swipeEnd(swipeData: any) {
    if (!this.isSwiping) return;
    if (swipeData == null) {
      this.currentTranslateX = 0;
      this.showIcon = false;
    }
    this.isSwiping = false;
    const swipeWidth = swipeData?.startPoint - swipeData?.endPoint;
    if (swipeWidth > this.minSwipeForDelete) {
      this.onIconClick();
    }
    else if (swipeWidth > 30) {
      this.currentTranslateX = this.shared.isRtl() ? 80 : -80;
      this.showIcon = true;
    }
    else {
      this.toggleSwipe.setActiveComponent(null);
      this.currentTranslateX = 0;
    }
  }

}
