import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnInit, Output, EventEmitter, Renderer2, HostListener, OnDestroy, ElementRef } from '@angular/core';
import { ScrollService } from '../../../_services/scroll-service.service'
@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit, OnDestroy {

  elementScrolled: boolean;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scrollService: ScrollService,
    private elementRef: ElementRef
  ) { }
  @Output() scrollClick = new EventEmitter();
  @Input() elemnt: HTMLElement;
  moveButtonToTop: boolean = false;
  activeScroll: boolean = false;
  hasScrollBar: boolean = false;
  timeoutId = null;
  previousScrollPosition = 0;

  private findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
    if (!element) return null;
    if (element.classList.contains(className)) {
      return element;
    }
    return this.findParentWithClass(element.parentElement, className);
  }

  ngOnInit() {
    this.elemnt.addEventListener("scroll", this.scrollME, true);
    this.elemnt.addEventListener("scrollend", this.scrollEnd, true);
    setTimeout(() => {
      let containsAppFabButtonContainer = false;
      const parent = this.findParentWithClass(this.elemnt, 'sub-menu-content');
      const children = parent?.childNodes;
      
      for (let i = 0; i < children?.length ?? 0; i++) {
        if (children[i].nodeName === 'APP-FAB-BUTTON-CONTAINER') {
          containsAppFabButtonContainer = true;
          break;
        }
      }
      if (containsAppFabButtonContainer) {
        var spacer = this.elemnt.querySelector('.fab-extra-scroll-space');
        spacer?.classList.remove("hide");
        const multiCards = this.elemnt.querySelector('.multi-card-content');
        if (multiCards) {
          const clone = spacer.cloneNode(true);
          multiCards.appendChild(clone);
          spacer.parentNode.removeChild(spacer);
        }
      }
    }, 2000);
  }

  ngOnDestroy(): void {
    if (this.elemnt != null)
      this.elemnt.removeEventListener("scroll", this.scrollME, true);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  scrollEnd = (event): void => {
    this.scrollService.updateScrollStopped(true);
    this.activeScroll = false;
  }

  setTimeOut() {
    this.timeoutId = setTimeout(() => {
      this.activeScroll = false;
    }, 2000);
  }

  clearTimeOut() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  scrollME = (event): void => {
    this.scrollService.updateScrollPosition(event.target.scrollTop);
    const scrollTop = this.elemnt.scrollTop;
    if (scrollTop > this.previousScrollPosition) {
      this.scrollService.updateScrollDown(true);
    }
    this.previousScrollPosition = scrollTop;
    if (event.target.scrollTop !== event.target.offsetHeight && event.target.scrollTop > 0) {
      this.activeScroll = true;
      this.clearTimeOut();
      this.setTimeOut();
      this.hasScrollBar = true;
      /*if (event.target.scrollHeight - event.target.offsetHeight- event.target.scrollTop < 300){
        this.moveButtonToTop = true;
      }
      else this.moveButtonToTop = false;*/
    }
    else {
      this.activeScroll = false;
      this.hasScrollBar = false;
    }
  };

  scrollToTop(): void {
    this.elemnt.scrollTop = 0;
    this.scrollClick.emit();
  }
}

