import { SharedService } from './../../_services/shared.service';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { PostService } from 'src/app/_services/postService';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';
import { User } from 'src/app/_models/user';
import { StringRequest } from 'src/app/_models/sharedModels';
import { UserService } from 'src/app/_services/user-service.service';

@Component({
  selector: 'app-first-password-dialog',
  templateUrl: './first-password-dialog.component.html',
  styleUrls: ['./first-password-dialog.component.css']
})
export class FirstPasswordDialogComponent implements OnInit {

  recoveryTypes = [
    { value: 0, icon: 'password_login' },
    { value: 2, icon: 'Sms' },
    { value: 1, icon: 'code_by_mail' },
    { value: 3, icon: 'voice _msg' }
  ]
  btnText = 'Enter';
  guid = '';
  myForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    sms: new FormControl('', [Validators.required]),
    userName: new FormControl('', [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    recoveryType: new FormControl()
  });
  code = '';
  showCodeOnTheWay = false;


  textBox: UntypedFormControl = new UntypedFormControl();
  constructor(
    private dialogRef: MatDialogRef<FirstPasswordDialogComponent>,
    private postSrv: PostService,
    private user: UserService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private shared: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: { userData: User }
  ) {
    this.myForm.controls['recoveryType'].setValue(0);
  }

  ngOnInit(): void {
  }
  onCancelClick() {
    this.dialogRef.close(false);

  }
  setSelectedPassword(value) {
    this.myForm.controls['recoveryType'].setValue(value);
    if (value == 0) {
      this.btnText = 'Enter';
    } else { this.btnText = 'sendCode'; }
  }

  onConfirmClick() {
    switch (this.myForm.controls['recoveryType'].value) {
      case 0:
        this.validateRegularPassword();
        break;
      default:
        if (!this.showCodeOnTheWay) {
          this.sendCode();
        } else {
          this.enter();
        }
        break;
    }
  }
  validateRegularPassword() {
    if (this.shared.isEmpty(this.textBox.value)) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('AskForPassword'), type: 'warning' }
      });
      return;
    }

    this.postSrv.postData('api/user/ValidateNewPassword',
      true,
      {
        id: this.textBox.value
      }
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.data) {
        this.dialogRef.close(true);
      } else {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('WrongPassword'), type: 'warning' }
        });

      }
    });
  }

  getRecoveryTextSms() {
    if (this.shared.validateCellphone(this.data.userData.cellphone)) {
      return `${this.translate.instant('recoverySms')}`;
    } else {
      return '';
    }
  }

  getRecoveryEmail() {
    if (this.shared.validateEmail(this.data.userData.studentEmail)) {
      return `${this.translate.instant('recoveryEmail')}`;
    } else {
      return '';
    }
  }

  sendCode() {
    this.postSrv.postData<StringRequest[]>('api/user/SendOneTimePasswordChangeUser',
      false,
      {
        'UserName': `${this.data.userData.lastName} ${this.data.userData.firstName}`,
        'MemberId': this.data.userData.id,
        'RecoveryType': this.myForm.controls['recoveryType'].value,
        'PasswordType': 0,
        'input': this.myForm.controls['recoveryType'].value == 1 ? this.data.userData.studentEmail : this.data.userData.cellphone,
        'InstitutionCode': this.data.userData.institutionCode,
        'guid': this.data.userData.isWebTopUser ? '1' : '2'
      }).pipe(take(1)).subscribe((result: baseResult<StringRequest[]>) => {
        if (result.status) {
          if (result.data.length === 2) {
            this.guid = result.data[1].id;
            this.btnText = 'Enter';
            this.showCodeOnTheWay = true;
          }/*  else {


            const dialogRef = this.matDialog.open(MessageBoxDialogComponent, {
              disableClose: true,
              data: {
                messages: [this.translate.instant(result.data[0].id)],
                confirmBtn: this.translate.instant('OKBtn'), header: this.translate.instant('notification')
              },
              autoFocus: true
            });
          } */
        }
      });


    this.btnText = 'Enter';
    this.showCodeOnTheWay = true;
  }

  back() {
    this.btnText = 'Enter';
    this.showCodeOnTheWay = false;
    this.myForm.controls['recoveryType'].setValue(0);
  }

  sendCodeAgain() {
    this.sendCode();
  }

  onCodeChanged(code: string) {
    this.code = code;
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.code = code;
    this.enter();
  }

  enter() {
    if (this.code.length !== 6) { return; }
    const enterCode: number = parseFloat(this.code);
    this.user.getSavedUser().subscribe(result => {
      this.postSrv.postData<boolean>('api/user/ChangeUserByOneTimePassword',
        false,
        {
          'Code': enterCode,
          'Guid': this.guid,
          'savedUser': result?.value

        }).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
          if (result.data) {
            this.dialogRef.close(true);
          } else {
            this.snackBar.openFromComponent(SnackBarComponent, {
              duration: 3000,
              data: { message: this.translate.instant('WrongPassword'), type: 'warning' }
            });

          }
        });
    });

  }

}
