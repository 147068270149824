
export class User {
  firstName: string;
  lastName: string;
  isTeacher: number;
  studentLoginId: number;
  studentId: number;
  schoolId: number;
  schoolName: string;
  studentEmail: string;
  studentGender: boolean;
  firstInstitutionCode: number;
  institutionCode: number;
  isReseted: boolean;
  lastResetDate: Date;
  lastPasswordChangeDate: Date;
  lastLoginDate: Date;
  classCode: string;
  classNumber: number;
  isSchoolyAdministrator: boolean;
  token: string;
  initialUserType: number;
  userType: number | string;
  userId: string;
  id: string;
  cellphone:string;
  mustChangePassword: any;
  isWebTopUser: boolean;
  userImageToken: string;
}

export class TinyUser {
  isReseted: boolean;
  constructor(user: User ) {
    this.firstName = user.firstName ;
    this.lastName = user.lastName ;
    this.isTeacher = user.isTeacher ;
    this.schoolId = user.schoolId ;
    this.schoolName = user.schoolName ;
    this.studentEmail = user.studentEmail ;
    this.studentGender = user.studentGender ;
    this.institutionCode = user.institutionCode ;
    this.lastLoginDate = user.lastLoginDate ;
    this.classCode = user.classCode ;
    this.classNumber = user.classNumber ;
    this.token = user.token ;
    this.userType = user.userType ;
    this.userId = user.userId ;
    this.id = user.id ;
    this.isWebTopUser = user.isWebTopUser ;
    this.initialUserType = user.initialUserType;
    this.isSchoolyAdministrator = user.isSchoolyAdministrator;
    this.userImageToken = user.userImageToken;
    this.isReseted = user.isReseted
  }
  firstName: string;
  lastName: string;
  isTeacher: number;
  schoolId: number;
  schoolName: string;
  studentEmail: string;
  studentGender: boolean;
  institutionCode: number;
  lastLoginDate: Date;
  classCode: string;
  classNumber: number;
  initialUserType: number;
  token: string;
  userType: number | string;
  userId: string;
  id: string;
  isWebTopUser: boolean;
  isSchoolyAdministrator: boolean;
  userImageToken: string;

}

export class MultiUsers {
  studentId: string;
  isTeacher: number;
  institutionCode: number;
  isSchoolyAdministrator: number;
  isReseted: boolean;
  school_name: string;
}
export interface Teacher {
  family_name: string;
  private_name: string;
}

export interface StudentData {
  token: string;
  studentTz: string;
  class_code: number;
  class_num: number;
  classTitle: string;
  family_name: string;
  private_name: string;
  institutionCode: number;
  teachers: Teacher[];
}
