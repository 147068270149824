import { ScrollToTopComponent } from '../common/UIControls/scroll-to-top/scroll-to-top.component';
import { MultiCardsViewComponent } from '../common/pageStructure/multi-cards-view/multi-cards-view.component';

import { WarningBadgeComponent } from '../common/badges/warning-badge/warning-badge.component';
import { SuccessBadgeComponent } from '../common/badges/success-badge/success-badge.component';
import { CommentBadgeComponent } from '../common/badges/comment-badge/comment-badge.component';
import { PipesModule } from './pipes.module';
import { UIModule } from './UI.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BreadcrumbComponent } from '../common/pageStructure/breadcrumb/breadcrumb.component';
import { ContentCardComponent } from '../common/pageStructure/content-card/content-card.component';
import { EditFooterComponent } from '../common/pageStructure/edit-footer/edit-footer.component';
import { FooterComponent } from '../common/pageStructure/footer/footer.component';
import { HeaderCardComponent } from '../common/pageStructure/header-card/header-card.component';
import { ToolBarComponent } from '../common/pageStructure/tool-bar/tool-bar.component';
import { MaterialModule } from './material-module';
import { InfoBadgeComponent } from '../common/badges/info-badge/info-badge.component';
import { NoDataComponent } from '../common/no-data/no-data.component';
import { RouterModule } from '@angular/router';
import { EditorModule } from '@tinymce/tinymce-angular';




@NgModule({
  declarations: [
    InfoBadgeComponent,
    CommentBadgeComponent,
    SuccessBadgeComponent,
    WarningBadgeComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    EditorModule,
    FormsModule,
    TranslateModule,
    PipesModule,
    RouterModule
  ],
  exports: [
    InfoBadgeComponent,
    CommentBadgeComponent,
    SuccessBadgeComponent,
    WarningBadgeComponent,
  ]
  })
  export class BadgesModule { }
