// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-menu {
  width: 300px;
}

.header-menu > div:hover {
  background-color: var(--hover-gray);
  cursor: pointer;
}

.notification-item {
  padding: 8px 12px;
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--small);
}

.notification-item > * {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}

.notification-item > span {
  font-size: var(--font-size--default);
}

.notification-item app-info-badge {
  margin-right: -8px;
}

.module-link {
  padding: 16px 12px;
  font-size: var(--font-size--default);
}`, "",{"version":3,"sources":["webpack://./src/app/app-nav/unread-notifications-preview/unread-notifications-preview.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,mCAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,+BAAA;EACA,mBAAA;EACA,kCAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,uBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,oCAAA;AACF","sourcesContent":[".header-menu{\r\n  width: 300px;\r\n}\r\n\r\n.header-menu > div:hover{\r\n  background-color: var(--hover-gray);\r\n  cursor: pointer;\r\n}\r\n\r\n.notification-item{\r\n  padding: 8px 12px;\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--small);\r\n}\r\n\r\n.notification-item  > *{\r\n  display: block;\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  display: block;\r\n  text-overflow: ellipsis;\r\n}\r\n\r\n.notification-item  > span{\r\n  font-size:var(--font-size--default);\r\n}\r\n\r\n.notification-item app-info-badge{\r\n  margin-right: -8px;\r\n}\r\n\r\n.module-link{\r\n  padding: 16px 12px;\r\n  font-size: var(--font-size--default);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
