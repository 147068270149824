// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swipe-wrapper {
  position: relative;
  max-height: 500px;
  transition: max-height 0.3s ease-out;
}

.swipe-wrapper.deleted{
  max-height: 0;
}

.swipe-element{
  z-index: 1;
  position: inherit;
  transform: translateX(0); 
  transition: transform 0.2s linear;
  background: var(--table-background-color);
}

.swipe-element.swiping, .show-icon > .swipe-element{
  border-radius: 4px 0 0 4px
}

.actions {
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--button-danger-background-color);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
}

.deleted.swipe-wrapper > .actions >  .swipe-delete-btn {
  transform: translateX(360px);
  opacity: 1;
  animation: fadeOut 2s forwards;
} 

.swipe-wrapper > .actions >  .swipe-delete-btn{
  width: 80px;
  height: 100%;
  transform: translateX(0); 
  transition: transform 0.2s linear;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/swipe-item/swipe-item.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,wBAAwB;EACxB,iCAAiC;EACjC,yCAAyC;AAC3C;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,YAAY;EACZ,uDAAuD;EACvD,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,UAAU;EACV,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,wBAAwB;EACxB,iCAAiC;AACnC;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".swipe-wrapper {\r\n  position: relative;\r\n  max-height: 500px;\r\n  transition: max-height 0.3s ease-out;\r\n}\r\n\r\n.swipe-wrapper.deleted{\r\n  max-height: 0;\r\n}\r\n\r\n.swipe-element{\r\n  z-index: 1;\r\n  position: inherit;\r\n  transform: translateX(0); \r\n  transition: transform 0.2s linear;\r\n  background: var(--table-background-color);\r\n}\r\n\r\n.swipe-element.swiping, .show-icon > .swipe-element{\r\n  border-radius: 4px 0 0 4px\r\n}\r\n\r\n.actions {\r\n  position: absolute;\r\n  top: 0;\r\n  height: 100%;\r\n  background-color: var(--button-danger-background-color);\r\n  display: flex;\r\n  align-items: center;\r\n  width: 100%;\r\n  justify-content: end;\r\n}\r\n\r\n.deleted.swipe-wrapper > .actions >  .swipe-delete-btn {\r\n  transform: translateX(360px);\r\n  opacity: 1;\r\n  animation: fadeOut 2s forwards;\r\n} \r\n\r\n.swipe-wrapper > .actions >  .swipe-delete-btn{\r\n  width: 80px;\r\n  height: 100%;\r\n  transform: translateX(0); \r\n  transition: transform 0.2s linear;\r\n}\r\n\r\n@keyframes fadeOut {\r\n  from {\r\n    opacity: 1;\r\n  }\r\n  to {\r\n    opacity: 0;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
