// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field {
  padding: 0;
  width: 100%;
  font-family: var(--regularFont);
  font-weight: normal;
}

  .form-field .mat-form-field-wrapper {
    margin-bottom: -1.34375em;
  }

.mat-form-field-label {
  font-size: var(--font-size--default);
  transform: scale(0.75)
}

.mat-input-element {
  font-size: var(--font-size--large);
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline{
  color: var(--input-outline-indicator-color);
  direction: rtl;
}

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap{
  flex-grow: 0.04;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick,
::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,
::ng-deep .mat-form-field.mat-focused .mat-form-field-label{
  color: var(--input-focus-indicator-color);
}

textarea{
    resize: none;
    height: 100px;
}
.spaceBetween{
  place-content: center space-between;
}
.extraData
{
  padding-top: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/textarea/textarea.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,+BAA+B;EAC/B,mBAAmB;AACrB;;EAEE;IACE,yBAAyB;EAC3B;;AAEF;EACE,oCAAoC;EACpC;AACF;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,2CAA2C;EAC3C,cAAc;AAChB;;AAEA;EACE,eAAe;AACjB;;AAEA;;;EAGE,yCAAyC;AAC3C;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;AACA;EACE,mCAAmC;AACrC;AACA;;EAEE,gBAAgB;AAClB","sourcesContent":[".form-field {\r\n  padding: 0;\r\n  width: 100%;\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n}\r\n\r\n  .form-field .mat-form-field-wrapper {\r\n    margin-bottom: -1.34375em;\r\n  }\r\n\r\n.mat-form-field-label {\r\n  font-size: var(--font-size--default);\r\n  transform: scale(0.75)\r\n}\r\n\r\n.mat-input-element {\r\n  font-size: var(--font-size--large);\r\n}\r\n\r\n::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline{\r\n  color: var(--input-outline-indicator-color);\r\n  direction: rtl;\r\n}\r\n\r\n::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap{\r\n  flex-grow: 0.04;\r\n}\r\n\r\n::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick,\r\n::ng-deep .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick,\r\n::ng-deep .mat-form-field.mat-focused .mat-form-field-label{\r\n  color: var(--input-focus-indicator-color);\r\n}\r\n\r\ntextarea{\r\n    resize: none;\r\n    height: 100px;\r\n}\r\n.spaceBetween{\r\n  place-content: center space-between;\r\n}\r\n.extraData\r\n{\r\n  padding-top: 8px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
