import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { PostService } from 'src/app/_services/postService';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'app-qr-dialog',
  templateUrl: './qr-dialog.component.html',
  styleUrls: ['./qr-dialog.component.css']
})
export class QrDialogComponent implements OnInit {
  qrStr: string;
  name: string;
  constructor(
    private dialogRef: MatDialogRef<QrDialogComponent>,
    private postSrv: PostService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { qrStr: string , name: string},
  ) {
    this.qrStr = data.qrStr;
    this.name = data.name;

   }

  ngOnInit(): void {
  }

  onCancelClick() {
    this.dialogRef.close(false);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  SendQrToMail() {
    this.postSrv.postData<boolean>('api/qrCode/SendQrToMail', true, {
    'Param1' : this.qrStr,
    'Param2' : this.name
    }).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.data === true) {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('SendQrToMailSuccess'), type: 'warning' }
        });
      } else {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('SendQrToMailFailed'), type: 'warning' }
        });
      }

    });
  }

}
