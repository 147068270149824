
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-alert-warning',
  templateUrl: './alert-warning.component.html',
  styleUrls: ['./alert-warning.component.css']
})
export class AlertWarningComponent implements OnInit, AfterViewInit {
  @Input() cssClass = '';
  @Input() isOuter: boolean = false;

  hasOverFlow: boolean;
  showMore: boolean = false;
  @ViewChild('content', { read: ElementRef, static: false }) content: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.checkContent();
  }

  checkContent() {
    if (this.content == undefined) {
      return;
    }
    if (this.content.nativeElement.offsetHeight < this.content.nativeElement.scrollHeight ||
      this.content.nativeElement.offsetWidth < this.content.nativeElement.scrollWidth) {
      this.hasOverFlow = true;
      //this.showMore = true;
    } else {
      this.showMore = false;
      this.hasOverFlow = false;
    }
  }

  ngOnInit(): void {
  }

}

