import { Colors } from './../../../_consts/colors';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-data',
  templateUrl: './color-data.component.html',
  styleUrls: ['./color-data.component.scss']
})
export class ColorDataComponent implements OnInit {

  constructor() {}

  private _selectedColor: string = Colors.colors[0].value;
  selectedColorName: string = 'NoName';

  @Input() set selectedColor(value: string) {
    this._selectedColor = value.toLowerCase();
    this.selectedColorName = Colors.colors.find(x => x.value === this._selectedColor)?.name;
    if (this.selectedColorName == null)
      this.selectedColorName = 'NoName';
  }

  get selectedColor(): string {
    return this._selectedColor;
  }

  ngOnInit() {
  }

}
