import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.css']
})
export class SwitchComponent implements OnInit {

  constructor() { }

  @Input() checked: boolean = false;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() disabled: boolean = false;
  @Input() text: string;
  @Input() title: string;

  @ViewChild(MatSlideToggle) sllide:MatSlideToggle;

  @Output() onValueChange = new EventEmitter<boolean>();

  valueChanged(val: boolean){
    this.control.setValue(val)
    this.onValueChange.emit(val);
  }
  setValue(val:boolean){
    this.control.setValue(val);
    this.checked = val;
    this.sllide.toggle();

  }

  ngOnInit() {
  }

}
