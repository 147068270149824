export class ChipItem{
  firstName: string;
  lastName: string;
  extraText?: string;
  value: any;
  showAvatar: boolean;
  type: string;
  userImageToken: string;
  avatarId?: string;

  constructor( lastName: string, firstName: string,  value: number | string, extraText: string, type: string, imageToken: string = '', avatarId: string = null) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.value = value;
    this.extraText = extraText;
    this.type = type;
    this.userImageToken = imageToken;
    this.avatarId = avatarId;
  }
}
