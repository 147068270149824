import { MobileService } from 'src/app/_services/mobile-service.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs-compat';
import { MenuItem } from '../_models/MenuItem';


@Injectable({
  providedIn: 'root'
})
export class HeaderCountersService {

constructor(
  private mobileService:MobileService
) { }

  private unreadMessages = new BehaviorSubject<number>(0);
  private unreadNotifications = new BehaviorSubject<number>(0);
  private menuItems = new BehaviorSubject<MenuItem[]>([]);
  private hasWebtop = new Subject<boolean>();

  updateMenuItems(data: MenuItem[]) {
    this.menuItems.next(data);
  }

  getMenuItems() {
    return this.menuItems.asObservable();
  }

  updateHasWebtop(data: boolean) {
    this.hasWebtop.next(data);
  }

  getHasWebtop() {
    return this.hasWebtop.asObservable();
  }

  updateUnreadMessages(data: number) {
    this.unreadMessages.next(data);
  }

  changeUnreadMessages( numOfMessages: number) {
    const tmp = this.unreadMessages.value;
    this.unreadMessages.next( tmp + numOfMessages);
  }

  updateUnreadNotifications(data: number) {
    this.unreadNotifications.next(data);
  }

  changeUnreadNotifications( numOfNotification: number) {
    const tmp = this.unreadNotifications.value;
    this.unreadNotifications.next( tmp + numOfNotification);
  }

  getUnreadMessages() {
    return this.unreadMessages.asObservable();
  }

  getUnreadNotificatoins() {
    return this.unreadNotifications.asObservable();
  }
}
