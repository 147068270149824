// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .group-header .mat-checkbox {
  font-family: var(--boldFont);
  font-weight: bold;
}

.inner-cb {
  padding: 0 24px 0 0;
}

.fixPostion {
  position: relative;
  top: -2px;
  color: black;
}

.checkBoxText {
  top: -20px;
  position: relative;
}

.fixMargin {
  margin-top: -3px;
}

.noWrap {
  white-space: nowrap !important;
}

.margin-bottom {
  margin-bottom: 16px;
  display: block;
}

/* ::ng-deep .mat-checkbox-disabled .mat-checkbox-label {
  color: var(--black);
  cursor: not-allowed;
} */

::ng-deep .mat-checkbox-disabled>.mat-checkbox-layout {
  cursor: not-allowed;
  opacity: 0.38;
}
.vertical {
  padding-left: 16px;
}


.mat-checkbox-disabled>.mat-checkbox-layout {
  cursor: not-allowed;
  opacity: var(--checkbox-disabled-opacity);
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: var(--checkbox-disabled-label-color);
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/check-box-group/check-box-group.component.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB;;AAEA;;;GAGG;;AAEH;EACE,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,kBAAkB;AACpB;;;AAGA;EACE,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,2CAA2C;EAC3C,mBAAmB;AACrB","sourcesContent":["::ng-deep .group-header .mat-checkbox {\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n}\r\n\r\n.inner-cb {\r\n  padding: 0 24px 0 0;\r\n}\r\n\r\n.fixPostion {\r\n  position: relative;\r\n  top: -2px;\r\n  color: black;\r\n}\r\n\r\n.checkBoxText {\r\n  top: -20px;\r\n  position: relative;\r\n}\r\n\r\n.fixMargin {\r\n  margin-top: -3px;\r\n}\r\n\r\n.noWrap {\r\n  white-space: nowrap !important;\r\n}\r\n\r\n.margin-bottom {\r\n  margin-bottom: 16px;\r\n  display: block;\r\n}\r\n\r\n/* ::ng-deep .mat-checkbox-disabled .mat-checkbox-label {\r\n  color: var(--black);\r\n  cursor: not-allowed;\r\n} */\r\n\r\n::ng-deep .mat-checkbox-disabled>.mat-checkbox-layout {\r\n  cursor: not-allowed;\r\n  opacity: 0.38;\r\n}\r\n.vertical {\r\n  padding-left: 16px;\r\n}\r\n\r\n\r\n.mat-checkbox-disabled>.mat-checkbox-layout {\r\n  cursor: not-allowed;\r\n  opacity: var(--checkbox-disabled-opacity);\r\n}\r\n\r\n.mat-checkbox-disabled .mat-checkbox-label {\r\n  color: var(--checkbox-disabled-label-color);\r\n  cursor: not-allowed;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
