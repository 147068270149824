import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MobileService } from '../../../_services/mobile-service.service';

@Component({
  selector: 'app-sorter-dialog',
  templateUrl: './sorter-dialog.component.html',
  styleUrls: ['./sorter-dialog.component.css']
})
export class SorterDialogComponent implements OnInit {


  @Input() title: string;
  @Input() titleColor: string;
  @Input() cssClass = '';
  @Input() closeIcon = 'close';
  @Input() transformOriginClass: string;

  @Output() closeDialog = new EventEmitter();
  @Output() saveFunction = new EventEmitter();
  @Output() resetFunction = new EventEmitter();


  constructor(
    private dialogRef: MatDialogRef<SorterDialogComponent>,
    private mobile: MobileService

  ) {
    this.dialogRef.addPanelClass('edit-modal');
    if (!this.mobile.isMobileView) {
      this.dialogRef.addPanelClass('edit-modal-short');
    }
  }

  saveClick(): void {
    this.saveFunction.emit(null);
  }
  resetClick(): void {
    this.resetFunction.emit(null);
  }

  ngOnInit(): void {
  }
  closedialogEvent() {
    if (this.closeDialog.observers.length === 0) {
      this.dialogRef.close(false);
    } else {
      this.closeDialog.emit();
    }
  }

}
