import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-badge',
  templateUrl: './success-badge.component.html',
  styleUrls: ['./success-badge.component.css']
})
export class SuccessBadgeComponent implements OnInit {

  @Input() text: string;
  @Input() cssClass: string =''
  constructor() { }

  ngOnInit() {
  }

}
