import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleSwipeItemService {
  private activeComponent = new BehaviorSubject<string | null>(null);

  get activeComponent$() {
    return this.activeComponent.asObservable();
  }

  setActiveComponent(id: string) {
    this.activeComponent.next(id);
  }
}
