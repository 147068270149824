import { SharedService } from 'src/app/_services/shared.service';
import { UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedShotefView } from 'src/app/_models/shotef/shotefPermissions';
import { SelectOptgroupOption } from './selectOptgroupOption';

@Component({
  selector: 'app-select-optgroup',
  templateUrl: './select-optgroup.component.html',
  styleUrls: ['./select-optgroup.component.scss']
})
export class SelectOptgroupComponent implements OnInit {

  @Input() set selectedItem(val: string | number){
    this.control.setValue(val);
  }
  get selectedItem(): string | number{
    return this.control.value;
  }
  @Input() public options: SelectOptgroupOption[];
  @Input() public label: string;
  @Input() public alwaysShowTitle: boolean = false;
  @Input() public showFilter = false;
  @Input() public groups: SelectOptgroupOption[] = null;

  private _disabled: boolean = false;
  @Input() set disabled(val: boolean){
    this._disabled = val;
    if (val)
      this.control.disable();
    else this.control.enable()
  }
  get disabled(): boolean{
    return this._disabled;
  }

  @Input() public control: UntypedFormControl = new UntypedFormControl();

  @Output() selectedValue: EventEmitter<SelectedShotefView> = new EventEmitter<SelectedShotefView>();

  constructor(private _shared: SharedService){
  }


  changeView(value: string, type: number, evt: any): void {
    if (evt === undefined || evt.source.selected ) {
        this.selectedValue.emit({
          type: type,
          value: value
        });
    }
  }
  getGroups($event){
      this.groups = $event;
  }

  ngOnInit(): void {
    this.control.setValue(this.selectedItem);
  }

  setGroups() {
    this.options.forEach(opt => {
      opt.options.forEach(x=>x.textForSearch = x[opt.text] + this._shared.replcaeLanguage(x[opt.text]));
    });
    this.groups = this.options.slice();

  }

}
