import { SharedService } from './../../../_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SidenavService } from '../../../_services/SidenavService';
import { TabsComponent } from '../../UIControls/tabs/tabs.component';
import { SortItem } from 'src/app/_models/sortItem';

@Component({
  selector: 'app-header-card',
  templateUrl: './header-card.component.html',
  styleUrls: ['./header-card.component.css']
})
export class HeaderCardComponent implements OnInit {

  @Input() public moduleTitle: string;
  @Input() public showMenuBtn = true;
  @Input() public showIcons: boolean;
  @Input() public showSelects = false;
  @Input() public showBreadcrumb = true;
  @Input() public showBtn: boolean;
  @Input() public isSticky = false;
  @Input() public shushiMoreOptions: SortItem[] = [];

  private _tabs: any[] = [];
  @Input() public set tabs(value: any[]){
    this.selectedTabIndex = 0;
    this._tabs = value;
  }
  public get tabs(): any[]{
    return this._tabs;
  }
  @Input() public tabsHideForMobile: string[] =[];
  @Input() public selectedTabIndex = 0 ;
  @Input() public showExtra = false;
  @Input() public extraData: string;
  @Input() public selectsClass = 'l12 s12';
  @Input() public breadCrumbs: any[];
  @Input() public titleSvg: string ="";
  @Input() public disabledTabs: number[] = [];
  @Input() public backBtnTooltip: string='';

  @Output() selectedTab = new EventEmitter<string>();
  @Output() backBtnClicked = new EventEmitter<boolean>();
  @Output() breadCrumbClick = new EventEmitter<any>();
  @Output() extraOptionClicked = new EventEmitter<SortItem>();
  
  constructor(
    private sidenav: SidenavService,
    public shared: SharedService
  ) {

  }

  @ViewChild(TabsComponent, { static: false }) tab: TabsComponent;
  goToTabIndex(index: number) {
    this.tab?.goToTabIndex(index);
  }


  onBackBtnClicked(): void {
    this.backBtnClicked.emit(true);
  }

  changeView(tab): void {
    this.selectedTab.emit(tab);
  }

  toggleSidenav(): void {
    this.sidenav.toggle();
  }

  ngOnInit(): void {

  }
  breadcrumbClickevent($event) {
    this.breadCrumbClick.emit($event);
  }


}
