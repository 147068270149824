import { SharedService } from 'src/app/_services/shared.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-inline-select',
  templateUrl: './inline-select.component.html',
  styleUrls: ['./inline-select.component.css']
})
export class InlineSelectComponent implements OnInit {

  @Input() public selected: string | number;
  @Input() public disabled: boolean;
  @Input() public options: any[];
  @Input() public value: string = 'value';
  @Input() public cssClass: string = '';
  @Input() public text: string = 'text';
  @Input() public control: UntypedFormControl = new UntypedFormControl();

  @Output() selectedValue = new EventEmitter<string | number>();

  constructor(
    private translate: TranslateService,
    public _shared: SharedService) {

  }

  onSelectChange(value: string | number): void {
    this.selectedValue.emit(value);
  }

  ngOnInit(): void {
    if (this.selected == null)
      this.selected = 0;
  }

  ngAfterContentInit(): void {
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

}
