// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectImage {
  width: 49%;
  border: 1px solid rgba(0, 0, 0, .12);
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  justify-content: space-between;
  font-family: 'boldOpenSans';
  margin: 8px;
}

.waitingToImprove {
  top: -90px;
  height: 30px;
  background-color: white;
  opacity: 0.6;
  position: relative;
  z-index: 1;
}

.lyCropper {
  top: -20px;
}

.footerButtons {
  justify-content: space-between;
}

.bodyContent {
  overflow: hidden;
}

.lyCropper {
  padding-top: 30px;
}

@media screen and (max-width: 959px) {

  .bodyContent {
    overflow: hidden;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/settings/user-image/user-image.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,oCAAoC;EACpC,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,8BAA8B;EAC9B,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;EAEE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".selectImage {\r\n  width: 49%;\r\n  border: 1px solid rgba(0, 0, 0, .12);\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n  padding: 16px;\r\n  justify-content: space-between;\r\n  font-family: 'boldOpenSans';\r\n  margin: 8px;\r\n}\r\n\r\n.waitingToImprove {\r\n  top: -90px;\r\n  height: 30px;\r\n  background-color: white;\r\n  opacity: 0.6;\r\n  position: relative;\r\n  z-index: 1;\r\n}\r\n\r\n.lyCropper {\r\n  top: -20px;\r\n}\r\n\r\n.footerButtons {\r\n  justify-content: space-between;\r\n}\r\n\r\n.bodyContent {\r\n  overflow: hidden;\r\n}\r\n\r\n.lyCropper {\r\n  padding-top: 30px;\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n\r\n  .bodyContent {\r\n    overflow: hidden;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
