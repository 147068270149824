import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Notifications } from './../../_models/settings/settings';
import { PostService } from './../../_services/postService';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { KeyValue } from '@angular/common';
import { T } from '@angular/cdk/keycodes';
import { SnackBarComponent } from 'src/app/common/snack-bar/snack-bar.component';

@Component({
  selector: 'app-notifications-settings',
  templateUrl: './notifications-settings.component.html',
  styleUrls: ['./notifications-settings.component.css']
})
export class NotificationsSettingsComponent implements OnInit {

  @Input() viewName: string;

  constructor(
    private postSrv: PostService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  data: Notifications;

  saveNotifications() {
    switch (this.viewName) {
      case "PushNotification":
        this.savePushNotifications();
        break;
      case "EmailNotification":
        this.saveEmailNotifications();
        break;
    }
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  saveEmailNotifications() {
    this.postSrv.postData('api/settings/UpdateEmailNotification',
      true,
      this.data.emailNotification
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.status) {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('SettingsSavedSuccess'), type: 'succcess' }
        });
      }
    });
  }

  savePushNotifications() {
    this.postSrv.postData('api/settings/UpdateNotifications',
      true,
      this.data.pushNotifications
    ).pipe(take(1)).subscribe((result: baseResult<boolean>) => {
      if (result.status) {
        this.snackBar.openFromComponent(SnackBarComponent, {
          duration: 3000,
          data: { message: this.translate.instant('SettingsSavedSuccess'), type: 'succcess' }
        });
      }
    });
  }

  ngOnInit() {
    this.postSrv.postData('api/settings/GetNotifications',
      true,
      {}
    ).pipe(take(1)).subscribe((result: baseResult<Notifications>) => {
      if (result.status) {
        this.data = result.data;
        if (this.data.pushNotifications != null) {

          if (this.data.userType == 0) {
            delete this.data.pushNotifications?.pushNotification_homeroomTeacherJustificationRequests;
          }
          else if (this.data.pushNotifications != null) {
              if (this.data.userType == 1) {
                  delete this.data.pushNotifications.pushNotification_discipline;
                  delete this.data.pushNotifications.pushNotification_outOfClassDiscipline;
                  delete this.data.pushNotifications.pushNotification_homework;
                  delete this.data.pushNotifications.pushNotification_grades;
                  delete this.data.pushNotifications.pushNotification_periodGrades;
                  delete this.data.pushNotifications.pushNotification_matriculationGrades;
              }
              else {
                  delete this.data.pushNotifications.pushNotification_homeroomTeacherJustificationRequests;
                  delete this.data.pushNotifications.pushNotification_newLearningSpacesPageTask;
                  delete this.data.pushNotifications.pushNotification_homeroomTeacherJustificationRequests;
              }
            }
          }
        }
    });
  }

}
