import { SliderComponent } from './../slider/slider.component';
import { StyleRenderer, Platform, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
  STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';

import { Component, Inject, OnInit, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(CROPPER_STYLES);
  return {
    cropper: lyl`{
      height: 320px
      .LyImageCropper-area-d {
        position: absolute
        top: 0 !important
        bottom: 0 !important
        left: 0 !important
        right: 0 !important
        width: 256px !important
        height: 256px !important
        margin: auto !important
        border-radius: 50% !important
        pointer-events: none
        box-shadow: 0 0 0 20000px rgb(0 0 0 / 60%)
        &::before {
          width: 0  !important
          height: 0 !important
          margin: auto !important
          border-radius: 50% !important
          background: #fff !important
          border: solid 2px rgb(255,255,255) !important
        }

        &::after {
          border: solid 2px rgb(255,255,255) !important
          border-radius: 0 !important
        }
      }
      .d {
        position: absolute
        top: 0 !important
        bottom: 0 !important
        left: 0 !important
        right: 0 !important
        width: 256px !important
        height: 256px !important
        margin: auto !important
        border-radius: 50% !important
        pointer-events: none
        box-shadow: 0 0 0 20000px rgb(0 0 0 / 60%)
        &::before {
          width: 0  !important
          height: 0 !important
          margin: auto !important
          border-radius: 50% !important
          background: #fff !important
          border: solid 2px rgb(255,255,255) !important
        }

        &::after {
          border: solid 2px rgb(255,255,255) !important
          border-radius: 0 !important
        }
      }
    }`,
    cropperResult: lyl`{
      position: relative
      width: 150px
      height: 150px
    }`,
  };
};

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.css']
})
export class CropperComponent implements OnInit, AfterViewInit {
  classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string | null = null;
  scale: number;
  ready = false;
  image: string = '';
  watingImage: string = '';
  @ViewChild(LyImageCropper) readonly cropper!: LyImageCropper;
  @ViewChild(SliderComponent) readonly slider: SliderComponent;
  @Output() imageCropped = new EventEmitter<string>();
  @Output() imageloadComplete = new EventEmitter();
  @Output() imageRotate = new EventEmitter<number>();
  @Input() data: any;
  @Input() minScale: number;
  @Input() returnId: any;

  myConfig: ImgCropperConfig = {
    // autoCrop: true,
    width: 256, // Default `250`
    height: 256, // Default `200`
    fill: '#ff2997', // Default transparent if type == png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
    responsiveArea: true,
    output: {
      width: 256,
      height: 256
    }

  };
  imagePreview = true;
  constructor(
    readonly sRenderer: StyleRenderer,

  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.cropper.loadImage(this.data);
    setTimeout(() => {
      this.cropper.fit();
      this.cropper.center();
    }, 100);
  }

  updatePosition(x, y, scale ) {
    this.cropper.updatePosition(x, y);
    this.cropper.scale = scale;
  }

  rotateImg(rotate) {
    this.cropper.rotate(rotate)
  }
  rotate(rotate) {
    this.imageRotate.emit(rotate);
  }

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
    //console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    //console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }
  onSliderInput(event) {
    this.scale = event;
  }

  getCroppedImage() {
    let cropResult = this.cropper.crop();

    return {
      img: this.croppedImage,
      id: this.returnId,
      cropData: cropResult
    };
  }

  loadComplete() {

    this.imageloadComplete.emit();
  }

  setFontSize(value) {
    this.slider.setFontSize(value);
  }


}
