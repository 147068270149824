import { DrawerComponent } from './drawer.component';
import { NgModule } from "@angular/core";
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { UIModule } from 'src/app/modules/UI.module';



@NgModule({
  declarations: [
    DrawerComponent

  ],
  imports: [
    MatIconModule,
    CommonModule,
    MatDividerModule,
    UIModule
  ],
exports: [
  DrawerComponent
] })
  export class DrawerModule { }
