import { SharedService } from './../../../_services/shared.service';
import { MobileService } from './../../../_services/mobile-service.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, NgModule, ViewChild } from '@angular/core';
import {  MatTabGroup } from '@angular/material/tabs';


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit {

  @Input() public tabs: any[] = [];
  @Input() public tabsHideForMobile: string[] = [];
  @Input() public disabledTabs: number[] = [];

  @Input() public fullWidth = false;
  @Output() activeTab = new EventEmitter<string>();
  @Output() activeTabIndex = new EventEmitter<number>();
  @Input() public selectedTabIndex =  0;

  tabss = ['First', 'Second', 'Third','Forth', 'fifth'];

  onSelectedTabChange(tab): void {
    this.selectedTabIndex = tab.index;
    this.activeTab.emit(tab.tab.textLabel);
    this.activeTabIndex.emit(tab.index);
  }
  checkifDisabled(i: number) {
    return this.disabledTabs.filter(x => x === i).length > 0;
  }

  showTab(tabName: string): boolean {
    if (!this.mobile.isMobileView ) {
      return true;
    }
    return this.tabsHideForMobile.find(x => x === tabName) == null;
  }

  constructor(
    private mobile: MobileService,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
  }

  changeTab(x) {
    if(!this.sharedService.isRtl()) {
      x = x * -1;
    }
    this.selectedTabIndex += x;
    if (this.selectedTabIndex < 0 ) {
      this.selectedTabIndex = this.tabs.length - 1;
    }
     if (this.selectedTabIndex >  this.tabs.length - 1 ) {
      this.selectedTabIndex = 0;
    }
    this.showTab(this.tabs[this.selectedTabIndex]);
  }

  @ViewChild(MatTabGroup, { static: false }) tab: MatTabGroup;
  goToTabIndex(index: number) {
    this.tab.selectedIndex = index;
  }


}
