import { LogService } from './logService';
import { Injectable, Inject, NgZone } from '@angular/core';


import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs-compat';
import { CookiesService } from './cookies-service.service';

@Injectable({
  providedIn: 'root'
})
export class BiomericService {

  constructor(
    private cookiesService: CookiesService,
    private zone: NgZone,
    private logService: LogService
  ) {

  }

  biometricFingerprintPrompt(token) {
    let deviceType = this.cookiesService.getItem('deviceType');

    if (deviceType == 'ios' || deviceType == undefined || deviceType == null || deviceType == '') {
      try {
        //@ts-ignore
        webkit.messageHandlers.MobileDevice.postMessage({ action: 'biometricFingerprintPrompt', data: token });
      } catch (err) {
        try {
          if (deviceType == 'ios') {
            this.logService.writeToLog('biometricFingerprintPrompt',JSON.stringify(err));
          }
        } catch { }
      }
    } else if (deviceType == 'android') {
      try {
        //@ts-ignore
        MobileDevice.biometricFingerprintPrompt(token);
      } catch (err) {
        try {
            this.logService.writeToLog('biometricFingerprintPrompt','android',JSON.stringify(err));
        } catch { }
      }
    }
  }

  setDeviceCallBackFunction(scope, bioReadSuccess, bioReadFailed, bioReadCanceled, noBiometricOnDevice, isBiometricDevice?, setRegistrationID?) {
    window['loginComponentReference'] = {
      zone: this.zone,
      biometricReadSuccess: (value) => bioReadSuccess(scope, value),
      bioReadFailed: (value) => bioReadFailed(scope),
      bioReadCanceled: (value) => bioReadCanceled(scope,),
      noBiometricOnDevice: (value) => noBiometricOnDevice(scope,),
      isBiometricDevice: (value) => isBiometricDevice(scope, value),
      setRegistrationID: (deviceType, token) => setRegistrationID(scope, deviceType, token),
      component: this,
    };
  }
}
