// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textBottom{
  position: relative;
    bottom: -20px;
    left: 23px;
    margin: 8px 0;
}
.textBottomShortRtl {
  position: relative;
    bottom: -20px;
    left: 20px;
    margin: 8px 0;
}

.textBottomShortLtr {
  position: relative;
    bottom: -20px;
    right: 20px;
    margin: 8px 0;
}
.spanClass{
  white-space: pre-line;
}
.noWrap{
  white-space: nowrap !important;
}
.table-header {
/*   font-size: var(--font-size--default) !important; */
  font-size: 12px ;

}
@media screen and (max-width: 959px) {
.bankSpan{
  max-width: calc(100vw - 40px);

}
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/span/span.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;IAChB,aAAa;IACb,UAAU;IACV,aAAa;AACjB;AACA;EACE,kBAAkB;IAChB,aAAa;IACb,UAAU;IACV,aAAa;AACjB;;AAEA;EACE,kBAAkB;IAChB,aAAa;IACb,WAAW;IACX,aAAa;AACjB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,8BAA8B;AAChC;AACA;AACA,uDAAuD;EACrD,gBAAgB;;AAElB;AACA;AACA;EACE,6BAA6B;;AAE/B;AACA","sourcesContent":[".textBottom{\r\n  position: relative;\r\n    bottom: -20px;\r\n    left: 23px;\r\n    margin: 8px 0;\r\n}\r\n.textBottomShortRtl {\r\n  position: relative;\r\n    bottom: -20px;\r\n    left: 20px;\r\n    margin: 8px 0;\r\n}\r\n\r\n.textBottomShortLtr {\r\n  position: relative;\r\n    bottom: -20px;\r\n    right: 20px;\r\n    margin: 8px 0;\r\n}\r\n.spanClass{\r\n  white-space: pre-line;\r\n}\r\n.noWrap{\r\n  white-space: nowrap !important;\r\n}\r\n.table-header {\r\n/*   font-size: var(--font-size--default) !important; */\r\n  font-size: 12px ;\r\n\r\n}\r\n@media screen and (max-width: 959px) {\r\n.bankSpan{\r\n  max-width: calc(100vw - 40px);\r\n\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
