export class baseResult<typeOfData> {
  data: typeOfData;
  status: boolean;
  errorDescription: string;
  token: string;
}

export class MenuFilter {
  id: number;
  showOnWeb: boolean;
  showOnMobile: boolean;
}
