// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-wrapper {
    width: 32px;
    height: 32px;
    background-color: var(--badge-info-background);
    border-radius: 4px;
    color: var(--badge-info-color);
    display: inline-flex;
    margin-left: var(--rightRtl_16);
    margin-right: var(--leftRtl_16);
}

.student-ship-grade .icon-wrapper{
    background-color: var( --badge-info-2-background);
    color: var(--badge-info-2-color);
}

.icon-wrapper .icon {
    margin: auto;
}

.indicator-wrapper {
    position: relative;
    margin-top: 8px;
}

.indicator {
    height: 20px;
    border-radius: 4px;
}

.indicator-base {
    width: 100%;
    background-color: var(--linear-progress-track-color);
}

.range-wrapper {
    justify-content: space-between;
}

.indicator-value {
    position: absolute;
    top: 0;
    background-color: var(--blue-300);
}

.student-ship-grade .indicator-value{
    background-color: #8067DC;
}`, "",{"version":3,"sources":["webpack://./src/app/my-pupils/my-pupil-data/student-control-panel/student-behavior-grade/student-behavior-grade.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,8CAA8C;IAC9C,kBAAkB;IAClB,8BAA8B;IAC9B,oBAAoB;IACpB,+BAA+B;IAC/B,+BAA+B;AACnC;;AAEA;IACI,iDAAiD;IACjD,gCAAgC;AACpC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,oDAAoD;AACxD;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".icon-wrapper {\r\n    width: 32px;\r\n    height: 32px;\r\n    background-color: var(--badge-info-background);\r\n    border-radius: 4px;\r\n    color: var(--badge-info-color);\r\n    display: inline-flex;\r\n    margin-left: var(--rightRtl_16);\r\n    margin-right: var(--leftRtl_16);\r\n}\r\n\r\n.student-ship-grade .icon-wrapper{\r\n    background-color: var( --badge-info-2-background);\r\n    color: var(--badge-info-2-color);\r\n}\r\n\r\n.icon-wrapper .icon {\r\n    margin: auto;\r\n}\r\n\r\n.indicator-wrapper {\r\n    position: relative;\r\n    margin-top: 8px;\r\n}\r\n\r\n.indicator {\r\n    height: 20px;\r\n    border-radius: 4px;\r\n}\r\n\r\n.indicator-base {\r\n    width: 100%;\r\n    background-color: var(--linear-progress-track-color);\r\n}\r\n\r\n.range-wrapper {\r\n    justify-content: space-between;\r\n}\r\n\r\n.indicator-value {\r\n    position: absolute;\r\n    top: 0;\r\n    background-color: var(--blue-300);\r\n}\r\n\r\n.student-ship-grade .indicator-value{\r\n    background-color: #8067DC;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
