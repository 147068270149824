import { Pipe, PipeTransform } from '@angular/core';
import { HDate } from '@hebcal/core';

@Pipe({
  name: 'HebreMonthYearPipe'
})
export class HebreMonthYearPipe implements PipeTransform {

  transform(month: number, year: number): string {
    try {
      if (month === undefined || month === null || year === undefined || year === null) {
        return '';
      }
      const startDate = new HDate(new Date(year, month, 1));
      const endDate = new HDate(new Date(year, month + 1, 0));
      const startHebDate =  startDate.renderGematriya().split(" ");
      const endHebDate =  endDate.renderGematriya().split(" ");
      let result = '';
      if (startHebDate[1] != endHebDate[1])
        result=startHebDate[1]+ " - " + endHebDate[1];
      else result=startHebDate[1];
      return result+", " + startHebDate[2];
    } catch (error) {
      console.log(error);
      return '';
    }
  }

}
