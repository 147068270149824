import { NumToMonthPipe } from './../_pipes/numToMonth.pipe';
import { AveragePipe } from './../_pipes/averagePipe';
import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from '../common/pageStructure/breadcrumb/breadcrumb.component';
import { formattedDate } from '../_pipes/formattedDate';
import { HebreMonthYearPipe } from '../_pipes/HebreMonthYearPipe.pipe';
import { HebrewDatePipe } from '../_pipes/hebrewDate.pipe';
import { HebrewYearPipe } from '../_pipes/hebrewYear.pipe';
import { NumberToDayNamePipe } from '../_pipes/number-to-day-name.pipe';
import { SafeHtmlPipe } from '../_pipes/safeHtml';
import { ShortDatePipe } from '../_pipes/shortDate.pipe';



@NgModule({
  declarations: [

    HebrewYearPipe ,
    SafeHtmlPipe ,
    NumberToDayNamePipe ,
    NumToMonthPipe,
    AveragePipe,
    ShortDatePipe,
    HebrewDatePipe,
    HebreMonthYearPipe,
    formattedDate
  ],
  imports: [

    TranslateModule
  ],
  exports: [
    HebrewYearPipe ,
    SafeHtmlPipe ,
    NumberToDayNamePipe ,
    NumToMonthPipe,
    AveragePipe,
    ShortDatePipe,
    HebrewDatePipe,
    HebreMonthYearPipe,
    formattedDate
  ],
  providers: [
    DatePipe,
    HebrewYearPipe ,
    SafeHtmlPipe ,
    NumberToDayNamePipe ,
    NumToMonthPipe,
    ShortDatePipe,
    HebrewDatePipe,
    HebreMonthYearPipe,
    formattedDate
  ]
  })
  export class PipesModule { }
