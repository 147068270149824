import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.css','../radio-buttons-group/radio-buttons-group.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() name: string;
  @Input() value: any;
  @Input() checked: boolean;
  @Input() text = '';
  @Input() public disabled = false;
  @Input() public cssClass = '';

  @Output()  ckbClick = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  handleClick(ckb) {
    this.ckbClick.emit(ckb);
  }

}
