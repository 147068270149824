//import { MatDialogRef } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accessibility-statement',
  templateUrl: './accessibility-statement.component.html',
  styleUrls: ['./accessibility-statement.component.css']
})
export class AccessibilityStatementComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<AccessibilityStatementComponent>,
  ) { }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }
  counter(i: number) {
    return new Array(i);
  }
  ngOnInit(): void {
  }

}
