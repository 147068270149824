import { MobileService } from './../../../_services/mobile-service.service';
import { MatButton } from '@angular/material/button';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-btn-icon',
  templateUrl: './btn-icon.component.html',
  styleUrls: ['./btn-icon.component.css']
})
export class BtnIconComponent implements OnInit {

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    console.log(event);
    event.stopPropagation();
  }

  @Input() icon: string;
  @Input() cssClass = '';
  @Input() btnTabIndex = 0;
  @Input() btnClass = '';
  @Input() toolTip = '';
  @Input() inputId = '';
  @Input() menuTriggerFor = null;
  @Input() disabled: boolean = false;
  @Input() showFilterActive: boolean = false;
  @Input() stopPropagation: boolean = false;
  @Input() ariaExpanded: boolean = false;
  @Input() ariaLabel: string = '';
  @Output() submit = new EventEmitter();
  @ViewChild('iconElement') iconElement: MatButton;

  constructor(
    private mobileService: MobileService
  ) { }

  setFocus() {
    if (!this.mobileService.isMobileView) {
      setTimeout(() => {
        this.iconElement.focus();
      }, 100);
    }
  }
  ngOnInit(): void {
  }

  click($event) {
    if (this.stopPropagation) {
      $event.stopPropagation();
      $event.preventDefault();
    }
    this.submit.emit();
  }

}
