export class Colors{
  public static colors: any[] = [{
    value: '3c4db7',
    name: 'Blue'
    }, {
        value: '6733b9',
        name: 'DarkPurple'
    }, {
        value: '9c1ab2',
        name: 'LightPurple'
    }, {
        value: 'eb1461',
        name: 'Pink'
    }, {
        value: 'f6402b',
        name: 'Red'
    }, {
        value: '1193f5',
        name: 'MiddleBlue'
    }, {
        value: '01a6f6',
        name: 'LightBlue'
    }, {
        value: '00bbd6',
        name: 'Turquoise1'
    }, {
        value: '009788',
        name: 'Turquoise2'
    }, {
        value: '46af4a',
        name: 'Green1'
    }, {
        value: 'ff9801',
        name: 'Orange1'
    }, {
        value: 'ffc100',
        name: 'Yellow1'
    }, {
        value: 'ffec16',
        name: 'Yellow2'
    }, {
        value: 'ccdd1d',
        name: 'Green2'
    }, {
        value: '88c440',
        name: 'Green3'
    }, {
        value: 'ff5505',
        name: 'Orange2'
    }, {
        value: '7b5548',
        name: 'Brown'
    }, {
        value: '9d9d9d',
        name: 'LightGray'
    }, {
        value: '5f7c8c',
        name: 'DarkGray'
    }, {
        value: '000000',
        name: 'Black'
    }];
}
