import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor( private translate: TranslateService) {
  }

  transform(date: Date | string): string {
    const format = this.translate.instant('shortDateFormat');
    date = new Date(date);
    return new DatePipe('en-US').transform(date, format);
  }

}
