import { Component, Inject, OnInit } from '@angular/core';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { teacheresView } from 'src/app/_models/messageBox/Recipients';

@Component({
  selector: 'app-student-teachers-details',
  templateUrl: './student-teachers-details.component.html',
  styleUrls: ['./student-teachers-details.component.css']
})
export class StudentTeachersDetailsComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

}
