// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-field {
  padding: 0;
  width: 100%;
  font-family: var(--regularFont);
  font-weight: normal;
}

.form-field .mat-form-field-wrapper {
  margin-bottom: -1.34375em;
}

.mat-form-field-label {
  font-size: var(--font-size--default);
  transform: scale(0.84);
}

.mat-input-element {
  font-size: var(--font-size--large);
}

::ng-deep .mat-form-field-subscript-wrapper {
  padding: 0 !important;
  font-size: 100%;
  direction: ltr;
}

::ng-deep .form-field.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--primary-blue);
}

::ng-deep .mat-form-field-hint-wrapper {
  font-size: 100%;
}

.mat-hint {
  color: var(--input-hint-color);
  font-size: var(--font-size--default);
  direction: ltr;
}

textarea {
  height: 200px;
  resize: none;
}

::ng-deep app-textare-wordcounter .mat-form-field-subscript-wrapper {
  top: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/textare-wordcounter/textare-wordcounter.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,WAAA;EACA,+BAAA;EACA,mBAAA;AACF;;AAEE;EACE,yBAAA;AACJ;;AAEA;EACE,oCAAA;EACA,sBAAA;AACF;;AAEA;EACE,kCAAA;AACF;;AAEA;EACE,qBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,8BAAA;EACA,oCAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACE,YAAA;AACJ;;AAEA;EACE,SAAA;AACF","sourcesContent":[".form-field {\r\n  padding: 0;\r\n  width: 100%;\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n}\r\n\r\n  .form-field .mat-form-field-wrapper {\r\n    margin-bottom: -1.34375em;\r\n  }\r\n\r\n.mat-form-field-label {\r\n  font-size: var(--font-size--default);\r\n  transform: scale(0.84)\r\n}\r\n\r\n.mat-input-element {\r\n  font-size: var(--font-size--large);\r\n}\r\n\r\n::ng-deep .mat-form-field-subscript-wrapper{\r\n  padding: 0 !important;\r\n  font-size: 100%;\r\n  direction: ltr;\r\n}\r\n\r\n::ng-deep .form-field.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{\r\n  color: var(--primary-blue);\r\n}\r\n\r\n::ng-deep .mat-form-field-hint-wrapper{\r\n  font-size: 100%;\r\n}\r\n\r\n.mat-hint{\r\n  color: var(--input-hint-color);\r\n  font-size: var(--font-size--default);\r\n  direction: ltr;\r\n}\r\n\r\ntextarea{\r\n  height: 200px;\r\n    resize: none;\r\n}\r\n\r\n::ng-deep app-textare-wordcounter .mat-form-field-subscript-wrapper{\r\n  top: auto;\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  \r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
