import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.css']
})
export class ToggleButtonComponent implements OnInit {


  @Input() items = [];
  @Input() value: any;
  @Input() disabled: boolean = false;
  @Input() isButtonCheck: boolean = false;
  @Output() changeEvent = new EventEmitter<Event>();

  constructor() {
  }

  onChange(e): void {
    this.changeEvent.emit(e);
  }

  ngOnInit() {
  }

}
