// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  margin-bottom: 16px;
  position: relative;
  padding: 8px;
  border: 1px solid transparent;
  font-family: var(--boldFont);
  font-weight: bold;
}

.alert-warning {
  color: var(--alert-warning-color);
  background-color: var(--alert-warning-background);
  border-color: var(--alert-warning-border);
  border-radius: 4px;
}

.alert-content {
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  transition: -webkit-line-clamp .2s ease !important;
}

::ng-deep .alert-content > *:not(div){
  display: inline;
}

::ng-deep .alert-warning a{
  color: var(--alert-warning-color);
  text-decoration: underline !important;
}

.alert-content.show-more{
  -webkit-line-clamp: unset;
}

@media screen and (max-width: 959px){
  .alert.mobile-margin{
    margin: 8px 16px 16px 16px;
  }
}


.hide {
  visibility: hidden;
}

.showMorePos {
  position: absolute;
  top: 28px;
  left: var(--rightRtl_10Null);
  right: var(--leftRtl_10Null);
  z-index: 20;
  background-color: var(--alert-warning-background);
  color: var(--alert-warning-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/alerts/alert-warning/alert-warning.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,iCAAiC;EACjC,iDAAiD;EACjD,yCAAyC;EACzC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;EACrB,gBAAgB;EAChB,4BAA4B;EAC5B,kDAAkD;AACpD;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,qCAAqC;AACvC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;;;AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,4BAA4B;EAC5B,4BAA4B;EAC5B,WAAW;EACX,iDAAiD;EACjD,iCAAiC;AACnC","sourcesContent":[".alert {\r\n  margin-bottom: 16px;\r\n  position: relative;\r\n  padding: 8px;\r\n  border: 1px solid transparent;\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n}\r\n\r\n.alert-warning {\r\n  color: var(--alert-warning-color);\r\n  background-color: var(--alert-warning-background);\r\n  border-color: var(--alert-warning-border);\r\n  border-radius: 4px;\r\n}\r\n\r\n.alert-content {\r\n  position: relative;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 2;\r\n  overflow: hidden;\r\n  -webkit-box-orient: vertical;\r\n  transition: -webkit-line-clamp .2s ease !important;\r\n}\r\n\r\n::ng-deep .alert-content > *:not(div){\r\n  display: inline;\r\n}\r\n\r\n::ng-deep .alert-warning a{\r\n  color: var(--alert-warning-color);\r\n  text-decoration: underline !important;\r\n}\r\n\r\n.alert-content.show-more{\r\n  -webkit-line-clamp: unset;\r\n}\r\n\r\n@media screen and (max-width: 959px){\r\n  .alert.mobile-margin{\r\n    margin: 8px 16px 16px 16px;\r\n  }\r\n}\r\n\r\n\r\n.hide {\r\n  visibility: hidden;\r\n}\r\n\r\n.showMorePos {\r\n  position: absolute;\r\n  top: 28px;\r\n  left: var(--rightRtl_10Null);\r\n  right: var(--leftRtl_10Null);\r\n  z-index: 20;\r\n  background-color: var(--alert-warning-background);\r\n  color: var(--alert-warning-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
