import { Injectable } from '@angular/core';

/* import * as CryptoJS from 'crypto-js'; */
import { AES, enc , lib , pad , mode , PBKDF2} from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class RsaService {

  private privateKey: string;
  private publicKey: string;
  private enabled: boolean;
  private smartKey = '01234567890000000150778345678901';
  constructor() {
    this.privateKey = 'fsdfsdfs526g89v69765937fc';
    this.publicKey = 'v535676593256345d34758';
    this.enabled = true;
  }

  isEnabled(): boolean {
    return this.enabled;
  }
  encrypt(data) {

    try {
      return AES.encrypt(JSON.stringify(data), this.privateKey).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decrypt(data) {
    if (data === null) {
      return data;
    }
    try {
      const bytes = AES.decrypt(data, this.privateKey);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  encryptStringToServer(data) {                              // Base64 encoded ciphertext, 32 bytes string as key
    const keySize = 256;
  const salt = lib.WordArray.random(16);
  // well known algorithm to generate key
  const key = PBKDF2(this.smartKey, salt, {
      keySize: keySize / 32,
      iterations: 100
    });
  // random IV
  const iv = lib.WordArray.random(128 / 8);
  // specify everything explicitly
  const encrypted = AES.encrypt(data, key, {
    iv: iv,
    padding: pad.Pkcs7,
    mode: mode.CBC
  });
  // combine everything together in base64 string
  const result = enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));
  return result;


}

  decryptStringFromServer(ciphertextB64) {
    if(ciphertextB64=='' || ciphertextB64 == null)                         // Base64 encoded ciphertext, 32 bytes string as key {
     {
       return '';
    }
    const key2 = enc.Utf8.parse(this.smartKey);                             // Convert into WordArray (using Utf8)
    const iv = lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);   // Use zero vector as IV
    const decrypted = AES.decrypt(ciphertextB64, key2, {iv: iv}); // By default: CBC, PKCS7
    return decrypted.toString(enc.Utf8);                       // Convert into string (using Utf8)
}

}
