import { ButtonFlatComponent } from './../../UIControls/button-flat/button-flat.component';
import { Component, Inject, Input, OnInit, ViewChildren, ElementRef } from '@angular/core';
import {  MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  @ViewChildren(ButtonFlatComponent) buttons: ButtonFlatComponent[];
  @Input() customFooter = false;
  @Input() useConfirmModal = true;
  @Input() footerCss = '';
  @Input() contentCss = '';
  @Input() mainDivCss = '';
  showCancelButton = true;
  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, confirmBtn: string, cancelBtn: string , isHtml: boolean, hideCancelButton: boolean},
  ) {
    if(data?.hideCancelButton == true)
     {
      this.showCancelButton = false;
     }
    if(this.useConfirmModal) {
      this.dialogRef.addPanelClass('confirm-modal')
    }
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }

}
