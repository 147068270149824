import { EventEmitter, Injectable, Component, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { finalize } from 'rxjs/operators';
import { LoaderService } from './LoaderService';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loadingIndicatorService: LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // emit onStarted event before request execution
    this.loadingIndicatorService.onStarted(req);

    return next
      .handle(req)
      // emit onFinished event after request execution
      .pipe(finalize(() => {
          try {
            setTimeout(x => this.killTime(), 10);
            this.loadingIndicatorService.onFinished(req);
          } catch (e) {
            console.log('LoaderInterceptor');
            console.log(e);
          }

        }
      ));
  }
  killTime() {

  }
}




