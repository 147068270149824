import { MobileService } from 'src/app/_services/mobile-service.service';
import { SliderComponent } from './../../common/UIControls/slider/slider.component';
import { SharedService } from 'src/app/_services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { AccessibilityStatementComponent } from 'src/app/account/accessibility-statement/accessibility-statement.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accessibility-settings',
  templateUrl: './accessibility-settings.component.html',
  styleUrls: ['./accessibility-settings.component.css']
})
export class AccessibilitySettingsComponent implements OnInit {

  @ViewChild(SliderComponent) slider: SliderComponent;

  fontSize: number = 100;
  defaultFontSize: number;
  fontExample: string = '100%';

  colorContractBtn: any[] = [
    { "name": "Monochrome", "isChecked": false },
    { "name": "Bright_contrast", "isChecked": false },
    { "name": "Dark_contrast", "isChecked": false },
    { "name": "Low_saturation", "isChecked": false },
    { "name": "High_saturation", "isChecked": false }
  ]

  fontSettingsBtn: any[] = [
    { "name": "MarkLinks", "isChecked": false },
    { "name": "MarkTitle", "isChecked": false },
    { "name": "ReadableFont", "isChecked": false }
  ]



  constructor(
    private matDialog: MatDialog,
    public shared: SharedService,
    private translate: TranslateService,
    public mobileService: MobileService

  ) {
    this.defaultFontSize = Number(this.translate.instant('defaultFontSize'));
  }

  ngOnInit(): void {
    this.fontSize = Number(localStorage.getItem('fontSize'));
    if (this.fontSize != 0) {
      this.fontExample = this.fontSize + '%';
    }
    var access = localStorage.getItem("accessibility");
    if (access != null)
      this.colorContractBtn.find(x => x.name == access).isChecked = true;
    const fontaccessibility = (localStorage.getItem('fontaccessibility') ?? '').trimEnd();
    if (fontaccessibility.length > 0)
      fontaccessibility.split(" ").forEach(setting => {
        this.fontSettingsBtn.find(x => x.name == setting).isChecked = true;
      })
  }

  backToDefaultSize() {
    this.fontSize = 100;
    this.slider.resetSize();
    this.changeExamplePreview(this.fontSize);
  }




  changeExamplePreview(val: number) {
    this.fontSize = val;
    this.fontExample = val + '%';
    this.changeFontSize();
  }


  declarationAccessibility() {
    this.matDialog.open(AccessibilityStatementComponent, {
      disableClose: false,
      autoFocus: true
    });

  }

  fontSettingsClick(setting: any) {
    setting.isChecked = !setting.isChecked;
    var access = localStorage.getItem("fontaccessibility") ?? '';
    if (!setting.isChecked) {
      document.getElementsByTagName('body').item(0).classList.remove(setting.name);
      access = access.replace(setting.name + " ", '');
    }
    else {
      document.getElementsByTagName('body').item(0).classList.add(setting.name);
      access += setting.name + " ";
    }
    if (access.length > 0)
      localStorage.setItem("fontaccessibility", access)
    else localStorage.removeItem("fontaccessibility")
  }

  colorContractClick(setting: any) {
    if (setting.isChecked) {
      setting.isChecked = false;
      this.changeColorContrasts();
      return;
    }
    if (this.colorContractBtn.find(x => x.isChecked) != null)
      this.colorContractBtn.find(x => x.isChecked).isChecked = false;
    setting.isChecked = true;
    this.changeColorContrasts();
  }

  changeColorContrasts(): void {
    var checked = this.colorContractBtn.find(x => x.isChecked);
    var access = localStorage.getItem("accessibility");
    if (access != null)
      document.getElementsByTagName('body').item(0).classList.remove(access);
    if (checked != null) {
      localStorage.setItem("accessibility", checked.name);
      document.getElementsByTagName('body').item(0).classList.add(checked.name);
    }
    else localStorage.removeItem("accessibility");
  }

  changeFontSize(): void {
    if (this.fontSize == 0)
      return;
    localStorage.setItem('fontSize', this.fontSize.toString());
    const calculatedFontSize = (this.fontSize * this.defaultFontSize / 100) * 100 / 16;
    document.getElementsByTagName('body').item(0).style.fontSize = calculatedFontSize + '%';
  }

  resetSettings() {
    this.backToDefaultSize();
    this.colorContractBtn.forEach(x => x.isChecked = false);
    this.changeColorContrasts();
    this.changeFontSize();
    const fontaccessibility = localStorage.getItem('fontaccessibility')?.trimEnd();
    localStorage.removeItem('fontaccessibility')
    fontaccessibility.split(" ").forEach(setting => {
      document.getElementsByTagName('body').item(0).classList.remove(setting);
      this.fontSettingsBtn.find(x => x.name == setting).isChecked = false;
    })
    //this.saveSettings();
  }

}
