import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MobileService } from 'src/app/_services/mobile-service.service';
import { SharedService } from 'src/app/_services/shared.service';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';

@Component({
  selector: 'app-pager-dialog',
  templateUrl: './pager-dialog.component.html',
  styleUrls: ['./pager-dialog.component.css']
})
export class PagerDialogComponent implements OnInit {

  @Input() title: string;
  @Input() footerClass = '';
  @Input() buttonText: string;
  @Input() titleColor: string;
  @Input() cssClass = '';
  @Input() closeIcon = 'close';
  @Input() Hidebuttons = false;
  @Input() disabledButton = true;
  @Input() numOfPages = 0;
  @Input() transformOriginClass: string;
  
  currentPage = 1;
  @Output() closeDialog = new EventEmitter();
  @Output() submitFunction = new EventEmitter();
  @Output() pageChange = new EventEmitter<number>();

  constructor(
    private dialogRef: MatDialogRef<FormDialogComponent>,
    public shared: SharedService,
    private mobile: MobileService

  ) {
      this.dialogRef.addPanelClass('edit-modal');

  }

  onSubmit(): void {
    this.submitFunction.emit(null);
  }
  changeToPage(pageId) {
    this.currentPage = pageId;
    this.pageChange.emit(this.currentPage);
  }
  changePage(x)  {
    this.currentPage += x;
    this.pageChange.emit(this.currentPage);
  }

  ngOnInit(): void {

  }
  closedialogEvent() {
    if (this.closeDialog.observers.length === 0) {
      this.dialogRef.close(false);
    } else {
      this.closeDialog.emit();
    }
  }

}
