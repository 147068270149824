import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {


  @Input() isChecked = false;
  @Input() tabindex = 0;
  @Input() isOpenText = false;
  @Input() disabled = false;
  @Input() text: string;
  @Input() color = '';
  @Input() cssClass = '';
  @Input() checkboxLabelClass = '';
  @Input() cssClassMain = '';
  @Input() startCenter = ' startCenter ';
  @Input() id = '';
  @Input() data: any;
  @Output() selectedValue = new EventEmitter<boolean>();
  @Output() textChanged = new EventEmitter<any>();
  @Output() onTextClick = new EventEmitter();
  @Input() control: UntypedFormControl = new UntypedFormControl();
  textboxControl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('cb') cb:any;

  constructor() {
  }

  textClick() {
    this.onTextClick.emit();
    let x:any = {};
    x.checked = (this.control.value??false)
    this.onSelectChange(x)
  }

  setFocus() {
   this.cb.focus();
  }
  onSelectChange($event): void {
    this.control.setValue($event.checked)
    this.selectedValue.emit($event.checked);
  }

  onSelectChangeSpace(flag): void {
    setTimeout(() => {
      this.isChecked = flag;
      this.control.setValue(flag);
      this.selectedValue.emit(flag);
    }, 10);
  }

  inputChanged($event) {
    this.textChanged.emit({'text': $event, 'checked': this.control.value});
  }


  setValue (flag: boolean, emitValue = false) {
    this.control.setValue(flag);
    if(emitValue) {
      this.selectedValue.emit(flag);
    }
  }

  clearChckbox() {
    this.control.setValue(false);
  }

  ngOnInit(): void {
    if (this.control == null) {
      this.control = new UntypedFormControl('');
    } else {
      this.isChecked = this.isChecked || this.control.value; }
    if (this.disabled)
      this.control.disable();
    this.control.setValue(this.isChecked);
  }



}
