import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';
import { PostService } from 'src/app/_services/postService';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class YearRangeServiceService {

  currentStudyYear: number;

  constructor(){
  }

  clearRange(){
    localStorage.removeItem('startDate');
    localStorage.removeItem('endDate');
  }

  getRange(studyYear: number, postSrv: PostService){
    postSrv.postData('api/user/GetYearRange',
      true,
      studyYear
    ).pipe(take(1)).subscribe((result: baseResult<any[]>) => {
      if (result.status){
        localStorage.setItem('startDate', result.data["minDate"]);
        localStorage.setItem('endDate', result.data["maxDate"]);

      }
    });
  }
}

