import { CookiesService } from 'src/app/_services/cookies-service.service';
import { PdfExportData } from './PdfExportData';
import { HttpEventType } from '@angular/common/http';
import { PostService } from 'src/app/_services/postService';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './shared.service';
import { SnackBarComponent } from '../common/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class FilesService {

  constructor(private post: PostService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private cookie: CookiesService

  ) { }

  public download(fileToken: string, fileName: string) {
    try {
      this.post.downloadFile(fileToken).subscribe(
        data => {
          switch (data.type) {
            case HttpEventType.DownloadProgress:
              //  this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
              break;
            case HttpEventType.Response:
              if (data.body.size == 1) {
                this.snackBar.openFromComponent(SnackBarComponent, {
                  duration: 3000,
                  data: { message: this.translate.instant('FileNotFound'), type: 'warning' }
                });
              }
              //  this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});
              let downloadedFile = new Blob([data.body], { type: data.body.type });
              //if (this.getMobileOperatingSystem() == 'Android') {
              downloadedFile = new Blob([data.body], { type: data.body.type + "/" + fileName });

              this.cookie.setItemShortTime('fileName', fileName, 1);
              //}

              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');

              document.body.appendChild(a);
              a.download = fileName;
              a.href = URL.createObjectURL(downloadedFile);
              a['data-filename'] = fileName;
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
          }
        },
        error => {
          //alert(error)
          // this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
        }
      );
    }
    catch (e) {
      //alert('error')
      console.log(e);
      //alert(e);
    }
  }

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "iOS";
    }

    return "unknown";
  }

  public downloadPdf(exportData: PdfExportData, fileName) {

    this.post.downloadPdf(exportData).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            //  this.downloadStatus.emit( {status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100)});
            break;
          case HttpEventType.Response:
            if (data.body.size == 1) {
              this.snackBar.openFromComponent(SnackBarComponent, {
                duration: 3000,
                data: { message: this.translate.instant('FileNotFound'), type: 'warning' }
              });
              return;
            }
            //  this.downloadStatus.emit( {status: ProgressStatusEnum.COMPLETE});
            let downloadedFile = new Blob([data.body], { type: data.body.type });
            if (this.getMobileOperatingSystem() == 'Android') {
              downloadedFile = new Blob([data.body], { type: data.body.type + "/" + fileName });
            }
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = exportData.fileName;
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      },
      error => {
        // this.downloadStatus.emit( {status: ProgressStatusEnum.ERROR});
      }
    );
  }


}
