import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/_services/shared.service';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BtnIconComponent } from '../btn-icon/btn-icon.component';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements OnInit {

  @Input() cssClass = '';
  closeBtn
  constructor(
    private translate:TranslateService,
    public _shared: SharedService
  ) {
    this.closeBtn = this.translate.instant('closeBtn');
  }

  @Input() isOpen = false;
  @Input() title: string;
  @Input() btnTabIndex = -1;

  @Output() closeDrawer = new EventEmitter()
  @Output() onLoad = new EventEmitter()
  @ViewChild(BtnIconComponent) iconBtn: BtnIconComponent;
  ngOnInit() {
    this.onLoad.emit();
  }

  updateOpenStatus(): void {
    this.isOpen = false;
    this.closeDrawer.emit(false);
  }

  setFocus() {
    setTimeout(() => {
      this.iconBtn.setFocus();
      //document.getElementById('drawerCloseIcon').focus();
    }, 50);
  }

}
