import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.css']
})
export class SecondaryNavComponent {
  isPdfExport = this.shared.getParamValueFromQuery('pdfLogin') == 1;
  constructor(

    public shared: SharedService,

  )  {

  }

  @Input() mobileMenu: boolean = false;
  @Input() navigationMenuTooltip: string = ''
}
