// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-footer {
  background-color: var(--app-footer-background-color);
  position: relative;
  color: var(--app-footer-text-color);
  text-align: left;
  border-top: 1px solid var(--app-footer-border-top);
  padding: 0 16px;
  height: 56px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  flex: 1;
  position: -webkit-sticky;
  position: sticky;
  box-shadow: var(--card-box-shadoew-elevation);
  border-radius: var(--card-border-radius);
}

.selected-counter {
  vertical-align: middle;
}

::ng-deep .edit-footer .icon {
  height: 40px;
  width: 40px;
  display: inline-block;
  border-radius: 50%;
  padding: 8px;
}

::ng-deep .edit-footer .icon-wrappers > * {
  margin-right: var(--rightRtl_8);
  margin-left: var(--leftRtl_8);
}

::ng-deep .edit-footer .icon-wrappers > div {
  display: inline-block;
}

::ng-deep .edit-footer .icon:hover {
  background-color: var(--hover-gray);
  cursor: pointer;
}

.edit-footer {
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/common/pageStructure/edit-footer/edit-footer.component.scss"],"names":[],"mappings":"AAAA;EACE,oDAAA;EACE,kBAAA;EACA,mCAAA;EACA,gBAAA;EACA,kDAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,OAAA;EACA,wBAAA;EACA,gBAAA;EACA,6CAAA;EACA,wCAAA;AACJ;;AAGA;EACE,sBAAA;AAAF;;AAGA;EACE,YAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;AAAF;;AAGA;EACE,+BAAA;EACA,6BAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,mCAAA;EACA,eAAA;AAAF;;AAGA;EACE,8BAAA;EACA,mBAAA;AAAF","sourcesContent":[".edit-footer {\r\n  background-color: var(--app-footer-background-color);\r\n    position: relative;\r\n    color: var(--app-footer-text-color);\r\n    text-align: left;\r\n    border-top: 1px solid var(--app-footer-border-top);\r\n    padding: 0 16px;\r\n    height: 56px;\r\n    display: flex;\r\n    align-items: center;\r\n    padding: 12px 16px;\r\n    flex: 1;\r\n    position: -webkit-sticky;\r\n    position: sticky;\r\n    box-shadow: var(--card-box-shadoew-elevation);\r\n    border-radius: var(--card-border-radius);\r\n\r\n}\r\n\r\n.selected-counter{\r\n  vertical-align: middle;\r\n}\r\n\r\n::ng-deep .edit-footer .icon {\r\n  height: 40px;\r\n  width: 40px;\r\n  display: inline-block;\r\n  border-radius: 50%;\r\n  padding: 8px;\r\n}\r\n\r\n::ng-deep .edit-footer .icon-wrappers > *{\r\n  margin-right: var(--rightRtl_8);\r\n  margin-left: var(--leftRtl_8);\r\n}\r\n\r\n::ng-deep .edit-footer .icon-wrappers > div{\r\n  display: inline-block;\r\n}\r\n\r\n::ng-deep .edit-footer .icon:hover {\r\n  background-color: var(--hover-gray);\r\n  cursor: pointer;\r\n}\r\n\r\n.edit-footer {\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n@media screen and (min-width: 960px) {\r\n \r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
