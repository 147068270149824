import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import {HDate} from '@hebcal/core';

@Pipe({
  name: 'hebrewDate',
  pure: false
})
export class HebrewDatePipe implements PipeTransform {
/**
 *
 */
constructor( private translate: TranslateService) {


}
  transform(value: any, args?: any): any {

    try {
      if( value === undefined || value === null || value.toString() == 'Invalid Date'){
        return '';
      }
      const hd2 = new HDate(new Date(value));
      const result =  hd2.renderGematriya();
      if( args === 'short') {
        return result.slice(0, -5);
      }
      return result;
    } catch (error) {
      console.log(error);
      console.log(value);
      return '';
    }
  }

}


