import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../_services/shared.service';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-empty',
  templateUrl: './empty.component.html',
  styleUrls: ['./empty.component.css']
})
export class EmptyComponent implements OnInit, OnDestroy {
  routerSubscribe;
  constructor(
    private shared: SharedService,
    private router: Router,
    private _location: Location
  ) {

   // _location.back();
    /*  this.routerSubscribe = router.events.subscribe((event: NavigationStart) => {
       if (event.navigationTrigger === 'popstate') {
         console.log('Back button pressed');
         //router.navigate(['dashboard']);
         //_location.

         // Perform actions
       }
     }); */
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
  }

  ngOnInit(): void {
    this.tryLoginFromCs();
  }

  ngOnDestroy() {
    //this.routerSubscribe.unsubscribe();
  }


  tryLoginFromCs(): boolean {
    const institutionCode = this.shared.getParamValueFromQuery('institutionCode');
    const csUser = this.shared.getParamValueFromQuery('csUser');
    const code = this.shared.getParamValueFromQuery('code');

    if (this.shared.isEmpty(institutionCode) || this.shared.isEmpty(csUser) || this.shared.isEmpty(code)) {
      return false;
    }
    //this.disconnect(true);
    console.log('ok')
    return true;

  }

}
