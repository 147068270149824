import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';

@Injectable({
  providedIn: 'root'
})
export class SqlService {
  uniqueId: any;


  constructor(
    private dbService: NgxIndexedDBService
  ) {
    this.getByKey('uniqueId').subscribe(result => {
      if (result != undefined) {
        this.uniqueId = result['value'];
      } else  {
        this.uniqueId = Math.random().toString(16).slice(2)+(new Date()).getTime()+Math.random().toString(16).slice(2);
        this.setValue('uniqueId',this.uniqueId)
      }
    })
  }

  setValue(key, value, expireDate = null) {
    this.dbService.update('storage', {
      key: key,
      value: value,
      expireDate: expireDate
    }).subscribe((key) => {
      //console.log('key: ', key);
    });
  }


  deleteByKey(key): Observable<unknown> {
    return this.dbService.deleteByKey('storage', key).pipe().map((status) => {
     // console.log('Deleted?:', status);
      return status;
    });
  }


  getByKey(key): Observable<any> {
    return this.dbService.getByKey('storage', key).pipe().map((data) => {
      if (data!= null && data['expireDate'] != null && data['expireDate'] < new Date()) {
        this.deleteByKey(key).subscribe();
        return null;
      }
      return (data);
    });
  }

/*   test(key) {
    this.dbService.
  }

 */


}

