import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {  MatButton } from '@angular/material/button';

@Component({
  selector: 'app-button-flat',
  templateUrl: './button-flat.component.html',
  styleUrls: ['./button-flat.component.css']
})
export class ButtonFlatComponent implements OnInit {

  @Input() text: string;
  @Input() cssClass = '';
  @Input() inputId = '';
  @Input() disable: boolean;
  @Input() isInline: boolean = false;

  @ViewChild('btn') btn: MatButton;
  @Output() clickEvent = new EventEmitter<Event>();

  constructor() {

  }

  onClick(e): void {
    this.clickEvent.emit(e);
  }

  ngOnInit(): void {
  }

  setFocus(){
    this.btn.focus();
  }

}
