// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-cards{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/pageStructure/multi-cards-view/multi-cards-view.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf","sourcesContent":[".multi-cards{\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
