// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .lottie svg {
    transform: unset !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/lottie/lottie.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B","sourcesContent":["::ng-deep .lottie svg {\r\n    transform: unset !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
