
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output } from '@angular/core';
import { SharedService } from '../../../_services/shared.service';

@Component({
  selector: 'app-span',
  templateUrl: './span.component.html',
  styleUrls: ['./span.component.css']
})
export class SpanComponent implements OnInit ,AfterViewInit {

  @ViewChild('content', { read: ElementRef, static: true }) content: ElementRef;
  ltrClass = '';
  @Input() cssClass = '';
  @Input() startCenter = ' startCenter ';
  @Output() onTextClick = new EventEmitter();

  constructor( private shared: SharedService) { }
  ngAfterViewInit(): void {
    const tmp = this.content.nativeElement.childNodes;
   if (tmp.length > 0 ) {
    if (!this.shared.isUnicode(tmp[0].data)) {
      setTimeout(() => {
        this.ltrClass = 'ltr textAlignLeft';
      }, 10);

    }
   }
  }

  ngOnInit(): void {

  }
  textClick() {
    this.onTextClick.emit();
  }


}
