import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-disconnected-alert',
  templateUrl: './disconnected-alert.component.html',
  styleUrls: ['./disconnected-alert.component.css']
})
export class DisconnectedAlertComponent implements OnInit ,OnDestroy {
  intervalId = 0;
  message = '';
  seconds = 60;
  originalTitle = '';
  constructor(
    private dialogRef: MatDialogRef<DisconnectedAlertComponent>,
    private titleService: Title,
    private translate: TranslateService

  ) {
    this.dialogRef.addPanelClass('confirm-modal')
    this.originalTitle = titleService.getTitle();
  }

  onCancelClick(): void {
    this.titleService.setTitle(this.originalTitle);
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    this.titleService.setTitle(this.originalTitle);
    this.dialogRef.close(true);
  }
  clearTimer() { clearInterval(this.intervalId); }
  ngOnInit()    { this.start(); }
  ngOnDestroy() { this.clearTimer(); }
  start() { this.countDown(); }
  stop()  {
    this.clearTimer();
    this.message = `Holding at T-${this.seconds} seconds`;
  }

  private countDown() {
    this.clearTimer();
    this.intervalId = window.setInterval(() => {
      this.seconds -= 1;
      this.titleService.setTitle(this.translate.instant('disconnectXXXseconds').replace('XXX', this.seconds) );
      setTimeout(() => {
        this.titleService.setTitle('Webtop');
      }, 850);
      if (this.seconds === 0) {
       this.dialogRef.close(false);
      } else {
        if (this.seconds < 0) { this.seconds = 10; } // reset

      }
    }, 1000);
  }


}
