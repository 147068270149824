// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: var(--app-footer-background-color);
  color: var(--app-footer-text-color);
  text-align: left;
  border-top: 1px solid var(--app-footer-border-top);
  padding: 0 16px;
  min-height: 56px;
  display: flex;
  align-items: center;
  direction: var(--directionRtl);
  justify-content: space-between;
  flex: 1;
  position: -webkit-sticky;
  position: sticky;
}

.footer.btn-container{
  justify-content: center;
}

::ng-deep .footer .mat-icon:not(.iconInBtn){
  color: var(--btn-icon-tool-color) !important;
}

@media screen and (max-width: 959px) {
  .footer.btn-container{
    padding: 8px;
    display: flex;
    gap: 8px;
  }

  ::ng-deep .footer.btn-container app-button{
    width: 100%;
    height: 46px;
  }

  ::ng-deep .footer.btn-container button{
    width: 100%;
    height: 46px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/common/pageStructure/footer/footer.component.css"],"names":[],"mappings":"AAAA;EACE,oDAAoD;EACpD,mCAAmC;EACnC,gBAAgB;EAChB,kDAAkD;EAClD,eAAe;EACf,gBAAgB;EAKhB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,8BAA8B;EAG9B,OAAO;EACP,wBAAgB;EAAhB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;IACb,QAAQ;EACV;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;EACd;AACF","sourcesContent":[".footer {\r\n  background-color: var(--app-footer-background-color);\r\n  color: var(--app-footer-text-color);\r\n  text-align: left;\r\n  border-top: 1px solid var(--app-footer-border-top);\r\n  padding: 0 16px;\r\n  min-height: 56px;\r\n  display: -webkit-box;\r\n  display: -moz-box;\r\n  display: -ms-flexbox;\r\n  display: -webkit-flex;\r\n  display: flex;\r\n  align-items: center;\r\n  direction: var(--directionRtl);\r\n  justify-content: space-between;\r\n  -webkit-flex: 1;\r\n  -ms-flex: 1;\r\n  flex: 1;\r\n  position: sticky;\r\n}\r\n\r\n.footer.btn-container{\r\n  justify-content: center;\r\n}\r\n\r\n::ng-deep .footer .mat-icon:not(.iconInBtn){\r\n  color: var(--btn-icon-tool-color) !important;\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  .footer.btn-container{\r\n    padding: 8px;\r\n    display: flex;\r\n    gap: 8px;\r\n  }\r\n\r\n  ::ng-deep .footer.btn-container app-button{\r\n    width: 100%;\r\n    height: 46px;\r\n  }\r\n\r\n  ::ng-deep .footer.btn-container button{\r\n    width: 100%;\r\n    height: 46px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
