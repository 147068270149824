import { String } from 'typescript-string-operations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-inline-search',
  templateUrl: './inline-search.component.html',
  styleUrls: ['./inline-search.component.scss']
})
export class InlineSearchComponent implements OnInit {

  constructor() { }

  @Input() placeholder: string;
  @Output() inputChanged = new EventEmitter<any>();

  text: string;

  ngOnInit() {
  }

  clearInput(){
    this.text = "";
    this.searchChanged();
  }

  searchChanged() {
    this.inputChanged.emit(this.text);
  }

}
