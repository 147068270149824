import { SharedService } from './../../../_services/shared.service';
import { Form } from './../../../_models/WeeklyScedule/WeeklySceduleInitData';
import { AfterViewChecked, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss', '../inputs.css']
})
export class TextComponent implements OnInit, OnChanges, AfterViewChecked {


  @Input() label: string;
  @Input() iconAriaLabel: string = '';
  @Input() readOnly = false;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() icon?: string;
  @Input() hintAlert?: string;
  @Input() hinthelper?: string;
  @Input() hintWarning?: string;
  @Input() isPassword = false;
  @Input() clickOk = false;
  @Input() showCounter = false;
  @Input() inputType = 'text';
  @Input() inputMode = 'text';
  @Input() minValue = '';
  @Input() maxValue = '';
  @Input() autocomplete = true;
  @Input() maxlength = 250;
  @Input() disableAutoLtr = false;
  @Input() placeholder = '';
  @Input() value = '';
  @Input() disabled = false;
  @Input() ltr = false;
  @Input() id;
  @Input() pattern = ".*";
  @Input() hintClass = '';
  hintWarningValid = ''
  originalValue: string;

  currentValue = '';
  ltrClass = 'textAlignRight';

  @Output() inputChanged = new EventEmitter<any>();
  @Output() valueChangeBlur: EventEmitter<any> = new EventEmitter<any>();

  hide = true;


  constructor(
    public shared: SharedService,
    private translate: TranslateService
  ) {
    if (this.id === undefined) {
      this.id = shared.randomString(10, '');
    }
  }
  ngAfterViewChecked(): void {
    if (this.control.invalid && this.minValue?.toString() != '') {
      this.hintWarningValid = this.translate.instant('gradeMinMax').replace('{MinValue}', this.minValue).replace('{MaxValue}', this.maxValue);
    } else {
      this.hintWarningValid = '';
    }
  }

  scrollDocumnet() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  clearText() {
    this.control.setValue('');
    this.inputChanged.emit('');
    this.valueChangeBlur.emit({
      value: '',
      classList: [],
      textChanged: true
    });
  }

  dataChangedBlur($event) {
    if (this.control.disabled) {
      return;
    }
    this.valueChangeBlur.emit({
      value: this.control.value,
      classList: Array.from(($event.target as HTMLElement).classList).join(' '),
      textChanged: this.originalValue != this.control.value
    });

    this.originalValue = this.control.value;
  }

  setValue(value) {
    this.control.setValue(value);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.control.setValue(this.value);
    this.originalValue = this.control.value;
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    if (!this.shared.isEmpty(this.minValue)) {
      this.control.addValidators(Validators.min(parseInt(this.minValue)))
    }
    if (!this.shared.isEmpty(this.maxValue)) {
      this.control.addValidators(Validators.max(parseInt(this.maxValue)))
    }
  }

  ngOnInit() {
    this.dataChanged(null);
    if (this.disabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
    this.disabled = this.control.disabled;
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }

  dataChanged($event) {
    if (this.disableAutoLtr) {
      return;
    }
    if (this.inputType === 'numeric' && this.control.value !== null && this.control !== undefined) {
      this.control.setValue(this.control?.value?.toString().replace(/[^0-9.]*/g, ''));
    }

    if (this.maxlength !== undefined && this.control.value?.length > this.maxlength) {
      this.control.setValue(this.control.value.slice(0, this.maxlength));

    }


    this.inputChanged.emit(this.control.value);
    if (!this.shared.isUnicode(this.control.value) || this.ltr) {
      this.ltrClass = 'textAlignLeft ltr';
    } else {
      this.ltrClass = 'textAlignRight';

    }

  }



}
