import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { SharedService } from 'src/app/_services/shared.service';
import { mode } from 'crypto-js';
import { ChartColors } from 'src/app/_models/charts/DoughtChartColor';
import { color } from '@alyle/ui/color';

@Component({
  selector: 'app-horizontalbar-chart',
  templateUrl: './horizontalbar-chart.component.html',
  styleUrls: ['./horizontalbar-chart.component.css']
})
export class HorizontalbarChartComponent implements OnInit {


  @Input() public chartData: any[];
  @Input() public chartLabels: any[];
  @Input() public minY = 0;
  @Input() public maxY = 100;
  @Input() public stepSize = 20;
  @Input() public chartId = 'canvas'
  @Input() public showLegened: boolean = false;
  @Input() private showXTicks: boolean = true;
  @Input() private showXGridLines: boolean = true;
  @Input() public labelDataAlldatasets: boolean = false;
  @Input() private showTooltipTitle: boolean = true;
  @Input() private stackBar: boolean = false;

  chart: any = [];
  data: any = [];
  finishLoad: boolean = false;

  constructor(
    private translate: TranslateService,
    private shared: SharedService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  buildChartData(chartData) {
    this.data = chartData.map((x, index) => {
      if (x.customSettings) {
        return x.elem;
      }
      return {
        label: x.label,
        data: x.data,
        barThickness: 'flex',
        backgroundColor: x.color != null ? x.color : ChartColors.colors[index],
        hoverBackgroundColor: x.hoverColor != null ? x.hoverColor : ChartColors.hoverColors[index],
        borderRadius: 5,
        order: index + 1
      }
    });
  }

  ngOnInit(): void {
    this.buildChartData(this.chartData);
  }

  getLegendList() {
    return this.chart?.legend?.legendItems.sort((a, b) => a.datasetIndex - b.datasetIndex);
  }
  reset() {
    this.chart.reset();
  }

  update() {
    this.chart.update();
  }

  ngAfterViewInit() {
    const self = this;
   this.chart = new Chart(
      (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d'),
      {
        type: 'bar',
        data: {
          labels: this.chartLabels,
          datasets: this.data
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: this.stackBar,
              min: this.minY, // minimum value
              max: this.maxY,// maximum value
              border: {color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color')},
              grid: { 
                display: this.showXGridLines, 
                color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color')},
              ticks: {
                color: getComputedStyle(document.documentElement).getPropertyValue('--app-body-secondary-text-color'),
                display: this.showXTicks,
                font: {
                  family: 'boldOpenSans, boldMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  size: 12
                },

                stepSize: this.stepSize
              }
            },
            y: {
              stacked: this.stackBar,
              border: {color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color')},
              grid: { color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color') },
              beginAtZero: true,
              ticks: {
                color: getComputedStyle(document.documentElement).getPropertyValue('--app-body-secondary-text-color'),
                font: {
                  family: 'regularOpenSans, regularMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  size: 12
                },
              }
            }
          },
          plugins: {
            tooltip: {
              rtl: this.shared.isRtl(),
              enabled: true,
              bodyAlign: this.shared.isRtl() ? 'right' : 'left',
              displayColors: this.chartData?.length > 1 && this.labelDataAlldatasets,
              titleFont: {
                family: 'boldOpenSans, boldMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              bodyFont: {
                family: 'regularOpenSans, regularMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              mode: this.labelDataAlldatasets ? 'index' : 'nearest',
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 8,
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-background-color'),
              bodyColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              titleColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              cornerRadius: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--tooltip-radius'), 10),
              itemSort: function (a, b) {
                return a.datasetIndex - b.datasetIndex;
              },
              callbacks: {
                title: function (context) {
                  if (self.showTooltipTitle)
                    return context[0].label.split(",")[0];
                  return '';
                },
                label: function (context) {
                  if (!self.labelDataAlldatasets)
                    return context.label.split(",");
                  else {
                    return `${context.dataset.label}: ${context.raw == null ? self.translate.instant('noInserted') : context.formattedValue}`;
                  }
                }
              }
            },
            legend: {
              display: false
            },
          }
        }
      }
    );
    this.finishLoad = true;
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.finishLoad)
      return;
    this.buildChartData(this.chartData);
    this.chart.data.labels = this.chartLabels;
    this.chart.data.datasets.forEach((x, index) => {
      x.data = this.data[index].data;
      if (this.data[index].backgroundColor != null) {
        x.backgroundColor = this.data[index].backgroundColor;
        x.hoverBackgroundColor = this.data[index].hoverBackgroundColor;
      }
    });
    this.chart.update();
  }

  legendClick(item) {
    this.chart.setDatasetVisibility(item.datasetIndex, !this.chart.isDatasetVisible(item.datasetIndex));
    this.chart.update();
  }

}
