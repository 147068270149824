import { StyleRenderer, Platform, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import {
  ImgCropperConfig,
  ImgCropperEvent,
  LyImageCropper,
  ImgCropperErrorEvent,
  ImgCropperLoaderConfig,
  STYLES as CROPPER_STYLES
} from '@alyle/ui/image-cropper';

import { Component, Inject, OnInit, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(CROPPER_STYLES);
  return {
    cropper: lyl `{
      height: 320px
      .LyImageCropper-area-d {
        position: absolute
        top: 0 !important
        bottom: 0 !important
        left: 0 !important
        right: 0 !important
        width: 256px !important
        height: 256px !important
        margin: auto !important
        border-radius: 50% !important
        pointer-events: none
        box-shadow: 0 0 0 20000px rgb(0 0 0 / 60%)
        &::before {
          width: 0  !important
          height: 0 !important
          margin: auto !important
          border-radius: 50% !important
          background: #fff !important
          border: solid 2px rgb(255,255,255) !important
        }

        &::after {
          border: solid 2px rgb(255,255,255) !important
          border-radius: 0 !important
        }
      }
      .d {
        position: absolute
        top: 0 !important
        bottom: 0 !important
        left: 0 !important
        right: 0 !important
        width: 256px !important
        height: 256px !important
        margin: auto !important
        border-radius: 50% !important
        pointer-events: none
        box-shadow: 0 0 0 20000px rgb(0 0 0 / 60%)
        &::before {
          width: 0  !important
          height: 0 !important
          margin: auto !important
          border-radius: 50% !important
          background: #fff !important
          border: solid 2px rgb(255,255,255) !important
        }

        &::after {
          border: solid 2px rgb(255,255,255) !important
          border-radius: 0 !important
        }
      }
    }`,
    cropperResult: lyl `{
      position: relative
      width: 150px
      height: 150px
    }`,
  };
};


@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.css']
})
export class CropperDialogComponent implements OnInit ,AfterViewInit{


  classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string | null = null;
  scale: number;
  ready = false;
  minScale: number;
  image: string = '';
  watingImage: string = '';
  //currentUser = this.user.getUser();
  @ViewChild(LyImageCropper) readonly cropper!: LyImageCropper;
  @Output() saveCropperClick = new EventEmitter<string>();
  myConfig: ImgCropperConfig = {
    // autoCrop: true,
    width: 256, // Default `250`
    height: 256, // Default `200`
    fill: '#ff2997', // Default transparent if type == png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
    responsiveArea: true,
    output: {
      width: 256,
      height: 256
    }

  };
  imagePreview = true;
  constructor(
    private dialogRef: MatDialogRef<CropperDialogComponent>,
    readonly sRenderer: StyleRenderer,
    @Inject(MAT_DIALOG_DATA) public data: { input: any, isUploadFile: boolean },
  ) {

  }

  ngAfterViewInit() {

    if(this.data.isUploadFile) {
      this.cropper.selectInputEvent(this.data.input)
    } else {
      this.cropper.loadImage(this.data.input);
    }

   setTimeout(() => {
      this.cropper.fit();
      this.cropper.center();
    }, 100);


  }

  ngOnInit(): void {
  }

  onCropped(e: ImgCropperEvent) {
    this.croppedImage = e.dataURL;
  }
  onLoaded(e: ImgCropperEvent) {
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
  }
  onSliderInput(event) {

    this.scale = event;
  }
  close() {
    this.dialogRef.close(false);
  }

  saveCroppedImage() {
    this.cropper.crop();
    this.dialogRef.close(this.croppedImage)
  }
}
