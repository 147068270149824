import { MatTooltip } from '@angular/material/tooltip';
import { Directive, HostListener, inject, } from '@angular/core';

@Directive({
  selector: '[matTooltip]',
  standalone: true,
})
export class ClickableTooltipDirective {

  private matTooltip = inject(MatTooltip);

  @HostListener('click')
  onClick() {

    this.matTooltip.toggle();
  }

}
