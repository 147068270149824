import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SortItem } from 'src/app/_models/sortItem';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-edit-footer',
  templateUrl: './edit-footer.component.html',
  styleUrls: ['./edit-footer.component.scss']
})
export class EditFooterComponent implements OnInit {

  @Input() isSelectAll: boolean = false;
  constructor(public _shared: SharedService) { }

  @Input() numOfSelected: number = 0;

  @Output() SelectedChange = new EventEmitter<boolean>();
  @Output() closeEditMode = new EventEmitter<boolean>();
  @Output() extraOptionClicked = new EventEmitter<SortItem>();

  @Input() shushiOptions: SortItem[];
  @Input() shushiDisabled: boolean = false;
  
  ngOnInit() {
  }

  selectedClick(value: boolean, selectByKeyboard: boolean){
    this.isSelectAll = value;
    this.SelectedChange.emit(this.isSelectAll);
    if (!selectByKeyboard) { return; }
    if (value) {
      setTimeout(() => {
        document.getElementById('footerSelectAlla').focus();
      }, 200);
    }
  }

}
