import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileService } from 'src/app/_services/mobile-service.service';

@Component({
  selector: 'app-help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.css']
})
export class HelpButtonComponent {
  @Input() toolTipText = '';

  constructor(
    public mobileService: MobileService
  ) {

  }

  changeFocus() {
     setTimeout(() => {
      document.getElementById('toolTipText').focus();
    }, 500);

  }

  changeFocus2() {
    console.log(1111)
 /*    setTimeout(() => {
      document.getElementById('toolTip').focus();
    }, 250);
 */
  }

}
