import { SharedService } from 'src/app/_services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.css']
})
export class TextEditComponent implements OnInit , AfterViewInit {
  textbox: UntypedFormControl = new UntypedFormControl();
  constructor(
    private translate: TranslateService,
    private dialogRef: MatDialogRef<TextEditComponent>,
    private snackBar: MatSnackBar,
    private _shared: SharedService,
    @Inject(MAT_DIALOG_DATA) public data: { message: string, confirmBtn: string, cancelBtn: string ,
       isHtml: boolean , text: string , label: string, title: string,
      confirmAlertMessage: string},
  ) {
    this.dialogRef.addPanelClass('confirm-modal');
    this.textbox.setValue(data.text);
    if (data.confirmBtn === undefined || data.confirmBtn === null || data.confirmBtn === '')   {
      data.confirmBtn = translate.instant('Save');
    }
    if (data.cancelBtn === undefined || data.cancelBtn === null || data.cancelBtn === '')   {
      data.cancelBtn = translate.instant('cancelBtn');
    }
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.textbox.setValue(this.data.text);
    }, 10);
  }


  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    if (!this._shared.isEmpty(this.data.confirmAlertMessage)  && this.textbox.value === '' ) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.data.confirmAlertMessage, type: 'warning' }
      });
      return;
    }

    this.dialogRef.close(this.textbox.value);
  }

  ngOnInit(): void {
  }

}
