import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CodeInputComponent } from 'angular-code-input';


@Component({
  selector: 'app-code-input-control',
  templateUrl: './code-input-control.component.html',
  styleUrls: ['./code-input-control.component.css']
})

export class CodeInputControlComponent implements OnInit {

  @Input() codeLength: number;
  @Input() timeToResend: number = 30;
  @Output() code: EventEmitter<string> = new EventEmitter<string>();
  @Output() codeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() codeCompleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendCode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('codeInput')  codeInput !: CodeInputComponent;

  myTime:number = this.timeToResend;
  showSpinner:boolean = true;
  codeString: string;
  isCodeCompleted: boolean = false;

  constructor() {}

  onCodeChanged(code: string) {
    if (code.length < this.codeLength) {
      this.isCodeCompleted = false;
      this.codeChanged.emit('');
    }
    this.codeString = code;
  }

  onCodeCompleted(code: string) {
    this.codeString = code;
    this.isCodeCompleted = true;
    this.codeCompleted.emit(code);
  }

  sendCodeAgain() {
    this.codeInput.reset();
    this.sendCode.emit(true);

    this.runTimer();
  }

  runTimer(){
    this.showSpinner = true;
    this.myTime = this.timeToResend;
    const myInterval = setInterval(() => {
      if (this.myTime > 0) {
        this.myTime = this.myTime - 1;
        this.showSpinner = true;
      } else {
        this.showSpinner = false;
        clearInterval(myInterval);
      }
    }, 1000);
  }

  ngOnInit() {
    this.runTimer();
  }

}
