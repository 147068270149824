import { TranslateService } from '@ngx-translate/core';
import { Colors } from './../../../_consts/colors';
import { Component, EventEmitter, Input, OnInit, Output, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import {   MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss']
})
export class ColorInputComponent implements OnInit {

  constructor( ) {
  }

  @Input() label: string;
  @Input() disabled = false;
  @Output() outCcolor = new EventEmitter();
  @ViewChildren('item') items: ElementRef;
  @ViewChild('t') colorMenu: MatMenuTrigger;

  readonly colors = Colors.colors;

  @Input() selectedColor: string = Colors.colors[0].value;

  changeSelect(value: string) {
    this.selectedColor = value;
    this.outCcolor.next(value);
    setTimeout(() => {
      this.colorMenu.closeMenu();
    }, 100);

  }

  ngOnInit() {
    if (this.selectedColor == null || this.selectedColor.length < 6) {
      this.selectedColor = Colors.colors[0].value;
    }
  }
  menuOpened() {
    setTimeout(() => {
      (this.items as any)._results[0]?.nativeElement.focus();
    }, 0);
  }

}
