export class PdfExportData {
  title: string;
  url: string;
  fileName: string;
  path: string;
  skipPage1 : boolean;
  maxPages: number;
  exportToPdf: boolean;
  skipPages: number;
}

