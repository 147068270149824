import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ScrollToTopComponent } from '../../UIControls/scroll-to-top/scroll-to-top.component';

@Component({
  selector: 'app-multi-cards-view',
  templateUrl: './multi-cards-view.component.html',
  styleUrls: ['./multi-cards-view.component.css']
})
export class MultiCardsViewComponent implements OnInit {

  @Input() fullHeight: boolean;
  @Input() showFooter: boolean = false;
  @Input() buttonText: string = 'Save';
  @Input() cssClass: string = '';
  @Input() schowScrollTop: boolean = true;
  @ViewChild(ScrollToTopComponent) scrollTopBtn: ScrollToTopComponent;
  
  constructor() { }

  ngOnInit() {
  }

}
