import { RadioButtonsGroupComponent } from './../../radio-buttons-group/radio-buttons-group.component';
import { SharedService } from 'src/app/_services/shared.service';
import { AssesmentBank } from './../../../../_models/AssesmentBank';
import { PostService } from './../../../../_services/postService';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { radioBtnOption } from '../../radio-buttons-group/radioBtnOption';
import { take } from 'rxjs/operators';
import { baseResult } from 'src/app/_models/baseResult';

@Component({
  selector: 'app-full-bank',
  templateUrl: './full-bank.component.html',
  styleUrls: ['./full-bank.component.scss']
})
export class FullBankComponent implements OnInit {

  searchedOptions: radioBtnOption[] = [];
  pageTitle = 'bankTextualAssesment';
  @ViewChild(RadioButtonsGroupComponent) rdbGroup: RadioButtonsGroupComponent;
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      options: radioBtnOption[],
      selectedAssesment: number,
      pageTitle: string,
      splitByGroupText: string,
      allowRemove?: boolean
    },
    private _mdr: MatDialogRef<FullBankComponent>,
    public shared: SharedService
  ) {
    if (!shared.isEmpty(dialogData.pageTitle)) {
      this.pageTitle = dialogData.pageTitle;
    }
    this.searchedOptions = this.dialogData.options;
  }

  ngOnInit() {
    //this.loadData();
  }



  searchInList(val: string) {
      this.searchedOptions = this.dialogData.options.filter(x => x.text.includes(val) || x.text.includes(this.shared.replcaeLanguage(val)));
      this.rdbGroup.getItemsByGroupText(this.searchedOptions);
  }

  selectAssesment() {
    this._mdr.close(this.dialogData.options.find(x => x.value == this.dialogData.selectedAssesment));
  }

  deleteAssesment() {
    this._mdr.close(null);
  }

  closeDialog() {
    this._mdr.close(false);
  }

}
