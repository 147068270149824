import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-box-dialog',
  templateUrl: './message-box-dialog.component.html',
  styleUrls: ['./message-box-dialog.component.css']
})
export class MessageBoxDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<MessageBoxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      messages: string[],
      confirmBtn: string,
      header: string,
      messageOnly: boolean },
  ) {
    this.dialogRef.addPanelClass("confirm-modal")
  }

  onConfirmClick(): void {
    this.dialogRef.close();
  }



  ngOnInit(): void {
  }

}
