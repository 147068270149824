import { Injectable, Inject } from '@angular/core';


import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private notify = new Subject<any>();
  /**
   * Observable string streams
   */
  notifyObservable$ = this.notify.asObservable();

  constructor( private translate: TranslateService) {}

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
  public getClassName(classCode, classNum): string {

    let className;
    if (classCode === 0 || classCode === 33) {
      className = this.translate.instant('Grade0');
    } else if (classCode > 0 && classCode < 15) {
      className = this.translate.instant('Grade' + classCode) ;
    } else { className = this.translate.instant('UnknownGrade') + ' ' + this.translate.instant('Code') + '-' + classCode; }
    if (classNum !== undefined) {
      return className + ' ' + classNum;
    } else { return className; }
  }
}
