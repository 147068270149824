import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-badge',
  templateUrl: './info-badge.component.html',
  styleUrls: ['./info-badge.component.css']
})
export class InfoBadgeComponent implements OnInit {

  @Input() text: string;
  @Input() cssClass: string = '';

  constructor() { }

  ngOnInit() {
  }

}
