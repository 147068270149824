import { FilesService } from './../../../_services/files.service';
import { PostService } from 'src/app/_services/postService';
import { FileData } from './../../../_models/FileData';
import { Component, OnInit, Input } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import {  MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../dialogs/image-dialog/image-dialog.component';

@Component({
  selector: 'app-attached-file',
  templateUrl: './attached-file.component.html',
  styleUrls: ['./attached-file.component.css']
})
export class AttachedFileComponent implements OnInit {

  @Input() name: string;
  @Input() fileData: FileData;
  @Input() extraData: boolean;
  @Input() nameCss: string = '';
  @Input() cssClass: string = '';


  imageExtension = ['.jpeg', '.jpg', '.png', '.gif', '.tiff', '.exif', '.tif', '.jpe'];

  constructor(private fileService: FilesService,
    private matDialog: MatDialog,
    ) {
  }

  ngOnInit(): void {
  }


  public download() {
    this.fileService.download(this.fileData.fileUrl.slice(this.fileData.fileUrl.indexOf('token=') + 6) , this.fileData.fileName );
  }

  preview() {
    if (!this.isImage()) {
      return this.download();
    }
    this.matDialog.open(ImageDialogComponent, {
      disableClose: false,
      data: { title: this.name,
       fileUrl: this.fileData.fileUrl },
      autoFocus: true
    });
  }
  isImage(): boolean {

    return  this.imageExtension.includes(this.fileData.fileExtantion.toLowerCase());
  }

}
