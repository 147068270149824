import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartTypeRegistry } from 'chart.js/auto';
import { ChartColors } from 'src/app/_models/charts/DoughtChartColor';
import { MobileService } from 'src/app/_services/mobile-service.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-verticalbar-chart',
  templateUrl: './verticalbar-chart.component.html',
  styleUrls: ['./verticalbar-chart.component.css']
})
export class VerticalbarChartComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() public chartData: any[];
  @Input() public chartLabels: any[];
  @Input() public minY = 0;
  @Input() public maxY = 100;
  @Input() public stepSize = 20;
  @Input() public chartId = 'canvas';
  @Input() public chartType: any = 'bar';
  @Input() public showLegened: boolean = false;
  @Input() public chartFill: boolean = false;
  @Input() public backgroundColorFillColor: string = '';
  @Input() public canvasCss: string = '';
  @Input() private showXTicks: boolean = true;
  @Input() private displayXData: boolean = true;
  @Input() private displayYData: boolean = true;
  @Input() private showXGridLines: boolean = true;
  @Input() private showYGridLines: boolean = false;
  @Input() public labelDataAlldatasets: boolean = false;
  @Input() private showTooltipTitle: boolean = true;
  @Input() private stackBar: boolean = false;
  @Input() private addAverageToData: boolean = false;
  @Input() public chartLabel: string;

  chart: any = [];
  data: any = [];
  finishLoad: boolean = false;

  constructor(
    private translate: TranslateService,
    private shared: SharedService,
    private cdRef: ChangeDetectorRef,
    private mobile: MobileService
  ) {
  }

  buildChartData(chartData) {
    this.data = chartData.map((x, index) => {
      if (x.customSettings) {
        return x.elem;
      }
      return {
        label: x.label,
        data: x.data,
        barThickness: 'flex',
        borderColor: x.color != null ? x.color : ChartColors.colors[index],
        backgroundColor: this.backgroundColorFillColor != '' ? this.backgroundColorFillColor :
          x.color != null ? x.color : ChartColors.colors[index],
        hoverBackgroundColor: x.hoverColor != null ? x.hoverColor : ChartColors.hoverColors[index],
        borderRadius: 5,
        order: index + 1,
        fill: this.chartFill,
        pointRadius: 0
      }
    });
  }

  ngOnInit(): void {
    this.buildChartData(this.chartData);
  }

  getLegendList() {
    return this.chart?.legend?.legendItems.sort((a, b) => a.datasetIndex - b.datasetIndex);
  }
  reset() {
    this.chart.reset();
  }

  update() {
    this.chart.update();
  }

  ngAfterViewInit() {
    const self = this;
    this.chart = new Chart(
      (document.getElementById(this.chartId) as HTMLCanvasElement).getContext('2d'),
      {
        type: this.chartType,
        data: {
          labels: this.chartLabels,
          datasets: this.data
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: this.displayXData,
              stacked: this.stackBar,
              border: { color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color') },
              grid: { display: this.showXGridLines, color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color') },
              ticks: {
                autoSkip: false,
                minRotation: this.mobile.isMobileView && this.chartLabels.length > 10 ? 90 : 0,
                maxRotation: 90,
                color: getComputedStyle(document.documentElement).getPropertyValue('--app-body-secondary-text-color'),
                display: this.showXTicks,
                font: {
                  family: 'boldOpenSans, boldMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  size: 12
                },
                callback: function (val: number, index) {
                  return this.getLabelForValue(val)[0];
                }
              }
            },
            y: {
              display: this.displayYData,
              stacked: this.stackBar,
              border: { color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color') },
              grid: {
                display: this.showYGridLines,
                color: getComputedStyle(document.documentElement).getPropertyValue('--divider-color')
              },
              min: this.minY, // minimum value
              max: this.maxY,// maximum value
              beginAtZero: true,
              ticks: {
                color: getComputedStyle(document.documentElement).getPropertyValue('--table-list-row-header-text-color'),
                font: {
                  family: 'regularOpenSans, regularMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                  size: 12
                },
                stepSize: this.stepSize
              }
            }
          },
          plugins: {
            tooltip: {
              rtl: this.shared.isRtl(),
              enabled: true,
              bodyAlign: this.shared.isRtl() ? 'right' : 'left',
              displayColors: this.labelDataAlldatasets,
              titleFont: {
                family: 'boldOpenSans, boldMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              bodyFont: {
                family: 'regularOpenSans, regularMada, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
              },
              mode: this.labelDataAlldatasets ? 'index' : 'nearest',
              intersect: false,
              usePointStyle: true,
              boxWidth: 10,
              boxHeight: 10,
              boxPadding: 8,
              backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-background-color'),
              bodyColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              titleColor: getComputedStyle(document.documentElement).getPropertyValue('--tooltip-text-color'),
              cornerRadius: parseInt(getComputedStyle(document.documentElement).getPropertyValue('--tooltip-radius'), 10),
              itemSort: function (a, b) {
                return a.datasetIndex - b.datasetIndex;
              },
              callbacks: {
                title: function (context) {
                  if (context[0].chart.data.datasets?.length > 1 && self.showTooltipTitle)
                    return context[0].label.split(",")[0];
                  return '';
                },
                label: function (context) {
                  if (!self.labelDataAlldatasets)
                    return context.label.split(",");
                  else {
                    if (self.addAverageToData) {
                      let numEvents = 0;
                      self.chartData.forEach(x => {
                        numEvents += x.data[context.dataIndex];
                      });
                      return `${context.dataset.label} | ${context.formattedValue} (${parseFloat((Number(context.raw ?? 0) / numEvents * 100).toFixed(2))}%)`;
                    }
                    return `${context.dataset.label}: ${context.raw == null ? self.translate.instant('noInserted') : context.formattedValue}`;
                  }
                }
              },
            },
            legend: {
              display: false
            }
          }
        }
      }
    );
    this.finishLoad = true;
    this.cdRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.finishLoad)
      return;
    this.buildChartData(this.chartData);
    this.chart.data.labels = this.chartLabels;
    this.chart.data.datasets.forEach((x, index) => {
      x.data = this.data[index].data;
      if (this.data[index].backgroundColor != null) {
        x.backgroundColor = this.data[index].backgroundColor;
        x.hoverBackgroundColor = this.data[index].hoverBackgroundColor;
      }
    });
    this.chart.update();
  }

  legendClick(item) {
    this.chart.setDatasetVisibility(item.datasetIndex, !this.chart.isDatasetVisible(item.datasetIndex));
    this.chart.update();
  }

}
