// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `canvas{
  font-size: var(--font-size--default);
}

.chart-wrapper{
padding: 12px 16px;
}

.legend-container{
  padding-bottom: 0;
}

.custom-legend-item{
  font-family: var(--regularFont);
  font-weight: normal;
    font-size: var(--font-size--default);
    line-height: 2;
}

.custom-legend{
  text-align: left;
  flex-direction: column;
}

.line-through{
  text-decoration: line-through;
}


.custom-legend-item{
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--default);
  cursor: pointer;
  margin-left: var(--rightRtl_8);
  margin-right: var(--leftRtl_8);
}

.custom-legend{
  text-align: var(--floatingRight);
}

.color-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: var(--rightRtl_8);
  margin-right: var(--leftRtl_8);
  border: 2px solid;
  border-radius: 5px;
}

@media screen and (min-width: 960px){
  .space-between{
    justify-content: space-between;
  }
}

@media screen and (max-width: 959px){
  .space-between{
    flex-direction: column-reverse;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/charts/horizontalbar-chart/horizontalbar-chart.component.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;AACA,kBAAkB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;IACjB,oCAAoC;IACpC,cAAc;AAClB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;AAC/B;;;AAGA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,oCAAoC;EACpC,eAAe;EACf,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,8BAA8B;EAC9B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF","sourcesContent":["canvas{\r\n  font-size: var(--font-size--default);\r\n}\r\n\r\n.chart-wrapper{\r\npadding: 12px 16px;\r\n}\r\n\r\n.legend-container{\r\n  padding-bottom: 0;\r\n}\r\n\r\n.custom-legend-item{\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n    font-size: var(--font-size--default);\r\n    line-height: 2;\r\n}\r\n\r\n.custom-legend{\r\n  text-align: left;\r\n  flex-direction: column;\r\n}\r\n\r\n.line-through{\r\n  text-decoration: line-through;\r\n}\r\n\r\n\r\n.custom-legend-item{\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--default);\r\n  cursor: pointer;\r\n  margin-left: var(--rightRtl_8);\r\n  margin-right: var(--leftRtl_8);\r\n}\r\n\r\n.custom-legend{\r\n  text-align: var(--floatingRight);\r\n}\r\n\r\n.color-box {\r\n  display: inline-block;\r\n  width: 10px;\r\n  height: 10px;\r\n  margin-left: var(--rightRtl_8);\r\n  margin-right: var(--leftRtl_8);\r\n  border: 2px solid;\r\n  border-radius: 5px;\r\n}\r\n\r\n@media screen and (min-width: 960px){\r\n  .space-between{\r\n    justify-content: space-between;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 959px){\r\n  .space-between{\r\n    flex-direction: column-reverse;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
