import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/modules/material-module';
import { PipesModule } from 'src/app/modules/pipes.module';
import { UIModule } from 'src/app/modules/UI.module';
import { AvatarComponent } from './avatar.component';


@NgModule({
  declarations: [
    AvatarComponent
  ],
  imports:[
    UIModule,
    PipesModule,
    TranslateModule,
    MaterialModule,

  ],
  exports: [
    AvatarComponent
  ]

  }) export class AvatarModule { }
