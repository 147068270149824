// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
    font-family: var(--regularFont);
    font-weight: normal;
    line-height: 1.5;
    padding: 12px 24px;
    margin: 0;
}

.actions{
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/account/disconnected-alert/disconnected-alert.component.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,SAAS;AACb;;AAEA;IACI,SAAS;AACb","sourcesContent":[".content {\r\n    font-family: var(--regularFont);\r\n    font-weight: normal;\r\n    line-height: 1.5;\r\n    padding: 12px 24px;\r\n    margin: 0;\r\n}\r\n\r\n.actions{\r\n    margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
