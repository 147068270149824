// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-content{
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--small);
}

.mat-dialog-content span{
  font-size: var(--font-size--large);
  line-height: 1.5;
}
.dotHeight
{
  height: 42px;
}
.bodyForm > div {
  margin-top: var(--font-size--small);
margin-bottom: var(--font-size--small);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/dialogs/date-picker/date-picker.component.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;AAClB;AACA;;EAEE,YAAY;AACd;AACA;EACE,mCAAmC;AACrC,sCAAsC;AACtC","sourcesContent":[".mat-dialog-content{\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--small);\r\n}\r\n\r\n.mat-dialog-content span{\r\n  font-size: var(--font-size--large);\r\n  line-height: 1.5;\r\n}\r\n.dotHeight\r\n{\r\n  height: 42px;\r\n}\r\n.bodyForm > div {\r\n  margin-top: var(--font-size--small);\r\nmargin-bottom: var(--font-size--small);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
