// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fab-container {
    position: absolute;
    left: var(--rightRtl_16Null);
    bottom: 16px;
    right: var(--leftRtl_16Null);
    flex-direction: column-reverse;
    align-items: center;
}

::ng-deep .fab-container app-fab-icon:first-child:not(:last-child) {
    margin-top: 24px;
}

::ng-deep .fab-container app-fab-icon .mini-fab:not(:last-child) {
    margin-top: 16px;
}

@keyframes hideBtn {
    from  {
        transform: scale(1);
    }

    to {
        transform: scale(0);
    }
}

::ng-deep .fab-container.mini-fab-hidden .mini-fab.fab-icon-btn {
    animation: hideBtn 0.2s forwards;
    transform-origin: left bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/fab-button-container/fab-button-container.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,YAAY;IACZ,4BAA4B;IAC5B,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAYA;IACI;QACI,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;IACvB;AACJ;;AAEA;IAII,gCAAgC;IAChC,6BAA6B;AACjC","sourcesContent":[".fab-container {\r\n    position: absolute;\r\n    left: var(--rightRtl_16Null);\r\n    bottom: 16px;\r\n    right: var(--leftRtl_16Null);\r\n    flex-direction: column-reverse;\r\n    align-items: center;\r\n}\r\n\r\n::ng-deep .fab-container app-fab-icon:first-child:not(:last-child) {\r\n    margin-top: 24px;\r\n}\r\n\r\n::ng-deep .fab-container app-fab-icon .mini-fab:not(:last-child) {\r\n    margin-top: 16px;\r\n}\r\n\r\n@-webkit-keyframes hideBtn {\r\n    from  {\r\n        transform: scale(1);\r\n    }\r\n\r\n    to {\r\n        transform: scale(0);\r\n    }\r\n}\r\n\r\n@keyframes hideBtn {\r\n    from  {\r\n        transform: scale(1);\r\n    }\r\n\r\n    to {\r\n        transform: scale(0);\r\n    }\r\n}\r\n\r\n::ng-deep .fab-container.mini-fab-hidden .mini-fab.fab-icon-btn {\r\n    -moz-animation: hideBtn 0.2s forwards;\r\n    -webkit-animation: hideBtn 0.2s forwards;\r\n    -o-animation: hideBtn 0.2s forwards;\r\n    animation: hideBtn 0.2s forwards;\r\n    transform-origin: left bottom;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
