// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tool-bar {
  padding: 12px 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--card-background-color);
  font-family: var(--boldFont);
  font-weight: bold;
  font-size: var(--font-size--large);
}

::ng-deep .tool-bar .flex {
  justify-content: space-between;
  width: 100%;
}

@media screen and (max-width: 959px) {
  .tool-bar {
    font-size: var(--font-size--default);
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/pageStructure/tool-bar/tool-bar.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,MAAM;EACN,UAAU;EACV,8CAA8C;EAC9C,4BAA4B;EAC5B,iBAAiB;EACjB,kCAAkC;AACpC;;AAEA;EAGE,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE;IACE,oCAAoC;IAKpC,wBAAwB;IACxB,8BAA8B;IAC9B,mBAAmB;EACrB;AACF","sourcesContent":[".tool-bar {\r\n  padding: 12px 16px;\r\n  position: -webkit-sticky;\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 1;\r\n  background-color: var(--card-background-color);\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n  font-size: var(--font-size--large);\r\n}\r\n\r\n::ng-deep .tool-bar .flex {\r\n  -webkit-justify-content: space-between;\r\n  -ms-flex-pack: justify;\r\n  justify-content: space-between;\r\n  width: 100%;\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  .tool-bar {\r\n    font-size: var(--font-size--default);\r\n    display: -webkit-box !important;\r\n    display: -moz-box !important;\r\n    display: -ms-flexbox !important;\r\n    display: -webkit-flex !important;\r\n    display: flex !important;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
