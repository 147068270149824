import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../../snack-bar/snack-bar.component';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  datePickerControl: UntypedFormControl;
  hourControl: UntypedFormControl = new UntypedFormControl();
  minuteControl: UntypedFormControl = new UntypedFormControl();
  showHour = false;
  hours = new Array();
  minutes = new Array();

  constructor(
    private dialogRef: MatDialogRef<DatePickerComponent>,
    private snackBar: MatSnackBar ,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { selectedDate: Date, SelectHour: boolean, control: UntypedFormControl , checkDate: boolean},
  ) {
      this.dialogRef.addPanelClass('confirm-modal');
      if (data?.control !== undefined && data?.control !== null) {
        this.datePickerControl = data.control;
      } else {
        this.datePickerControl = new UntypedFormControl();
      }

      if (data?.selectedDate !== undefined && data?.selectedDate !== null) {
        this.datePickerControl.setValue(data.selectedDate);
        this.hourControl.setValue(new Date(data.selectedDate).getHours());
        this.minuteControl.setValue(new Date(data.selectedDate).getMinutes());
      } else {
        this.datePickerControl.setValue(new Date());
      }
      this.fillArrays();
      if (data?.SelectHour !== undefined && data?.SelectHour !== null) {
        this.showHour = data.SelectHour;

      }
  }
  fillArrays() {
    for (let index = 0; index <= 23; index++) {
      this.hours.push({'text': this.getValue(index) , 'value' : this.getValue(index)});
    }
    for (let index = 0; index <= 59; index++) {
      this.minutes.push({'text': this.getValue(index) , 'value' : this.getValue(index)});
    }
  }

  getValue( x: number): string {
    if (x < 10 ) {
      return `0${x}`;
    }
    return `${x}`;
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    const date  =  new Date();
    date.setTime(new Date(this.datePickerControl.value).setHours((this.hourControl.value), (this.minuteControl.value), 0 , 0));
    if (this.data.checkDate && date.getTime() < (new Date().getTime())) {
      this.snackBar.openFromComponent(SnackBarComponent, {
        duration: 3000,
        data: { message: this.translate.instant('SchedualMessageDatePassed'), type: 'warning' }
      });
          return;
    }
    this.dialogRef.close(date);
  }

  ngOnInit(): void {
  }
}
