// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
  margin-bottom: 16px;
  position: relative;
  padding: 8px;
  border: 1px solid transparent;
  font-family: var(--boldFont);
  font-weight: bold;
}

.alert-success {
  color: var(--alert-success-color);
  background-color: var(--alert-success-background);
  border-color: var(--alert-success-border);
  border-radius: 4px;
}

.alert-content {
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

::ng-deep .alert-content > *:not(div) {
  display: inline;
}

::ng-deep .alert-success a {
  color: var(--alert-success-color);
  text-decoration: underline !important;
}

.alert-content.show-more {
  -webkit-line-clamp: unset;
}

@media screen and (max-width: 959px) {
  .alert.mobile-margin {
    margin: 8px 16px 16px 16px;
  }
}
.hide {
  display: none;
}

.showMorePos {
  position: absolute;
  top: 28px;
  left: var(--rightRtl_10Null);
  right: var(--leftRtl_10Null);
  z-index: 20;
  background-color: var(--alert-success-background);
  color: var(--alert-success-color);
}`, "",{"version":3,"sources":["webpack://./src/app/common/alerts/alert-sucecess/alert-sucecess.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;EACA,4BAAA;EACA,iBAAA;AACF;;AAEA;EACE,iCAAA;EACA,iDAAA;EACA,yCAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,oBAAA;EACA,qBAAA;EACA,gBAAA;EACA,4BAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,iCAAA;EACA,qCAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE;IACE,0BAAA;EACF;AACF;AAIA;EACE,aAAA;AAFF;;AAIA;EACE,kBAAA;EACA,SAAA;EACA,4BAAA;EACA,4BAAA;EACA,WAAA;EACA,iDAAA;EACA,iCAAA;AADF","sourcesContent":[".alert {\r\n  margin-bottom: 16px;\r\n  position: relative;\r\n  padding: 8px;\r\n  border: 1px solid transparent;\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n}\r\n\r\n.alert-success {\r\n  color: var(--alert-success-color);\r\n  background-color: var(--alert-success-background);\r\n  border-color: var(--alert-success-border);\r\n  border-radius: 4px;\r\n}\r\n\r\n.alert-content {\r\n  position: relative;\r\n  display: -webkit-box;\r\n  -webkit-line-clamp: 2;\r\n  overflow: hidden;\r\n  -webkit-box-orient: vertical;\r\n}\r\n\r\n::ng-deep .alert-content > *:not(div){\r\n  display: inline;\r\n}\r\n\r\n::ng-deep .alert-success a{\r\n  color: var(--alert-success-color);\r\n  text-decoration: underline !important;\r\n}\r\n\r\n.alert-content.show-more{\r\n  -webkit-line-clamp: unset;\r\n}\r\n\r\n@media screen and (max-width: 959px){\r\n  .alert.mobile-margin{\r\n    margin: 8px 16px 16px 16px;\r\n\r\n  }\r\n}\r\n\r\n\r\n.hide {\r\n  display: none;;\r\n}\r\n.showMorePos {\r\n  position: absolute;\r\n  top: 28px;\r\n  left: var(--rightRtl_10Null);\r\n  right: var(--leftRtl_10Null);\r\n  z-index: 20;\r\n  background-color: var(--alert-success-background);\r\n  color: var(--alert-success-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
