import { SharedService } from 'src/app/_services/shared.service';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icons-list',
  templateUrl: './icons-list.component.html',
  styleUrls: ['./icons-list.component.css']
})
export class IconsListComponent implements OnInit {

  @Input() icon: any;
  constructor( public shard: SharedService) {

   }


  ngOnInit() {

  }


}


