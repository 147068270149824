import { SharedService } from 'src/app/_services/shared.service';
import { HebrewDatePipe } from '../../../../_pipes/hebrewDate.pipe';
import { NumberToDayNamePipe } from '../../../../_pipes/number-to-day-name.pipe';
import { NumToMonthPipe } from '../../../../_pipes/numToMonth.pipe';
import { TranslateService } from '@ngx-translate/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, SimpleChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatCalendarUserEvent, MatDatepicker } from '@angular/material/datepicker';

import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs-compat';
import { UserService } from 'src/app/_services/user-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-datepicker-header',
  templateUrl: './datepicker-header.component.html',
  styleUrls: ['./datepicker-header.component.scss', '../../inputs.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    NumberToDayNamePipe,
    NumToMonthPipe
  ]
})
export class DatepickerHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  private isHebrewDate: boolean;
  private disabledToday: boolean;

  constructor(
    private _datePicker: MatDatepicker<D>,
    public _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private ntd: NumberToDayNamePipe,
    private ntm: NumToMonthPipe,
    private hebDate: HebrewDatePipe,
    public shared: SharedService,
    private user: UserService
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
    this.isHebrewDate = this.shared.isHebrewDate();
    this.user.getSelectedStudyYear().pipe(take(1)).subscribe(value => {
      this.disabledToday = environment.currentYear != value;
    });
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public get YearLabel() {
    let hebrewText = '';
    if (this.isHebrewDate) {
      const hebDate = this.hebDate.transform(this._calendar.activeDate).split(' ');
      this.checkIfHas2Adar(hebDate);
      hebrewText = ' - ' + hebDate[2];
    }
    return new Date(this._calendar.activeDate.toString()).getFullYear() + hebrewText;
  }

  checkIfHas2Adar(hebDate: string[]) {
    if (hebDate.length == 4) {
      hebDate[1] += ' ' + hebDate[2];
      hebDate[2] = hebDate[3];
    }
  }

  get MonthYearLabel() {
    const currentDate: Date = new Date(this._calendar.activeDate.toString());
    if (this._calendar.currentView === 'month') {
      return `${this.ntm.transform(currentDate.getMonth() + 1)} ${currentDate.getFullYear()}`;
    }
    return currentDate.getFullYear();
  }

  get selectedDate() {
    const selectedDate: Date = new Date(this._calendar.activeDate.toString());
    let hebrewText = '';
    if (this.isHebrewDate) {
      if (this._calendar.currentView === 'month') {
        const hebrewDate = this.hebDate.transform(selectedDate).split(' ')
        this.checkIfHas2Adar(hebrewDate);
        hebrewText = ` - ${hebrewDate[0]} ${hebrewDate[1]}`;
      } else {
        const firstHebrewDate = this.hebDate.transform(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)).split(' ');
        this.checkIfHas2Adar(firstHebrewDate);
        const lastHebrewDate = this.hebDate.transform(new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0)).split(' ');
        this.checkIfHas2Adar(lastHebrewDate);
        hebrewText = `, ${firstHebrewDate[1]} - ${lastHebrewDate[1]}`;
      }
    }
    if (this._calendar.currentView === 'month') {

      return this.shared.isRtl() ?
        `${this.ntd.transform(selectedDate.getDay() + 1)}, ${selectedDate.getDate()} ${this.translate.instant('In')}${this.ntm.transform(selectedDate.getMonth() + 1)}${hebrewText}` :
        `${this.ntd.transform(selectedDate.getDay() + 1)}, ${this.ntm.transform(selectedDate.getMonth() + 1)} ${selectedDate.getDate()}`;
    }
    return this.ntm.transform(selectedDate.getMonth() + 1) + hebrewText;
  }

  get TodayTxt() {
    return this.translate.instant('Today');
  }

  monthlyView(): void {
    this._calendar.currentView = 'year';
  }

  goToToday(): void {
    this._calendar.activeDate =
      this._dateAdapter.today();
    this._calendar._dateSelected(this._calendar.activeDate as any as MatCalendarUserEvent<D>);
    this._datePicker.select(this._dateAdapter.today());
    this._datePicker.close();
  }

  previousClicked() {
    this._calendar.activeDate =
      this._calendar.currentView === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked() {
    this._calendar.activeDate =
      this._calendar.currentView === 'month' ?
        this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1) :
        this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}

