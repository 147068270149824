// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: var(--rightRtl_8);
  margin-right: var(--leftRtl_8);
  display: inline-block;
  vertical-align: middle;
}

.color-name {
  font-family: var(--regularFont) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/color-data/color-data.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,8BAAA;EACA,8BAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE,0CAAA;AACF","sourcesContent":[".color-indicator{\r\n  width: 18px;\r\n  height: 18px;\r\n  border-radius: 50%;\r\n  margin-left: var(--rightRtl_8);\r\n  margin-right: var(--leftRtl_8);\r\n  display: inline-block;\r\n  vertical-align: middle;\r\n}\r\n\r\n.color-name{\r\n  font-family: var(--regularFont) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
