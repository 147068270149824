import { SelectedGradeHeader } from './../_models/grades/SelectedGradeHeader';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GradesTranslation, PeriodFilter, StudentGradeData } from '../_models/grades/studentGrades/studentGradesClass';

@Injectable({
  providedIn: 'root'
})
export class GradesService {


  private selectedHeader = new BehaviorSubject<SelectedGradeHeader>(null);
  private studentGrades = new BehaviorSubject<StudentGradeData>(null);
  private periodFilter = new BehaviorSubject<PeriodFilter>(null);
  private icons = new BehaviorSubject<any[]>([]);
  private filterGroups = new BehaviorSubject<string>('');
  private studentsData = new BehaviorSubject<any[]>(null);
  private tabs = new BehaviorSubject<any[]>([]);

  private footerClick = new Subject<any>();
  private tabValueChange = new Subject<string>();
  private headerClick = new Subject<any>();
  private mainClickSource = new Subject<any>();
  private isEditMode = new Subject<boolean>();
  private numSelectd = new Subject<any>();
  private showSaveButton = new Subject<boolean>();
  private studentInfo = new Subject<any>();
  private studentOpenInfo = new Subject<any>();

  public selectedStudyGroupName: string;
  private selectedDivisionName: string;
  private currentHeader: SelectedGradeHeader;

  mainClickSourceEmitted = this.mainClickSource.asObservable();

  setStudentsData(studentes: any[]) {
    this.studentsData.next(studentes);
  }

  getStudentsData() {
    return this.studentsData.asObservable();
  }

  getStudentGradesData() {
    return this.studentGrades.asObservable();
  }

  setStudentGradesData(data: StudentGradeData) {
    this.studentGrades.next(data);
  }

  getFilterGroups() {
    return this.filterGroups.asObservable();
  }

  setFilterGroups(data: string) {
    this.filterGroups.next(data);
  }

  getPeriodFilter() {
    return this.periodFilter.asObservable();
  }

  setPeriodFilter(data: PeriodFilter) {
    this.periodFilter.next(data);
  }

  setTabValue($event: any) {
    this.tabValueChange.next($event);
  }

  getTabValue() {
    return this.tabValueChange.asObservable();
  }

  getGradesIcons() {
    return this.icons.asObservable();
  }

  setGradesIcons(data: any[]) {
    this.icons.next(data);
  }


  getTabs() {
    return this.tabs.asObservable();
  }

  setTabs(data: any[]) {
    this.tabs.next(data);
  }


  mainClick(change: any) {
    this.mainClickSource.next(change);
  }

  public  getSelectedGradeHeader() {
    return this.selectedHeader.asObservable();
  }

  public  getCurrentSelectedGradeHeader() {
    return this.currentHeader;
  }

  public updateSelectedGradeHeader(data: SelectedGradeHeader) {
    this.selectedHeader.next(data);
    if(data?.selectedStudyGroup != null) {
      this.currentHeader = data;
      this.selectedStudyGroupName= data.data.gradesStudyGroup.filter(x=>x.id == data.selectedStudyGroup)[0]?.groupName;
      this.selectedDivisionName= data.data.division.filter(x=>x.id == data.selectedDivision)[0]?.name;
    }
  }

  getNumSelected(){
    return this.numSelectd.asObservable();
  }

  setNumSelected(value: any){
    this.numSelectd.next(value);
  }

  getIsEditMode() {
    return this.isEditMode.asObservable();
  }

  setIsEditMode(value:boolean) {
    this.isEditMode.next(value);
  }

  getShowSaveButton() {
    return this.showSaveButton.asObservable();
  }

  setShowSaveButton(value:boolean) {
    this.showSaveButton.next(value);
  }

  getStudentInfo() {
    return this.studentInfo.asObservable();
  }

  getStudentOpenInfo() {
    return this.studentOpenInfo.asObservable();
  }

  setStudentInfo(value:any) {
    this.studentInfo.next(value);
  }

  setStudentOpenInfo(value:any) {
    this.studentOpenInfo.next(value);
  }


  getFooterClick() {
    return this.footerClick.asObservable();
  }

  setFooterClick(value:any) {
    this.footerClick.next(value);
  }

  public  getHeaderClick() {
    return this.headerClick.asObservable();
  }

  public setHeaderClick(value:any) {
    this.headerClick.next(value);
  }

  getGradeText(gradesTranslation: GradesTranslation[], grade:number) {
    return gradesTranslation?.filter(x=>x.from <= grade && x.to >= grade)[0]?.value;
  }

  getSelectedStudyGroupName() {
    return this.selectedStudyGroupName;
  }

  getSelectedSDivisionName() {
    return this.selectedDivisionName;
  }





}
