// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-details app-avatar{
  margin: auto 0;

  margin-left: var(--rightRtl_12);
  margin-right: var(--leftRtl_12);
}

.user-details .user-text{
  margin: auto 0;
}

.user-cellphone{
  align-items: baseline;
  direction: rtl !important;
}

.code-input > .font-large.bold-text{
  line-height: 3;
}

.code-input > .font-default{
  line-height: 2.5;
}

.code-input-wrapper{
  width: 80%;
  margin: 0 auto;
}

@media screen and (min-width: 960px){
  .settings-footer app-button:not(:last-child){
    margin-left: var(--rightRtl_4Null);
    margin-right: var(--leftRtl_4Null);
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/settings/profile/profile.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,+BAA+B;EAC/B,+BAA+B;AACjC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE;IACE,kCAAkC;IAClC,kCAAkC;EACpC;AACF","sourcesContent":[".user-details app-avatar{\r\n  margin: auto 0;\r\n\r\n  margin-left: var(--rightRtl_12);\r\n  margin-right: var(--leftRtl_12);\r\n}\r\n\r\n.user-details .user-text{\r\n  margin: auto 0;\r\n}\r\n\r\n.user-cellphone{\r\n  align-items: baseline;\r\n  direction: rtl !important;\r\n}\r\n\r\n.code-input > .font-large.bold-text{\r\n  line-height: 3;\r\n}\r\n\r\n.code-input > .font-default{\r\n  line-height: 2.5;\r\n}\r\n\r\n.code-input-wrapper{\r\n  width: 80%;\r\n  margin: 0 auto;\r\n}\r\n\r\n@media screen and (min-width: 960px){\r\n  .settings-footer app-button:not(:last-child){\r\n    margin-left: var(--rightRtl_4Null);\r\n    margin-right: var(--leftRtl_4Null);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
