import { Component, OnInit, Input, ViewChild, AfterViewInit, ContentChildren, Output, EventEmitter } from '@angular/core';
import { ScrollToTopComponent } from '../../UIControls/scroll-to-top/scroll-to-top.component';


@Component({
  selector: 'app-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.css']
})
export class ContentCardComponent implements OnInit {

  @Input() public cardTitle: string;
  @Input() public showTitleSelector: boolean = false;
  @Input() public cardTitleLeftText: string = "";
  @Input() public cardTitleLeftTextClick: string = "";
  @Input() public showIcons = false;
  @Input() public showIconRight = false;
  @Input() public fullHeight = false;
  @Input() public margin_0 = false;
  @Input() public hideShadow = false;
  @Input() public stickyTitle = true;
  @Input() public cssClass:string = "";
  @Input() public schowScrollTop: boolean = true;
  @Input() public cssTitle: string = "";
  @Input() public classTitle: string = "";
  @Input() public cssPositionTitle: string = "";
  @Input() public showFooter: boolean = false;
  @Output() cardTitleLeftTextClicked = new EventEmitter();


  @ViewChild("refEl") contentContainer;
  @ViewChild(ScrollToTopComponent) scrollTopBtn: ScrollToTopComponent;

  constructor() { }

  ngOnInit(): void {
  }

  textClick() {
    this.cardTitleLeftTextClicked.emit();
  }

}
