// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .inline-search .mat-form-field-infix {
  display: flex;
}

.inline-search {
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--large);
}

::ng-deep .inline-search .mat-form-field-infix {
  padding: 0;
  border: none;
}

::ng-deep .inline-search .mat-form-field-wrapper {
  width: 100%;
}

::ng-deep .mat-form-field-appearance-fill.inline-search .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 12px !important;
}

.search-icon {
  margin-left: var(--rightRtl_8);
  margin-right: var(--leftRtl_8);
}

.close-icon {
  margin-right: var(--rightRtl_8);
  margin-left: var(--leftRtl_8);
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/inline-search/inline-search.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,+BAAA;EACA,mBAAA;EACA,kCAAA;AACF;;AAEA;EACE,UAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,0BAAA;EACA,wBAAA;AACF;;AAEA;EACE,8BAAA;EACA,8BAAA;AACF;;AAEA;EACE,+BAAA;EACA,6BAAA;AACF","sourcesContent":["::ng-deep .inline-search .mat-form-field-infix{\r\n  display: flex;\r\n}\r\n\r\n.inline-search {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--large);\r\n}\r\n\r\n::ng-deep .inline-search .mat-form-field-infix{\r\n  padding: 0;\r\n  border: none;\r\n}\r\n\r\n::ng-deep .inline-search .mat-form-field-wrapper{\r\n  width: 100%;\r\n}\r\n\r\n::ng-deep .mat-form-field-appearance-fill.inline-search .mat-form-field-flex {\r\n  border-radius: 4px 4px 0 0;\r\n  padding: 12px !important;\r\n}\r\n\r\n.search-icon{\r\n  margin-left: var(--rightRtl_8);\r\n  margin-right: var(--leftRtl_8);\r\n}\r\n\r\n.close-icon{\r\n  margin-right: var(--rightRtl_8);\r\n  margin-left: var(--leftRtl_8);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
