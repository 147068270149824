// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sort-dialog {
  background-color: var(--bottom-sheet-container-background-color);
  color: var(--bottom-sheet-text-color);
}

.content {
  font-family: var(--regularFont);
  font-weight: normal;
  padding: 0;
}

.content .title {
  padding: 16px;
  font-size: var(--font-size--small);
  color: var(--bottom-sheet-title-text-color);
  background-color: var(--bottom-sheet-container-background-color);
}

.content .title span {
  font-family: var(--boldFont);
  font-weight: bold;
}

.mat-list-item {
  font-family: var(--regularFont);
  font-weight: normal;
  font-size: var(--font-size--small);
  min-height: 46px;
  color: var(--option-text-color);
}

.mat-list-item:focus {
  background-color: var(--option-focus-state-color);
}

.action {
  color: var(--app-body-link-text-color);
}

.margin-16-left.lead-icon {
  margin-left: var(--rightRtl_16);
  margin-right: var(--leftRtl_16);
  fill: var(--option-lead-icon-color);
}

.trailing-icon.mat-icon {
  fill: var(--option-trailing-icon-color);
}`, "",{"version":3,"sources":["webpack://./src/app/common/dialogs/sort-dialog/sort-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,gEAAgE;EAChE,qCAAqC;AACvC;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,2CAA2C;EAC3C,gEAAgE;AAClE;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,kCAAkC;EAClC,gBAAgB;EAChB,+BAA+B;AACjC;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,+BAA+B;EAC/B,+BAA+B;EAC/B,mCAAmC;AACrC;;AAEA;EACE,uCAAuC;AACzC","sourcesContent":[".sort-dialog {\r\n  background-color: var(--bottom-sheet-container-background-color);\r\n  color: var(--bottom-sheet-text-color);\r\n}\r\n\r\n.content {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  padding: 0;\r\n}\r\n\r\n.content .title {\r\n  padding: 16px;\r\n  font-size: var(--font-size--small);\r\n  color: var(--bottom-sheet-title-text-color);\r\n  background-color: var(--bottom-sheet-container-background-color);\r\n}\r\n\r\n.content .title span {\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n}\r\n\r\n.mat-list-item {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  font-size: var(--font-size--small);\r\n  min-height: 46px;\r\n  color: var(--option-text-color);\r\n}\r\n\r\n.mat-list-item:focus {\r\n  background-color: var(--option-focus-state-color);\r\n}\r\n\r\n.action {\r\n  color: var(--app-body-link-text-color);\r\n}\r\n\r\n.margin-16-left.lead-icon {\r\n  margin-left: var(--rightRtl_16);\r\n  margin-right: var(--leftRtl_16);\r\n  fill: var(--option-lead-icon-color);\r\n}\r\n\r\n.trailing-icon.mat-icon {\r\n  fill: var(--option-trailing-icon-color);\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
