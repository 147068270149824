export class SelectItem {
  value: string;
  viewValue: string;
  extraData?: any;
}
export class SelectGroup {
  disabled?: boolean;
  name: string;
  items: SelectItem[] = [];

  constructor(name: string = '') {
    this.name = name;
  }
}
export class SearchArray {
  id: any;
  selectedData: string;
  displayData: string;
}

export class StringRequest {
  id: string;
}
export class NumberRequest {
  id: number;
  constructor(id: number) {
    this.id = id;
  }
}
export class GenericMessage {
  message: string;
  data: any;
  messageObjects: any[] = [];
}
export class CheckBoxGroup {
  constructor(name?: string, value?: string, completed: boolean = false, item?: any) {
    this.name = name;
    this.value = value;
    this.completed = completed;
    this.item = item;
    this.subtasks = [];

  }

  name: string;
  item: any;
  value: string;
  completed: boolean;
  allComplete: boolean;
  showGroupHeader = true;
  subtasks?: CheckBoxGroup[];
  isOpenText = false;
}
