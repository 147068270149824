// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
  margin:0;
  padding: 24px;
  min-height: 52px;
  font-size: var(--font-size--small);
  font-family: var(--boldFont);
  font-weight: bold;
  line-height: 1.5;
  border-color: var(--confirm-dialog-header-border-color);
  background-color: var(--confirm-dialog-dialog-header-background-color);
  color: var(--confirm-dialog-dialog-header-title-color);
}
* {
  font-family: var(--regularFont);
  font-weight: normal;
}

.body {
  padding: 24px;
  margin: 0;
  color: var(--confirm-dialog-container-text-color);
}
.modal-footer-bar {
  margin: 0;
  padding: 8px;
  min-height: 40px;
  height: 40px;
  border-top: 1px solid var(--confirm-dialog-footer-border-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/common/dialogs/message-box-dialog/message-box-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,aAAa;EACb,gBAAgB;EAChB,kCAAkC;EAClC,4BAA4B;EAC5B,iBAAiB;EACjB,gBAAgB;EAChB,uDAAuD;EACvD,sEAAsE;EACtE,sDAAsD;AACxD;AACA;EACE,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,iDAAiD;AACnD;AACA;EACE,SAAS;EACT,YAAY;EACZ,gBAAgB;EAChB,YAAY;EACZ,+DAA+D;AACjE","sourcesContent":[".modal-header {\r\n  margin:0;\r\n  padding: 24px;\r\n  min-height: 52px;\r\n  font-size: var(--font-size--small);\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n  line-height: 1.5;\r\n  border-color: var(--confirm-dialog-header-border-color);\r\n  background-color: var(--confirm-dialog-dialog-header-background-color);\r\n  color: var(--confirm-dialog-dialog-header-title-color);\r\n}\r\n* {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n}\r\n\r\n.body {\r\n  padding: 24px;\r\n  margin: 0;\r\n  color: var(--confirm-dialog-container-text-color);\r\n}\r\n.modal-footer-bar {\r\n  margin: 0;\r\n  padding: 8px;\r\n  min-height: 40px;\r\n  height: 40px;\r\n  border-top: 1px solid var(--confirm-dialog-footer-border-color);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
