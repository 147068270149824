// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-tab-labels .mat-tab-label {
  font-family: var(--regularFont);
  font-weight: normal;
  opacity: 1;
  min-width: 90px;
  font-size: var(--font-size--default);
  padding: 0 16px;
  color: var(--tab-inactive-text-color);
}

.mat-tab-label.mat-tab-disabled{
  opacity: 1;
  color: var(--tab-disabled-text-color);
}

.mat-tab-labels .mat-tab-label.mat-tab-label-active {
  font-family: var(--boldFont);
  font-weight: bold;
  color: var(--tab-active-text-color);
}

.full-width .mat-tab-labels .mat-tab-label {
  flex: 1;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background-color: var(--tab-active-indicator-color);
}

.mat-tab-labels .mat-tab-label:focus{
  background-color: var(--tab-focus-background-color);
}

.mat-tab-labels .mat-tab-label:hover{
  background-color: var(--tab-hover-background-color);
}

@media screen and (max-width: 959px) {
  .tabs-container {
    max-width: 959px;
  }

  .mat-tab-labels .mat-tab-label {
    flex: 1;
  }

  .mat-tab-label-container {
    width: 100%;
    overflow: auto !important;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .mat-tab-label-container::-webkit-scrollbar {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/tabs/tabs.component.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,oCAAoC;EACpC,eAAe;EACf,qCAAqC;AACvC;;AAEA;EACE,UAAU;EACV,qCAAqC;AACvC;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,mCAAmC;AACrC;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE,mDAAmD;AACrD;;AAEA;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,OAAO;EACT;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,wBAAwB;IACxB,qBAAqB;EACvB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".mat-tab-labels .mat-tab-label {\r\n  font-family: var(--regularFont);\r\n  font-weight: normal;\r\n  opacity: 1;\r\n  min-width: 90px;\r\n  font-size: var(--font-size--default);\r\n  padding: 0 16px;\r\n  color: var(--tab-inactive-text-color);\r\n}\r\n\r\n.mat-tab-label.mat-tab-disabled{\r\n  opacity: 1;\r\n  color: var(--tab-disabled-text-color);\r\n}\r\n\r\n.mat-tab-labels .mat-tab-label.mat-tab-label-active {\r\n  font-family: var(--boldFont);\r\n  font-weight: bold;\r\n  color: var(--tab-active-text-color);\r\n}\r\n\r\n.full-width .mat-tab-labels .mat-tab-label {\r\n  flex: 1;\r\n}\r\n\r\n.mat-tab-group.mat-primary .mat-ink-bar {\r\n  background-color: var(--tab-active-indicator-color);\r\n}\r\n\r\n.mat-tab-labels .mat-tab-label:focus{\r\n  background-color: var(--tab-focus-background-color);\r\n}\r\n\r\n.mat-tab-labels .mat-tab-label:hover{\r\n  background-color: var(--tab-hover-background-color);\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  .tabs-container {\r\n    max-width: 959px;\r\n  }\r\n\r\n  .mat-tab-labels .mat-tab-label {\r\n    flex: 1;\r\n  }\r\n\r\n  .mat-tab-label-container {\r\n    width: 100%;\r\n    overflow: auto !important;\r\n    -ms-overflow-style: none;\r\n    scrollbar-width: none;\r\n  }\r\n\r\n  .mat-tab-label-container::-webkit-scrollbar {\r\n    display: none;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
