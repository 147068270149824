import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'average'
})
export class AveragePipe implements PipeTransform {
  constructor() { }


  transform(items: any[], attribute: string): number {
    let itemsToCalculate = items.filter(x=>x[attribute] != null);
    let sum = itemsToCalculate.reduce((a, b) => a + b[attribute], 0);
    return sum / itemsToCalculate.length;
  }

}
