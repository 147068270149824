import { SharedService } from 'src/app/_services/shared.service';
import { baseResult } from 'src/app/_models/baseResult';
import { take } from 'rxjs/operators';
import { GetUserViaSearches, MailingGroupsRecipients } from './../_models/messageBox/Recipients';
import { PostService } from 'src/app/_services/postService';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChipItem } from '../_models/chip';


@Injectable({
  providedIn: 'root'
})
export class messegeBoxService {


  private msgId = new BehaviorSubject<string>('');

  constructor(
    private postSrv: PostService,
    private shared: SharedService,
    private translate: TranslateService,


  ) { }

  updateMsgId(id: string) {
    this.msgId.next(id);
  }

  getMsgId() {
    return this.msgId.asObservable();
  }


  getMailingGroupRecipients(mailingGroupId): Observable<any[]> {
    var subject = new Subject<any[]>();
    this.postSrv.postData<MailingGroupsRecipients[]>('api/messageBox/GetMailingGroupsRecipients',
      true,
      {
        'Id': mailingGroupId,
      }).pipe(take(1))
      .subscribe((result: baseResult<MailingGroupsRecipients[]>) => {
        if (result.status) {
          subject.next(result.data);
        }
      });
    return subject.asObservable();
  }


  getItem(x: GetUserViaSearches): ChipItem {
    if (x.item != null && this.shared.isEmpty(x.item.title)) {
      x.item.title = null
    }
    switch (x.mailType) {
      case 'StudyGroup':
      case 'MailingGroup':
        return new ChipItem(x.item === undefined ? x.lastName : x.item?.title, '', x.itemId, '', x.mailType, '', x.item?.avatarId);
      case 'ParentClass':
        return new ChipItem(x.item === undefined ? x.lastName : `${this.shared.getClassTitle(x.item.class_Num, true)} ${x.item.title ?? this.shared.getClassName(x.item.class_Code, x.item.class_Num)}`,
          '', x.itemId, '', x.mailType, '', x.item?.avatarId ?? x.itemId);
      case 'PupilClass':
        return new ChipItem(x.item === undefined ? x.lastName : `${this.shared.getClassTitle(x.item.class_Num, false)} ${x.item.title ?? this.shared.getClassName(x.item.class_Code, x.item.class_Num)}`,
          '', x.itemId, '', x.mailType, '', x.item?.avatarId ?? x.itemId);
      case 'parentsStudyGroup':
        return new ChipItem(`${this.translate.instant('parentsOfstudyGroups')} ${x.firstName}`, '', x.itemId, '', x.mailType, '', x.item?.avatarId);
      case 'classCounselor':
      case 'classCoordinator':
      case 'homeroomTeacher':
      case 'professionTeachers':
        return new ChipItem(`${this.translate.instant(x.mailType + 'Of')} ${x.lastName} ${x.firstName}`, '', x.itemId, '', x.mailType, '', x.item?.avatarId);
      default:
        return new ChipItem(x.lastName !== undefined && x.lastName !== null ? x.lastName : '', x.firstName, x.itemId, this.translate.instant(x.mailType), '', x.item?.userImageToken ?? x.userImageToken, x.item?.avatarId);
    }

  }


}
