import { VerticalbarChartComponent } from './verticalbar-chart/verticalbar-chart.component';
import { HorizontalbarChartComponent } from './horizontalbar-chart/horizontalbar-chart.component';
import { NgModule } from "@angular/core";
import { SharedModule } from 'src/app/shared.module';
import { DoughtChartComponent } from './dought-chart/dought-chart.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    DoughtChartComponent,
    HorizontalbarChartComponent,
    VerticalbarChartComponent
  ],
  exports: [
    DoughtChartComponent,
    HorizontalbarChartComponent,
    VerticalbarChartComponent
  ]
})
export class ChartModule {
}
