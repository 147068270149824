import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

constructor() { }
private guid: string;
public pageName: string;
private _allowToChangePassword = false;
private OldPassword = '';

setGuid (guid: string) {
  this.guid = guid;
}
getGuid () {
  return this.guid;
}
getAllowToChangePassword(): boolean {
  return this._allowToChangePassword;
}
setAllowToChangePassword(flag = true, oldPassword = '') {
  this._allowToChangePassword = flag;
  this.OldPassword = oldPassword;
}

getOldPassword(): string {
  return this.OldPassword;
}


}
