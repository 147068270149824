import { DashboardModule } from './dashboard/dashboard.module';
import { CustomPreloadingStrategyService } from './_services/CustomPreloadingStrategyService';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const webtopModule = () => import('./app-nav/webtop.module').then(x => x.WebtopModule);
const dashboardModule = () => import('./dashboard/dashboard.module').then(x => x.DashboardModule);
const shotefModule = () => import('./shotef-view/shotef.module').then(m => m.ShotefModule);
const reportModule = () => import('./reports/reports.module').then(m => m.RepotrsModule);

const routes: Routes = [
  { path: 'account', data: {preload: true}, loadChildren: accountModule },
  { path: '', data: {preload: true , loadAfterSeconds: 1} , loadChildren: webtopModule, canActivate: [AuthGuard] },
  { path: 'dashboard', data: {preload: true , loadAfterSeconds: 1} , loadChildren: dashboardModule, canActivate: [AuthGuard] },
  { path: 'Timetable_Changes', data: {preload: true , loadAfterSeconds: 1}, loadChildren: shotefModule},
  { path: 'extrep',  loadChildren: reportModule },
  { path: '**', redirectTo: 'account/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: CustomPreloadingStrategyService ,
    initialNavigation: 'enabledNonBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
