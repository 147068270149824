// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-form-field-appearance-fill.form-field-inline.text .mat-form-field-flex {
  padding: 8px;
  padding-bottom: 0;
}

/* ::ng-deep .mat-form-field-appearance-fill.form-field-inline.mat-form-field-disabled.text .mat-form-field-flex {
  background-color: rgb(0 0 0 / 2%) !important;
} */
.inlineText {
  font-size: var(--font-size--small);
}

::ng-deep .textInline .mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0;
}

@media screen and (max-width: 959px) {
  ::ng-deep .textInline .mat-form-field-subscript-wrapper {
    top: unset;
  }
  .mat-form-field.text {
    font-size: var(--font-size--large);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/inline-text/inline-text.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;AACF;;AAEA;;GAAA;AAIA;EACE,kCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,aAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAGA;IACE,kCAAA;EADF;AACF","sourcesContent":["::ng-deep .mat-form-field-appearance-fill.form-field-inline.text .mat-form-field-flex {\r\n  padding: 8px;\r\n  padding-bottom: 0;\r\n}\r\n\r\n/* ::ng-deep .mat-form-field-appearance-fill.form-field-inline.mat-form-field-disabled.text .mat-form-field-flex {\r\n  background-color: rgb(0 0 0 / 2%) !important;\r\n} */\r\n\r\n.inlineText {\r\n  font-size: var(--font-size--small);\r\n}\r\n\r\n::ng-deep .textInline .mat-form-field-infix {\r\n  padding: 0.5em 0;\r\n  border-top: 0;\r\n}\r\n\r\n@media screen and (max-width: 959px) {\r\n  ::ng-deep .textInline .mat-form-field-subscript-wrapper{\r\n    top: unset;\r\n  }\r\n\r\n  .mat-form-field.text{\r\n    font-size: var(--font-size--large)\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
