// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bodyContent {
  height: 100%;
  overflow: hidden;
}

@media screen and (max-width: 960px) {

.bodyContent {
  height: auto;
  overflow: hidden;
}
}
`, "",{"version":3,"sources":["webpack://./src/app/common/UIControls/camera-dialog/camera-dialog.component.css"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA","sourcesContent":["\r\n.bodyContent {\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\n@media screen and (max-width: 960px) {\r\n\r\n.bodyContent {\r\n  height: auto;\r\n  overflow: hidden;\r\n}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
