import { Stepper } from './../../../_models/stepper';
import { Component, ContentChild, ContentChildren, Input, OnInit, TemplateRef, QueryList, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {

  @Input() steppers: Stepper[] = [];
  @Input() showfooterBtns: false;
  @Input() isLinear = true;
  @Output() nextStepClicked = new EventEmitter<MatStepper>();
  @Output() prevStepClicked = new EventEmitter<MatStepper>();
  @Output() selectedTabChanged = new EventEmitter<MatStepper>();
  @ContentChildren('stepContent') stepContent: Element;
  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private shared: SharedService
  ) {
  }

  onSelectedTabChanged(selectedTab: MatStepper) {
    this.selectedTabChanged.emit(selectedTab);
  }



  goForward(stepper: MatStepper) {
    if (this.steppers[stepper.selectedIndex].checkBeforeCompleted == undefined || !this.steppers[stepper.selectedIndex].checkBeforeCompleted) {
      this.steppers[stepper.selectedIndex].isCompleted = true;
      stepper.next();
    }
    this.nextStepClicked.emit(stepper);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
    this.prevStepClicked.emit(stepper);
  }

  ngOnInit(): void {
  }
  goNext(stepper: MatStepper) {
    stepper.next();
  }

  goToIndex(index: number) {
    this.stepper.selectedIndex = index;
  }

}
