import { SharedService } from './../../../_services/shared.service';
import { MobileService } from './../../../_services/mobile-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import {  MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CookiesService } from 'src/app/_services/cookies-service.service';
import { RsaService } from 'src/app/_services/Rsa.service';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css']
})
export class ImageDialogComponent implements OnInit {

  imageUrl: string;
  title: string;

  constructor(
    private dialogRef: MatDialogRef<ImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { fileUrl: string , title: string},
    private mobile: MobileService,
    private sharedService: SharedService,
    private cookieService: CookiesService,

  ) {
    if (mobile.isMobileView) {
      this.dialogRef.addPanelClass('edit-modal');
    }
    this.imageUrl = `${data.fileUrl}` ;
    this.title = data.title;

  }

  ngOnInit(): void {
  }

  closedialogEvent() {
    this.dialogRef.close(false);
  }

}
