import { RsaService } from './../_services/Rsa.service';
import { UserService } from './../_services/user-service.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
    constructor(
        private router: Router,
        private userSrv: UserService,
        private rsaService: RsaService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userSrv.getUser() !== null) {
            // authorised so return true
            return true;
        }
        if (location.href.toLowerCase().includes('learning_space')
         ||
         location.href.toLowerCase().includes('extrep')
         ||
         location.href.toLowerCase().includes('timetable_changes')
         ||
         location.href.toLowerCase().includes('forums')
         ||
         location.href.toLowerCase().includes('weekly')
         ||
         location.href.toLowerCase().includes('my_pupils')
         ||
         location.href.toLowerCase().includes('push')



         ) {
          // allow to continue without user only if is spaces
          return true;
        }
        // not logged in so redirect to login page with the return url
        let stateUrl = this.rsaService.encrypt(state.url)
        this.router.navigate(['/account/login'], { queryParams: { 'stateUrl': stateUrl }});
        return false;
    }
}
