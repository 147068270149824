import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-comment-badge',
  templateUrl: './comment-badge.component.html',
  styleUrls: ['./comment-badge.component.css']
})
export class CommentBadgeComponent implements OnInit {

  @Input() text: string;
  @Input() cssClass: string =''

  constructor() { }

  ngOnInit() {
  }

}
