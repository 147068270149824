import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() text: string;
  @Input() disable: boolean;
  @Input() darkBtn = false;
  @Input() redBtn = false;
  @Input() fullWidth = false;
  @Input() outline = false;
  @Input() isActive = false;
  @Input() cssClass = '';
  @Input() inputId = '';
  @Input() public compTabIndex = 0;

  dblClickDisabled = false;


  @Output() clickEvent = new EventEmitter();

  constructor() { }

  onClick(): void {
    this.clickEvent.emit(null);
  }

  ngOnInit(): void {
  }

  disabledBtn() {
    const disable = this.disable;
    this.dblClickDisabled = this.disable = true;
    setTimeout(() => {
      this.dblClickDisabled = false;
      this.disable = disable;
    }, 500);
  }

}
