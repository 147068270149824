import { RsaService } from 'src/app/_services/Rsa.service';
import { MobileService } from 'src/app/_services/mobile-service.service';
import { CookieOptions, CookieService  } from 'ngx-cookie';

import { Injectable, OnDestroy } from '@angular/core';
import { L } from '@angular/cdk/keycodes';
@Injectable({
  providedIn: 'root'
})
export class CookiesService implements OnDestroy {
  constructor(
    private cookieService: CookieService ,
    private mobileService: MobileService,
    private rsaService: RsaService,

  ) {
  }

  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
  addDays(futureDate: Date, days: number): Date {
    futureDate = new Date(futureDate);
    futureDate.setDate(futureDate.getDate() + days);
    return futureDate;
  }

  addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }

  setItemShortTime(key: string, value: string, minutes: number) {
    let expireDate = new Date();
    expireDate = this.addMinutes(expireDate, minutes);
    let opt: CookieOptions = {};
    opt.expires = expireDate;
    opt.sameSite = "lax";
    opt.secure = true;
    this.cookieService.put(key, value, opt);
  }

  setItem(key: string, value: string, rememberMe: boolean = false) {
    let expireDate = new Date();
    if (key === 'webToptoken') {
      const tmp = this.cookieService.get('webToptokenExpired')
      if (tmp === undefined || tmp === null || tmp === '') {
        if (this.mobileService.isMobileView && rememberMe) {
          expireDate = this.addDays(expireDate, 180);
        } else {
          expireDate = this.addMinutes(expireDate, 35);
        }
      } else {
        const d = new Date(this.rsaService.decrypt(tmp));
        //const d = new Date(tmp);
        if (d > expireDate && this.mobileService.isMobileView) {
          expireDate = d;
        } else {
          expireDate = this.addMinutes(expireDate, 35);
        }

      }
      let opt: CookieOptions = {};
      opt.expires = expireDate;
      opt.sameSite = "lax";
      opt.secure = true;
      this.cookieService.put('webToptokenExpired', this.rsaService.encrypt(expireDate.toString()),opt)
      //this.cookieService.set('webToptokenExpired', this.rsaService.encrypt(expireDate.toString()), expireDate, '', '', true, "Lax")
      //this.cookieService.set('webToptokenExpired',expireDate.toString(),expireDate ,'' ,'', true,"Lax")
    } else {
      expireDate = this.addDays(expireDate, 1);
    }
    if (key === 'admin') {
      /*  this.cookieService.set('mg1', value.substring(0, 2000) ,expireDate ,'' ,'', true,"Lax");
       this.cookieService.set('mg2', value.substring(2000) ,expireDate ,'' ,'', true,"Lax"); */
    } else {
      let opt: CookieOptions = {};
      opt.expires = expireDate;
      opt.sameSite = "lax";
      opt.secure = true;
      this.cookieService.put(key, value,opt);
      //this.cookieService.set(key, value, expireDate, '', '', true, "Lax")
    }

  }

  getItem(key: string) {
    return this.cookieService.get(key);
  }

  removeItem(key: string) {
    this.cookieService.remove(key);
   /*  try{

      const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
      this.cookieService.set(key, null, expiresDate);
    } catch(error) {
      alert(error)
    }

    setTimeout(() => {
      try{

        const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');
        this.cookieService.set(key, null, expiresDate);
      } catch{}
    }, 150); */
  }

}
