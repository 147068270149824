import { CheckboxComponent } from './../checkbox/checkbox.component';
import { SharedService } from 'src/app/_services/shared.service';
import { MatOption } from '@angular/material/core';
import { FormControl, NgModel } from '@angular/forms';
import { SelectOptgroupOption } from './../select-optgroup/selectOptgroupOption';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multi-select-optgroup',
  templateUrl: './multi-select-optgroup.component.html',
  styleUrls: ['./multi-select-optgroup.component.css', '../inputs.css']
})
export class MultiSelectOptgroupComponent implements OnInit {
  modelGroup: any[];
  @Input() options: SelectOptgroupOption[];
  groups: SelectOptgroupOption[];
  showFilter = false;
  /* @Input() set options(value: SelectOptgroupOption[]){
    this._options = value;
    this.getArrayAllValues();
    this.selectedValue.emit(this.selectedItems.value);
  }
  private _aloneoptions: any[];
  @Input() set selectOptionsStendAlone(value: any[]){
    this._aloneoptions = value;
    this.getArrayStendAloneValues();
  }
  controlValue: number[];

  get options(){
    return this._options;
  }
  get selectOptionsStendAlone(){
    return this._aloneoptions;
  } */
  @Input() selectOptionsStendAlone: any[];
  @ViewChild('selectAllCkb') selectAllCkb: MatOption;
  @ViewChild('itemSelect') itemSelect: NgModel;
  @Input() public label: string;
  @Input() public selectAllText: string;
  @Input() public selectAllOnStart = false;

  @Output() selectedValue: EventEmitter<string[] | number[]> = new EventEmitter<string[] | number[]>();
  flag = false;
  optionsNum = 0;
  selectedItems: any[] = [];
  allSelected = false;
  items = 0;
  waitTime = false;
  constructor(
    public _shared: SharedService
  ) {

  }
  setGroups(options) {
    console.log('setGroups')
    if (this.selectOptionsStendAlone && this.selectOptionsStendAlone.length > 0) {
      const tmp = {
        'options': [],
        'label': '',
        'value': 'itemId',
        'text': 'textToSearch'
      }
      this.selectOptionsStendAlone.forEach(s => {
        if (this.options[0]?.options.find(x => x.itemId === s.value) == null) {
          let item = {
            'itemId': s.value,
            'textToSearch': s.text
          };

          tmp.options.push(item)
        }
      });
      if (tmp.options.length > 0)
        options.unshift(tmp);
    }
    let index = 1;
    options.forEach(opt => {
      opt.options?.forEach(x => {
        x.textForSearch = x[opt.text] + this._shared.replcaeLanguage(x[opt.text]);
        x['rowIndex'] = index++;
      }
      );
    });
    this.groups = options.slice();
    //this.selectAllCkb.setValue(true, true);
    this.selectAll(true);
  }




  StendAloneClick(item, $event) {
    this.selectedValue.emit(this.itemSelect.viewModel);


  }
  selectAll(flag: boolean) {
    if (this.waitTime) return;
    this.waitTime = true;
    this.flag = flag;

    if (!flag) {


      setTimeout(() => {
        this.selectedItems = [];
        this.itemSelect.update.emit([]);
        this.selectedValue.emit([]);
      }, 100);
      setTimeout(() => {

        this.waitTime = false;
      }, 500);



      //this.selectOptionsStendAlone.forEach(x=>x.isChecked = false);
    } else {
      //this.selectOptionsStendAlone.forEach(x=>x.isChecked = true);
      let values: any[] = []; // array which will contain all values
      /*  if(this.selectOptionsStendAlone && this.selectOptionsStendAlone.length > 0) {
         this.selectOptionsStendAlone.forEach(s => values.push(s.value));
       } */
      // loop through all groups and add their items' values to the array

      for (let group of this.groups) {
        for (let item of group.options) {
          if (!item.itemId) {
            item.itemId = item.id.toString();
          }
          values.push(item.itemId);
        }
      }
      values.push('9999999999')

      // submit the array with all values
      setTimeout(() => {
        this.items = values.length;
        this.itemSelect.update.emit(values);
        this.selectedValue.emit(values);
        this.selectedItems = values;

      }, 100);
      setTimeout(() => {

        this.waitTime = false;
      }, 500);


    }
  }

  getSelectedCount() {
    return this.selectedItems.filter(x => x != '9999999999').length;
  }

  toggleSingleItem(option, $event) {
    if (this.waitTime) return;
    if (this.selectedItems.includes(option.itemId)) {
      this.selectedItems = this.selectedItems.filter(x => x != option.itemId)
    } else {
      this.selectedItems.push(option.itemId);
    }
    this.selectedValue.emit(this.selectedItems);
    // this.selectAllCkb.setValue(this.itemSelect.viewModel.length == this.items);
  }

  ngOnInit() {
    //this.controlValue = this.selectedItems.value;
    // this.addValueToBase();
  }

  changeFocus(x) {
    /* if (x < 0) {
      x = lastIndex ;
    }
    if (x > lastIndex) {
      x = 0;
    } */
    const elem = document.getElementById('optGroupId-' + x);
    elem.focus();
  }

  /*  getArrayStendAloneValues(): void{
     this.selectOptionsStendAlone.forEach(item => {
       this.toggleUngroupOption();
     });
 } */

  /*  getArrayAllValues(): void{
       this.allSelected = true;
       this.optionsNum = 0;
       const allItems = [];
       this.options.forEach(group => {
         group.options.forEach(item => {
           this.optionsNum++;
           allItems.push(item[group.value]);
         });
       });
       allItems.push(0);
       this.selectedItems.patchValue(allItems);
   } */

  /* toggleSelectAll(data: any): void {
    if (data.selected) {
      this.getArrayAllValues();
    } else {
      this.allSelected = false;
      this.selectedItems.patchValue([]);
    }
    this.selectedValue.emit(this.selectedItems.value);
    this.controlValue = this.selectedItems.value;
    this.addValueToBase();
  } */

  /*  toggleUngroupOption(){
     this.selectedValue.emit(this.selectedItems.value);
     this.controlValue = this.selectedItems.value;
     this.addValueToBase();
   }
  */
  /*  toggleSingleItem(data: any): void {
     if (data.selected) {
       data.deselect();
     } else if (this.selectedItems.value.length === this.optionsNum) {
       data.select();
     }
     this.selectedValue.emit(this.selectedItems.value);
     this.controlValue = this.selectedItems.value;
     this.addValueToBase();
   }
  */
  /*  addValueToBase() {
     if (this.selectedItems.value.filter(x => x !== 0).length === this.optionsNum ) {
       if (this.controlValue[0] !== 0) {
         this.controlValue.splice(0, 0, 0);

       }
     }
   } */

  /*  itemClick(data) {
     const beforeValue = this.controlValue[0];
     const currentValue = (this.selectedItems.value as any[])[0];
     if (beforeValue !== currentValue && (beforeValue === 0 || currentValue === 0)) {
       this.toggleSelectAll(data);
     } else {
       this.toggleSingleItem(data);
     }


   } */



}
